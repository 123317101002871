import {
  CurrencyDollarIcon as DollarIconOutline,
  ClockIcon as TimeIconOutline,
  MapPinIcon as LocationIconOutline,
  BuildingOfficeIcon as OfficeBuildingIconOutline,
  ArrowPathIcon as LoadingIcon,
  BookmarkIcon as BookmarkOutline,
  PlusCircleIcon as CompareIconOutline,
} from '@heroicons/react/24/outline';
import {
  BookmarkIcon as BookmarkSolid,
  PlusCircleIcon as CompareIconSolid,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { client } from '@src/apollo/apollo-client';
import {
  CompatibilityResponse,
  Job,
  JobRate,
  JobRates,
  JobToCompareDocument,
  useAddJobToMyFavouritesMutation,
  useDeleteJobMutation,
  useLoadCompatibilityMutation,
  useRemoveJobFromMyFavouritesMutation,
} from '@src/apollo/types/graphql';
import { JobViewContext } from '@src/context/JobViewContext';
import { MyUserContext } from '@src/context/MyUserContext';
import { GradientSvgBar } from '@src/images/GradientSvgBar';
import {
  jobsToCompare as jobsToCompareSelector,
  setJobsToCompare,
} from '@src/store/reducers/jobs';

import { DeleteJobModal } from './DeleteJobModal';
import { EditJobModal } from './EditJobModal';
import { JobOptionsMenu } from './JobOptionsMenu';
import CompanyLogoImage from '../company/CompanyLogo';
import { UnlockComaptibilityButton } from '../compatibility/misc/UnlockCompatibilityButton';

interface JobListItemProps {
  job: Pick<
    Job,
    | 'salary'
    | 'hourlyRate'
    | 'salaryCurrency'
    | 'type'
    | 'location'
    | 'locationType'
    | 'industry'
    | 'id'
    | 'addedToFavourites'
    | 'knowledge'
    | 'title'
    | 'isManuallyCreated'
    | 'externalUrl'
    | 'createdById'
    | 'yearlyRate'
  > & { company: Pick<Job['company'], 'logo' | 'name'> } & {
    myCompatibility?: Pick<CompatibilityResponse, 'general'> | null;
  } & {
    computedRates?: JobRates | null;
  };
  onCompatibilityLoad?: () => Promise<void>;
  refetch?: () => void;
}

export const JobListItem: React.FC<JobListItemProps> = (
  props: JobListItemProps,
) => {
  const { viewJob } = useContext(JobViewContext);
  const { myUser, refetch: refetchMyUser } = useContext(MyUserContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [editJobModalOpened, setEditJobModalOpened] = useState(false);
  const [confirmDeleteJobModal, setConfirmDeleteJobModal] = useState(false);

  const jobsToCompare = useSelector(jobsToCompareSelector);

  const isJobAlreadyAddedToList = useMemo(
    () => jobsToCompare.find((job) => job?.id === props.job.id),
    [jobsToCompare, props.job.id],
  );

  // Mutations
  const [loadCompatibility, loadCompatibilityMutation] =
    useLoadCompatibilityMutation({
      variables: {
        jobId: props.job.id,
      },
    });

  const [deleteJob] = useDeleteJobMutation({
    variables: {
      jobId: props.job.id,
    },
  });

  // Queries

  const credits = useMemo(() => {
    return myUser?.credits ?? 0;
  }, [myUser]);

  const compatibilityCalculationCost = useMemo(() => {
    return myUser?.costs.forCompatibilityCreation ?? 0;
  }, [myUser]);

  // Effects
  useEffect(() => {
    if (credits && credits < compatibilityCalculationCost) {
      toast(t('compatibilities.notifications.dailyLimitReached'), {
        type: 'warning',
        toastId: 'compatibilitiesLeft',
      });
    }
  }, [credits]);

  // Methods
  const handleAddToCompare = async (jobId: string) => {
    if (!jobId) {
      return;
    }

    if (isJobAlreadyAddedToList) {
      return;
    }

    try {
      const result = await client.query({
        query: JobToCompareDocument,
        variables: {
          where: {
            id: jobId,
          },
        },
      });

      if (result.data.job) {
        dispatch(setJobsToCompare([...jobsToCompare, result.data.job]));
      }
    } catch (error) {
      console.error('Failed to add job to compare list', error);
    }
  };

  const handleRemoveJobFromCompare = (jobId?: string) => {
    if (!jobId) {
      return;
    }

    const newJobsToCompare = jobsToCompare.filter((job) => job?.id !== jobId);

    dispatch(setJobsToCompare(newJobsToCompare));
  };

  const handleCompatibiltyCalculation = async () => {
    await loadCompatibility();
    await refetchMyUser();

    if (props.onCompatibilityLoad) {
      props.onCompatibilityLoad();
    }
  };

  const [jobAddedToFavorites, setJobAddedToFavorites] = useState<boolean>(
    props.job.addedToFavourites,
  );

  const [addJobToMyFavourites] = useAddJobToMyFavouritesMutation({
    variables: {
      jobId: props.job.id,
    },
  });

  const [removeJobFromMyFavourites] = useRemoveJobFromMyFavouritesMutation({
    variables: {
      jobId: props.job.id,
    },
  });

  const handleHeartButtonClick = () => {
    if (jobAddedToFavorites) {
      removeJobFromMyFavourites();
    } else {
      addJobToMyFavourites();
    }

    setJobAddedToFavorites((prev) => !prev);
  };

  // // Memos
  // const userResumeFeaturesCalculated = useMemo(() => {
  //   return myResumeFeaturesQuery.data?.me.resume?.technicalSkills !== undefined;
  // }, [myResumeFeaturesQuery.data?.me.resume]);

  // const showNoCompatibilitiesLeft = useMemo(() => {
  //   return (
  //     loadCompatibilityMutation.loading === false &&
  //     loading === false &&
  //     credits &&
  //     credits < compatibilityCalculationCost &&
  //     userResumeFeaturesCalculated
  //   );
  // }, [
  //   loadCompatibilityMutation.loading,
  //   loading,
  //   credits,
  //   userResumeFeaturesCalculated,
  // ]);

  // const showCalculateCompatibility = useMemo(() => {
  //   return (
  //     loadCompatibilityMutation.loading === false &&
  //     loading === false &&
  //     credits &&
  //     credits >= compatibilityCalculationCost &&
  //     userResumeFeaturesCalculated
  //   );
  // }, [
  //   loadCompatibilityMutation.loading,
  //   loading,
  //   credits,
  //   userResumeFeaturesCalculated,
  // ]);

  const mySkills = useMemo(() => myUser?.resume?.knowledge ?? [], [myUser]);

  const commonSkills = useMemo(
    () =>
      props.job.knowledge
        .filter((skill) => {
          const mySkillsToLowerCase = mySkills.map((skill) =>
            skill.word.toLowerCase(),
          );

          return mySkillsToLowerCase.includes(skill.word.toLowerCase());
        })
        .map((skill) => skill.word) ?? [],
    [props.job.knowledge, mySkills],
  );

  const jobCompatibility = useMemo(() => {
    return (
      loadCompatibilityMutation.data?.loadCompatibility.jobCompatibility ||
      props.job.myCompatibility?.general
    );
  }, [props.job.myCompatibility, loadCompatibilityMutation.data]);

  const showPercentageOnBackground = jobCompatibility
    ? jobCompatibility < 10
    : false;

  return (
    <div className="grid bg-base-300 rounded-xl p-2 gap-1 shadow-md shadow-blue-light-01 bg-white h-full col-span-6">
      <div className="grid grid-cols-12 gap-2 divide-x divide-blue-light-02">
        <div className="col-span-4 flex items-center justify-center flex-wrap cursor-pointer">
          <div
            className="w-full flex flex-wrap justify-start items-center"
            onClick={() => viewJob(props.job.id)}
          >
            <h4 className="text-grey-dark text-base font-medium w-full font-semibold font-lexend">
              {props.job.title}
            </h4>
            <p className="text-xxs text-grey-light-00 w-full font-lexend font-medium">
              {props.job.company?.name ?? ''}
            </p>
            <p className="text-xxs text-grey-dark w-full">
              {props.job.location ?? ''}
            </p>
          </div>
          <div
            className="w-full flex flex-wrap justify-center"
            onClick={() => viewJob(props.job.id)}
          >
            <CompanyLogoImage company={props.job.company} imgWidth="50px" />
          </div>
        </div>
        <div className="col-span-7">
          <div className="grid grid-flow-rows gap-2 p-2 h-full">
            <div className="grid grid-cols-4 divide-x divide-blue-light-02">
              <div className="w-full text-center " title="Salary">
                <div className="flex justify-center">
                  <DollarIconOutline className="stroke-grey-light-00 w-4 h-4" />
                </div>
                <h4 className="text-xxs text-grey-light-00 font-medium break-words">
                  {props.job.computedRates ? (
                    <Fragment>
                      {myUser?.profile.rate === JobRate.Hourly &&
                        `${props.job.computedRates?.hourlyRate} ${myUser?.profile.currency?.code} / hour`}
                      {myUser?.profile.rate === JobRate.Monthly &&
                        `${props.job.computedRates?.monthlyRate} ${myUser?.profile.currency?.code} / month`}
                      {myUser?.profile.rate === JobRate.Yearly &&
                        `${props.job.computedRates?.yearlyRate} ${myUser?.profile.currency?.code} / year`}
                    </Fragment>
                  ) : (
                    <Fragment>{props.job.salary ?? ''}</Fragment>
                  )}
                </h4>
              </div>
              <div className="w-full text-center " title={props.job.type ?? ''}>
                <div className="flex justify-center">
                  <TimeIconOutline className="stroke-grey-light-00 w-4 h-4" />
                </div>
                <h4 className="text-xxs text-grey-light-00 font-medium break-words">
                  {props.job.type ? props.job.type : 'Unknown'}
                </h4>
              </div>
              <div
                className="w-full text-center "
                title={props.job.locationType ?? ''}
              >
                <div className="flex justify-center">
                  <LocationIconOutline className="stroke-grey-light-00 w-4 h-4" />
                </div>
                <h4 className="text-xxs text-grey-light-00 font-medium break-words">
                  {props.job.locationType ? props.job.locationType : 'Unknown'}
                </h4>
              </div>
              <div
                className="w-full text-center "
                title={props.job.industry ?? ''}
              >
                <div className="flex justify-center">
                  <OfficeBuildingIconOutline className="stroke-grey-light-00 w-4 h-4" />
                </div>
                <h4 className="text-xxs text-grey-light-00 font-medium break-words">
                  {props.job.industry ? props.job.industry : 'Unknown'}
                </h4>
              </div>
            </div>
            <div className="h-0.5 bg-white rounded-xl"></div>
            {props.job.knowledge && (
              <div className="flex items-center justify-center">
                <div className="flex flex-wrap justify-center gap-2">
                  {props.job.knowledge.map((skill, idx) => {
                    return (
                      <span
                        key={idx}
                        className={`${
                          commonSkills?.includes(skill.word)
                            ? 'border-2 border-blue-dark '
                            : ' '
                        }text-blue-dark inline-flex items-center rounded-full bg-blue-light-02 px-2 py-1 text-xxs`}
                      >
                        {skill.word.length > 20
                          ? skill.word.substring(0, 20) + '...'
                          : skill.word}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}

            {jobCompatibility && (
              <div className="relative w-full h-3.5 m-0 mt-2 p-0 self-end">
                <GradientSvgBar
                  id={props.job.id}
                  progress={jobCompatibility}
                  className={clsx('w-full h-full')}
                />

                <span
                  className={clsx(
                    'absolute top-0 left-0 pr-1 flex justify-end items-center h-3.5',
                    'font-inter font-semibold text-xxs leading-none',
                    {
                      'text-white': !showPercentageOnBackground,
                      'text-grey-light-00': showPercentageOnBackground,
                    },
                    'transition-all duration-300',
                  )}
                  style={{
                    width: `${
                      showPercentageOnBackground ? '100' : jobCompatibility
                    }%`,
                  }}
                >
                  {jobCompatibility}%
                </span>
              </div>
            )}
            {!jobCompatibility && (
              <div className="relative w-full h-3.5 my-4">
                <div
                  className={clsx(
                    'h-full font-medium text-white text-end rounded-xl',
                    'bg-gradient-to-tr from-start-gradient3 to-end-gradient3',
                    'blur-lg',
                  )}
                  style={{ width: '100%' }}
                ></div>
                <div
                  className={clsx(
                    'absolute top-1/2 left-1/2',
                    '-translate-x-1/2 -translate-y-1/2',
                    'flex flex-col justify-center items-center',
                    !loadCompatibilityMutation.loading
                      ? 'bg-white rounded-full'
                      : 'rounded-full',
                  )}
                >
                  {!loadCompatibilityMutation.loading && (
                    <UnlockComaptibilityButton
                      handleLoadCompatibility={handleCompatibiltyCalculation}
                      jobId={props.job.id}
                    />
                  )}
                  {loadCompatibilityMutation.loading && (
                    <div
                      className={clsx(
                        'flex items-center group w-full h-full justify-center',
                        'rounded-full p-1 text-blue-dark font-medium text-sm',
                        'animate-spin border-0 ',
                      )}
                    >
                      <LoadingIcon className="w-6 h-6" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1 flex justify-center">
          <div className="text-blue-dark">
            <JobOptionsMenu
              isManuallyCreated={props.job.isManuallyCreated}
              jobCreatedByMe={props.job.createdById === myUser?.id}
              openDeleteModal={() => setConfirmDeleteJobModal(true)}
              openEditModal={() => setEditJobModalOpened(true)}
              data={{
                job: {
                  id: props.job.id,
                  externalUrl: props.job.externalUrl,
                }
              }}
            />
            <div className="w-full">
              <button
                title={
                  jobAddedToFavorites
                    ? 'Remove from favourites'
                    : 'Add to favourites'
                }
                className="mt-auto text-primary w-5 h-5"
                onClick={handleHeartButtonClick}
              >
                {jobAddedToFavorites ? <BookmarkSolid /> : <BookmarkOutline />}
              </button>
            </div>
            <div className="w-full">
              {isJobAlreadyAddedToList ? (
                <CompareIconSolid
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => handleRemoveJobFromCompare(props.job.id)}
                />
              ) : (
                <CompareIconOutline
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => handleAddToCompare(props.job.id)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <EditJobModal
        closeEditModal={() => setEditJobModalOpened(false)}
        modalIsOpened={editJobModalOpened}
        refetch={() => props.refetch?.()}
        job={props.job}
      />

      <DeleteJobModal
        closeDeleteJobModal={() => setConfirmDeleteJobModal(false)}
        deleteJobModalOpened={confirmDeleteJobModal}
        deleteJob={() => deleteJob()}
        refetch={() => props.refetch?.()}
      />
    </div>
  );
};
