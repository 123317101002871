import React from 'react';

import { MyUserContext } from '@src/context/MyUserContext';

interface SalaryRangeProps {
  labelValue: string;
  salary: number;
  maxRange: number;
  salaryType: string;
  step: number;
  setValue: (value: number) => void;
}

export const SalaryRange: React.FC<SalaryRangeProps> = ({
  labelValue,
  salary,
  salaryType,
  maxRange,
  step,
  setValue,
}: SalaryRangeProps) => {
  const { myUser } = React.useContext(MyUserContext);

  const handleRangeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(ev.target.value);
    setValue(newValue);
  };

  return (
    <div className="border rounded mb-2 p-2">
      <div className="grid grid-cols-12 items-center">
        <label
          htmlFor="monthlySalary"
          className="col-span-6 lexend font-medium text-sm text-start text-grey-light"
        >
          {labelValue}
        </label>
        <input
          min={0}
          max={maxRange}
          step={step}
          id={salaryType}
          type="number"
          className="col-span-5 text-end"
          value={salary}
          onChange={handleRangeChange}
        />
        <span className="col-span-1 text-center">
          {myUser?.profile.currency?.code}
        </span>
      </div>

      <input
        type="range"
        min="0"
        max={maxRange}
        step={step}
        value={salary}
        className="transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200"
        id={salaryType}
        onChange={handleRangeChange}
      />
    </div>
  );
};
