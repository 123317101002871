import { CompatibilityResponse, Job } from '@src/apollo/types/graphql';

import { EditJobPage } from './EditJobPage';
import Modal from '../core/Modal';

interface EditJobModalProps {
  closeEditModal: () => void;
  modalIsOpened: boolean;
  job: Pick<
    Job,
    | 'salary'
    | 'hourlyRate'
    | 'salaryCurrency'
    | 'type'
    | 'location'
    | 'locationType'
    | 'industry'
    | 'id'
    | 'addedToFavourites'
    | 'knowledge'
    | 'title'
    | 'isManuallyCreated'
    | 'externalUrl'
    | 'createdById'
    | 'yearlyRate'
  > & { company: Pick<Job['company'], 'logo' | 'name'> } & {
    myCompatibility?: Pick<CompatibilityResponse, 'general'> | null;
  };
  refetch: () => void;
}

export const EditJobModal: React.FC<EditJobModalProps> = ({
  closeEditModal,
  modalIsOpened,
  job,
  refetch,
}: EditJobModalProps) => {
  return (
    <Modal
      modalClassNames="md:w-1/4 p-3"
      isOpen={modalIsOpened}
      onClose={() => {
        closeEditModal();
      }}
    >
      <EditJobPage
        closeModal={() => closeEditModal()}
        job={job}
        refetch={() => refetch?.()}
      />
    </Modal>
  );
};
