import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { createClient } from 'graphql-ws';
import { nanoid } from 'nanoid';

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const tId = nanoid();

  Sentry.configureScope((scope) => {
    scope.setTag('transaction_id', tId);
  });

  return {
    headers: {
      ...headers,
      'X-Request-Id': tId,
      'X-Transaction-ID': tId,
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_WS_ENDPOINT,
  }),
);

const errorLink = onError(({ graphQLErrors }) => {
  graphQLErrors &&
    graphQLErrors.forEach((err) => {
      if (err.message === "You're not authenticated") {
        window.location.href = '/login';
      }
    });
});

const terminatingLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

export const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        // includeCache: true,
        // includeFetchResult: true,
        includeError: true,
      },
    }),
    errorLink.concat(terminatingLink),
  ]),
});
