// eslint-disable-next-line import/named
import { FetchResult } from '@apollo/client';

import { DeleteJobMutation } from '@src/apollo/types/graphql';

import Modal from '../core/Modal';

interface DeleteJobModalProps {
  deleteJobModalOpened: boolean;
  closeDeleteJobModal: () => void;
  refetch: () => void;
  deleteJob: () => Promise<FetchResult<DeleteJobMutation>>;
}

export const DeleteJobModal: React.FC<DeleteJobModalProps> = ({
  deleteJobModalOpened,
  closeDeleteJobModal,
  refetch,
  deleteJob,
}: DeleteJobModalProps) => {
  return (
    <Modal
      modalClassNames="md:w-1/4 p-3"
      isOpen={deleteJobModalOpened}
      onClose={() => {
        closeDeleteJobModal();
      }}
    >
      <div className="p-2">
        <h3 className="text-center text-xl">
          Are you sure you want to delete this job?{' '}
        </h3>
        <div className="flex justify-around">
          <button
            className="mt-6 px-4 py-1 rounded-5 border ai-border-gradient"
            onClick={() =>
              deleteJob().then(() => {
                closeDeleteJobModal();
                refetch();
              })
            }
          >
            Yes
          </button>
          <button
            className="mt-6 text-red-500 border border-red-500 px-4 py-1 rounded-md font-lexend"
            onClick={() => closeDeleteJobModal()}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};
