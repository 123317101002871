import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export const Page404 = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-grey-light-02 px-16 md:px-0 ">
      <div className="ai-border-404-gradient flex flex-col items-center  justify-center rounded-lg bg-white px-4 py-8 shadow-2xl hover:border-2 md:px-8 lg:px-24">
        <p className="bg-gradient-to-tr from-purple-light to-blue-dark  bg-clip-text text-6xl font-bold tracking-wider text-transparent md:text-7xl lg:text-9xl">
          404
        </p>
        <p className="mt-5 font-display text-2xl font-bold text-black">
          Page Not Found
        </p>
        <p className="mt-2 text-sm text-slate-800">
          Sorry, the page you are looking for could not be found.
        </p>
        <Link
          to={'/'}
          className="ai-button-free mt-6 flex items-center space-x-2 rounded px-4 py-2 text-white"
          title="Return Home"
        >
          <ArrowLeftIcon className="w-5" />
          <span>Return Home</span>
        </Link>
      </div>
    </div>
  );
};
