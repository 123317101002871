import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export type WidgetErrorProps = {
  error?: string;
};

const WidgetError: React.FC<WidgetErrorProps> = (props: WidgetErrorProps) => {
  return (
    <div className="widget-error">
      <FontAwesomeIcon
        className="w-6 h-4 mr-2"
        color="yellow"
        icon={faCircleExclamation}
      />

      <span className="text-md text-gray-500">
        {props.error || 'An unexpected error occured while loading the widget.'}
      </span>
    </div>
  );
};

export default WidgetError;
