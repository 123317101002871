import {
  RectangleGroupIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useMemo } from 'react';

import { MyUserContext } from '@src/context/MyUserContext';

import WidgetFrame from './misc/WidgetFrame';

const TotalJobsWidget: React.FC = () => {
  const { myUser, loading } = useContext(MyUserContext);

  const statistics = useMemo(() => {
    if (!myUser || !myUser.statistics) {
      return null;
    }

    return myUser.statistics;
  }, [myUser]);

  const totalJobsSinceLastWeekCount = useMemo(() => {
    if (!statistics?.lastWeekStatistics.jobsCountPrevWeekChangeDifference) {
      return 0;
    }

    return statistics.lastWeekStatistics.jobsCountPrevWeekChangeDifference;
  }, [statistics]);

  // Template
  return (
    <WidgetFrame
      queriesReady={!!statistics}
      queriesLoading={loading}
      extraClasses="py-3"
    >
      <div className="h-full flex flex-wrap items-center justify-center">
        <div className="flex w-4/5 justify-start">
          <span className="text-xl inline-flex items-center gap-5 font-lexend text-grey-dark">
            <RectangleGroupIcon className="stroke-blue-dark h-9"></RectangleGroupIcon>
            Explored
          </span>
        </div>
        <div className="flex flex-wrap w-4/5 justify-between">
          <div>
            <span className="text-xl font-semibold text-grey-dark font-lexend">
              {statistics?.jobsCount}
            </span>
          </div>
          <div>
            <div>
              <span className="text-sm text-green-500 flex items-center font-bold gap-1">
                <ArrowTrendingUpIcon className="h-5 stroke-2"></ArrowTrendingUpIcon>
                +{totalJobsSinceLastWeekCount} jobs{' '}
              </span>
            </div>
            <div>
              <span className="text-xs text-grey-light-01 font-semibold">
                vs last 7 days
              </span>
            </div>
          </div>
        </div>
      </div>
    </WidgetFrame>
  );
};

export default TotalJobsWidget;
