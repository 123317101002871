import * as amplitude from '@amplitude/analytics-browser';
import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import { t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  MeQuery,
  PresignedPost,
  PresignedPostScope,
  Resume,
  useCreatePresignedPostMutation,
  useMeQuery,
  useUpdateMyAvatarMutation,
  useUpdateMyResumeMutation,
  useUserOnboardDoneMutation,
} from '@src/apollo/types/graphql';
import DefaultAvatar from '@src/images/DefaultAvatar.svg';
import {
  setFirstName,
  setLastName,
  setProfession,
} from '@src/store/reducers/onboarding';

import { SubscriptionsProvider } from './SubscriptionsContext';

export const MyUserContext = React.createContext<{
  myUser: MeQuery['me'] | null;
  loading: boolean;
  error?: ApolloError | GraphQLError | null;
  refetch: () => void;
  updateResume: (value: Pick<Resume, 'id'>) => void;
  uploadAvatar: (files: FileList) => void;
  userOnboardDone: () => void;
  profilePicture: string;
}>({
  myUser: null,
  loading: false,
  error: null,
  profilePicture: DefaultAvatar,
  refetch: () => undefined,
  updateResume: () => undefined,
  uploadAvatar: () => undefined,
  userOnboardDone: () => undefined,
});

export const MyUserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Queries
  const { data: myUser, refetch, loading, error } = useMeQuery();

  const [updateUserResume] = useUpdateMyResumeMutation();
  const [updateMyAvatar] = useUpdateMyAvatarMutation();
  const [createPresignedPost] = useCreatePresignedPostMutation();
  const [userOnboardDone] = useUserOnboardDoneMutation();

  const handleUpdateResume = async (resume: Pick<Resume, 'id'>) => {
    await updateUserResume({
      variables: {
        resumeId: resume.id,
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const handleAvatarChange = async (files: FileList | null | undefined) => {
    if (!files || files?.length === 0) {
      return;
    }

    const file = files.length > 0 ? files[0] : null;

    if (!file) {
      return;
    }

    try {
      const presignedPost = await createPresignedPost({
        variables: {
          filename: file.name,
          filetype: file.type,
          filesize: file.size,
          scope: PresignedPostScope.ProfilePicture,
        },
      });

      const { url, fields } = (
        presignedPost.data?.createPresignedPost as PresignedPost
      ).presignedPostData;

      const { id } = (presignedPost.data?.createPresignedPost as PresignedPost)
        .file;

      const formData = new FormData();

      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await fetch(url, {
        method: 'POST',
        body: formData,
      });

      await updateMyAvatar({
        variables: {
          fileId: id,
        },
      });

      refetch();

      toast(t('views.profile.success.avatarUpdated'), {
        type: 'success',
      });
    } catch (error) {
      toast(t('views.profile.errors.avatarUpdateFailed'), {
        type: 'error',
      });
    }
  };

  const profilePicture = useMemo(() => {
    // The avatar that the user set
    const avatar = myUser?.me.profile.avatar;
    if (avatar) {
      return avatar.url;
    }

    // The photos (optional) received from SSO
    const photos = myUser?.me.profile.photos;
    if (photos && photos.length > 0) {
      return photos[0];
    }

    // Default (if no photo is saved for user)
    return DefaultAvatar;
  }, [myUser]);

  const dispatch = useDispatch();

  useEffect(() => {
    const { profile } = myUser?.me || {};

    if (profile?.primaryEmail && myUser?.me) {
      amplitude.setUserId(myUser?.me.id);

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('id', myUser.me.id);
      identifyEvent.set('email', profile.primaryEmail);
      identifyEvent.set('firstName', profile.firstName);
      identifyEvent.set('lastName', profile.lastName);

      amplitude.identify(identifyEvent);

      Sentry.setUser({
        id: myUser.me.id,
        email: profile.primaryEmail,
        username: profile.primaryEmail,
      });
    }

    const firstName = profile?.firstName;

    if (firstName) {
      dispatch(setFirstName(firstName));
    }

    const lastName = profile?.lastName;

    if (lastName) {
      dispatch(setLastName(lastName));
    }

    const profession = profile?.profession;

    if (profession) {
      dispatch(setProfession(profession));
    }
    
    // (window as any).feedbackfin.config.url = `${
    //   import.meta.env.VITE_GRAPHQL_ENDPOINT
    // }/webhook/feedback`;
    // (window as any).feedbackfin.config.user = {
    //   name: `${profile?.firstName} ${profile?.lastName}`,
    //   email: profile?.primaryEmail,
    // };
    // const feedbackFinBubble = document.getElementsByClassName(
    //   'feedback-fin-bubble',
    // );
    // if (feedbackFinBubble && feedbackFinBubble.length > 0) {
    //   (feedbackFinBubble[0] as any).style = 'display:block';
    // }

  }, [myUser]);

  const handleUserOnboardDone = async () => {
    await userOnboardDone();
    refetch();
  };

  return (
    <MyUserContext.Provider
      value={{
        myUser: myUser?.me ?? null,
        error,
        loading,
        refetch,
        profilePicture: profilePicture,
        updateResume: handleUpdateResume,
        uploadAvatar: handleAvatarChange,
        userOnboardDone: handleUserOnboardDone,
      }}
    >
      <SubscriptionsProvider>{children}</SubscriptionsProvider>
    </MyUserContext.Provider>
  );
};
