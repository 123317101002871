import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/styles.css';
import { Maybe } from 'graphql/jsutils/Maybe';
import { LatLngExpression, Icon } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';

import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import { Address, CompatibilityResponse, Job } from '@src/apollo/types/graphql';
import MarkerIcon from '@src/assets/marker-icon.png';

import JobCard from './JobCard';
type JobMapItem = Pick<
  Job,
  | 'salary'
  | 'hourlyRate'
  | 'salaryCurrency'
  | 'type'
  | 'location'
  | 'locationType'
  | 'industry'
  | 'id'
  | 'addedToFavourites'
  | 'knowledge'
  | 'title'
  | 'isManuallyCreated'
  | 'externalUrl'
  | 'createdById'
  | 'yearlyRate'
> & { company: Pick<Job['company'], 'logo' | 'name'> } & {
  address?: Maybe<Pick<Address, 'latitude' | 'longitude'>>;
} & {
  myCompatibility?: Pick<CompatibilityResponse, 'general'> | null;
};
interface JobsMapProps {
  jobs: JobMapItem[];
}

export const JobsMap: React.FC<JobsMapProps> = ({ jobs }) => {
  const position: LatLngExpression = [51.505, -0.09];
  const [init, setInit] = useState<boolean>(true);
  const JobMarker = (job: JobMapItem) => {
    return (
      job.address &&
      job.address.latitude &&
      job.address.longitude && (
        <Marker
          key={job.id}
          position={[job.address?.latitude, job.address.longitude]}
          icon={
            new Icon({
              iconUrl: MarkerIcon,
              iconSize: [25, 41],
              iconAnchor: [12, 41],
            })
          }
        >
          <Popup>
            <JobCard job={job} />
          </Popup>
        </Marker>
      )
    );
  };

  const GestureHandlingSetter = () => {
    /* eslint-disable */
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addHandler('gestureHandling', GestureHandling);
    setInit(false);

    /* eslint-enable */
    return null;
  };

  return (
    <MapContainer
      center={position}
      zoom={3}
      scrollWheelZoom={false}
      className="h-full h-60 w-full z-10"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {init && <GestureHandlingSetter />}
      {jobs.map((job) => JobMarker(job))}
      {/* <MyLocationMarker /> */}
      <FullscreenControl position={'topleft'} forceSeparateButton={true} />
    </MapContainer>
  );
};
