import { ApolloError } from '@apollo/client';
import React, { PropsWithChildren } from 'react';

import { JpWaitingSpinner } from '@src/components/misc/JpWaitingSpinner';

import WidgetError from './WidgetError';

export type WidgetFrameProps = {
  queriesReady: boolean;
  queriesLoading: boolean;
  queriesError?: ApolloError;
  extraClasses?: string;
} & PropsWithChildren;

export type BaseWidgetFrameProps = Pick<
  WidgetFrameProps,
  'children' | 'extraClasses'
>;

const BaseWidgetFrame: React.FC<BaseWidgetFrameProps> = (
  props: BaseWidgetFrameProps,
) => {
  return (
    <div
      className={`rounded-lg border border-base/20 shadow-sm flex-1 ${props.extraClasses}`}
    >
      {props.children}
    </div>
  );
};

const WidgetFrame: React.FC<WidgetFrameProps> = (props: WidgetFrameProps) => {
  const { queriesReady, queriesLoading, queriesError, extraClasses } = props;

  if (queriesLoading || queriesError || !queriesReady) {
    return (
      <BaseWidgetFrame extraClasses={extraClasses}>
        <div className="xl:h-full h-60 grid grid-cols-12 items-center justify-items-center">
          {(queriesLoading || !queriesReady) && (
            <div className="col-span-12">
              <JpWaitingSpinner />
            </div>
          )}

          {queriesError && (
            <div className="col-span-12">
              <WidgetError />
            </div>
          )}
        </div>
      </BaseWidgetFrame>
    );
  }

  return (
    <BaseWidgetFrame extraClasses={extraClasses}>
      {props.children}
    </BaseWidgetFrame>
  );
};

export default WidgetFrame;
