import { DocumentTextIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  useCreatePresignedPostMutation,
  useCreateMyResumeMutation,
  PresignedPost,
  useMyResumeInfoQuery,
  PresignedPostScope,
} from '@src/apollo/types/graphql';

import { JpWaitingSpinner } from './JpWaitingSpinner';

interface ComponentProps {
  handleUpdateCompleted?: () => void;
  disabled?: boolean;
}

const JpDropzone: React.FC<ComponentProps> = ({ disabled }) => {
  // States
  const [rejections, setRejections] = useState<FileRejection[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  // Hooks
  const { t } = useTranslation();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled,
    maxFiles: 1,
    onDropRejected: (fileRejections) => {
      setRejections(fileRejections);
    },
  });

  // Mutations
  const [createPresignedPost] = useCreatePresignedPostMutation();

  const [createResume] = useCreateMyResumeMutation();

  // Queries
  const myResumeInfoQuery = useMyResumeInfoQuery({
    fetchPolicy: 'network-only',
  });

  // Methods
  const uploadResume = async () => {
    setUploadInProgress(true);

    try {
      const file = acceptedFiles[0];

      const filename = file.name;
      const filetype = file.type;
      const filesize = file.size;

      const presignedPost = await createPresignedPost({
        variables: {
          filename,
          filetype,
          filesize,
          scope: PresignedPostScope.Resume,
        },
      });

      const { url, fields } = (
        presignedPost.data?.createPresignedPost as PresignedPost
      ).presignedPostData;

      const { id } = (presignedPost.data?.createPresignedPost as PresignedPost)
        .file;

      const formData = new FormData();

      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      await fetch(url, {
        method: 'POST',
        body: formData,
      });

      await createResume({
        variables: {
          fileId: id,
        },
      });

      myResumeInfoQuery.refetch();
    } catch (error) {
      error instanceof Error &&
        toast(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
    }

    setUploadInProgress(false);
  };

  // Effects
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      uploadResume();
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (rejections.length > 0) {
      const timer = setTimeout(() => {
        setRejections([]);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [rejections]);

  return (
    <section className="container flex justify-center text-center flex-col w-full h-full gap-2">
      <div
        {...getRootProps()}
        className={clsx(
          'dropzone flex flex-1 gap-3 flex-col h-40 p-6 justify-evenly font-lexend items-center text-center text-grey-dark bg-white',
          'border-2 border-dashed border-grey-light-01 rounded-xl',
        )}
      >
        <input {...getInputProps()} />

        {uploadInProgress === true && <JpWaitingSpinner />}

        {uploadInProgress === false && (
          <div className="flex flex-col flex-1 justify-center items-center gap-2">
            <DocumentTextIcon
              className="h-6 w-6 text-grey-light-00 block sm:block lg:hidden xl:block "
              aria-hidden="true"
            />

            <span className="text-xs text-grey-dark leading-tight">
              <button
                type="button"
                className="btn btn-primary btn-xs font-lexend text-blue-dark"
              >
                {t('uploadDropzone.uploadFile')}
              </button>

              {t('uploadDropzone.resumeDropText')}
            </span>
          </div>
        )}
      </div>

      {rejections.length > 0 ? (
        <div className="flex justify-center text-xxs font-bold text-grey-light-02 mx-2 bg-red-600 animate-fade-in">
          <span>{t('uploadDropzone.errorMessage')}</span>
        </div>
      ) : (
        <div className="flex text-grey-dark font-lexend justify-between text-xxs mx-2">
          <span>{t('uploadDropzone.resumeSupportedFormat')}</span>

          <span>{t('uploadDropzone.maximumSize')}</span>
        </div>
      )}
    </section>
  );
};

export default JpDropzone;
