import clsx from 'clsx';

import JobpediaLogo from '@src/images/JobpediaLogo.svg';

type LogoProps = {
  noLabel?: boolean;
  className?: string;
};

export function Logo(props: LogoProps) {
  return (
    <div className={clsx('flex items-baseline mt-20 mr-0 md:mt-10')}>
      <img src={JobpediaLogo} alt="Jobpedia" width={35} height={40}></img>
      {!props.noLabel && (
        <span
          className={clsx(
            'text-lg font-lexend leading-none',
            'hidden lg:block',
            props.className,
          )}
        >
          Jobpedia.AI
        </span>
      )}
    </div>
  );
}
