import { nanoid } from '@reduxjs/toolkit';
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SortOrder, useFeaturedJobsQuery } from '@src/apollo/types/graphql';
import JobCard from '@src/components/jobs/JobCard';
import { JobListItem } from '@src/components/jobs/JobListItem';
import { JobViewProvider } from '@src/context/JobViewContext';
import { MyUserContext } from '@src/context/MyUserContext';
import useWindowDimensions from '@src/hooks/useWindowDimensions';

import WidgetFrame from './misc/WidgetFrame';
import WidgetHeader from './misc/WidgetHeader';

const FeaturedJobsWidget: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  const windowDimensions = useWindowDimensions();
  const userContext = useContext(MyUserContext);

  // Queries
  const featuredJobsQuery = useFeaturedJobsQuery({
    variables: {
      skip: 0,
      take: 10,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
    },
  });

  useEffect(() => {
    featuredJobsQuery?.refetch();
  }, [userContext.myUser?.resume]);

  // Memos
  const queriesLoading = useMemo(() => {
    return !!featuredJobsQuery?.loading;
  }, [featuredJobsQuery]);

  const queriesError = useMemo(() => {
    return featuredJobsQuery?.error;
  }, [featuredJobsQuery]);

  const queriesReady = useMemo(() => {
    return !queriesLoading && !queriesError;
  }, [queriesLoading, queriesError]);

  const featuredJobs = useMemo(() => {
    if (queriesReady) {
      return featuredJobsQuery?.data?.featuredJobs.nodes ?? [];
    }

    return [];
  }, [queriesReady, featuredJobsQuery]);

  const handleViewMoreClick = () => {
    navigate('/dashboard/featured');
  };

  // Template
  return (
    <WidgetFrame
      queriesReady={queriesReady}
      queriesLoading={queriesLoading}
      queriesError={queriesError}
      extraClasses="max-h-[550px] p-3"
    >
      <WidgetHeader
        title="Featured jobs"
        subtitle="Discover top job opportunities"
        rightTitleContent={
          <button
            className="bg-transparent hover:bg-grey-500 text-grey-dark font-semibold py-1 px-4 rounded hover:bg-blue-light-02 hover:text-blue-dark"
            onClick={handleViewMoreClick}
          >
            <span className="text-xs">View all</span>
          </button>
        }
      />
      <JobViewProvider>
        <div className="grid grid-flow-row auto-rows-max hover:auto-rows-min mt-2 h-fit gap-5 overflow-y-scroll max-h-[450px]">
          {featuredJobs?.map((job) => {
            return windowDimensions.width < 640 ? (
              <JobCard
                job={job}
                key={nanoid()}
                refetch={featuredJobsQuery?.refetch}
              ></JobCard>
            ) : (
              <JobListItem
                job={job}
                key={nanoid()}
                refetch={featuredJobsQuery?.refetch}
              />
            );
          })}
        </div>
      </JobViewProvider>
    </WidgetFrame>
  );
};

export default FeaturedJobsWidget;
