import {
  BookmarkIcon as BookmarkOutline,
  CurrencyDollarIcon as DollarIconOutline,
  ClockIcon as TimeIconOutline,
  MapPinIcon as LocationIconOutline,
  BuildingOfficeIcon as OfficeBuildingIconOutline,
  ArrowPathIcon as LoadingIcon,
  PlusCircleIcon as CompareIconOutline,
} from '@heroicons/react/24/outline';
import {
  ArrowTopRightOnSquareIcon,
  BookmarkIcon as BookmarkSolid,
  PlusCircleIcon as CompareIconSolid,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useMemo, useContext, useEffect, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { client } from '@src/apollo/apollo-client';
import {
  Company,
  CompatibilityResponse,
  Job,
  JobRate,
  JobRates,
  JobToCompareDocument,
  PersonalTag,
  useDeleteJobMutation,
} from '@src/apollo/types/graphql';
import CompatibilityBlurLines from '@src/assets/radial-chart/ColorBG.png';
import CompatibilityBlur from '@src/assets/radial-chart/RadarChart.png';
import { JobViewContext } from '@src/context/JobViewContext';
import { MyUserContext } from '@src/context/MyUserContext';
import {
  jobsToCompare as jobsToCompareSelector,
  setJobsToCompare,
} from '@src/store/reducers/jobs';

import { CompatibilityRadialChart } from './CompatibilityRadialChart';
import { DeleteJobModal } from './DeleteJobModal';
import { EditJobModal } from './EditJobModal';
import { JobOptionsMenu } from './JobOptionsMenu';
import CompanyLogoImage from '../company/CompanyLogo';
import { UnlockComaptibilityButton } from '../compatibility/misc/UnlockCompatibilityButton';
import { JobTagging } from '../tags/JobTagging';

interface JpFullCardProps {
  job: Pick<
    Job,
    | 'id'
    | 'title'
    | 'knowledge'
    | 'languages'
    | 'qualifications'
    | 'skills'
    | 'technologies'
    | 'location'
    | 'locationType'
    | 'type'
    | 'salary'
    | 'hourlyRate'
    | 'salaryCurrency'
    | 'description'
    | 'addedToFavourites'
    | 'industry'
    | 'externalUrl'
    | 'isManuallyCreated'
    | 'createdById'
  > & {company: Pick<Company, 'name' | 'logo'>} & {
    myCompatibility?: Pick<
      CompatibilityResponse,
      'general' | 'skills' | 'knowledge' | 'common_knowledge' | 'common_skills'
    > | null ;
  } & { myPersonalTags: Pick<PersonalTag, 'id' | 'tag'>[] } & {
    computedRates?: JobRates | null;
  };
  refetch?: () => void;
  closeModal: () => void;
}

const JobFullCard: React.FC<JpFullCardProps> = ({
  job,
  closeModal,
  refetch,
}: JpFullCardProps) => {
  //! TODO: Add translation

  const [editJobModalOpened, setEditJobModalOpened] = useState(false);
  const [confirmDeleteJobModal, setConfirmDeleteJobModal] = useState(false);
  const [compareJobListIsFull, setCompareJobListIsFull] = useState(false);

  const dispatch = useDispatch();

  const [deleteJob] = useDeleteJobMutation({
    variables: {
      jobId: job.id,
    },
    onCompleted: () => {
      closeModal();
    },
  });

  const jobsToCompare = useSelector(jobsToCompareSelector);

  const isJobAlreadyAddedToList = useMemo(
    () => jobsToCompare.find((item) => item?.id === job.id),
    [jobsToCompare, job.id],
  );

  // Context
  const jobContext = useContext(JobViewContext);
  const { myUser, refetch: myUserRefetch } = useContext(MyUserContext);

  // Queries
  const commonKnowledge = useMemo(
    () => job?.myCompatibility?.common_knowledge ?? [],
    [job?.myCompatibility],
  );

  const commonSkills = useMemo(
    () => job?.myCompatibility?.common_skills ?? [],
    [job?.myCompatibility],
  );

  useEffect(() => {
    jobContext.refetch();
    myUserRefetch();
  }, [myUser?.profile.currency, myUser?.profile.rate]);

  useEffect(() => {
    setCompareJobListIsFull(jobsToCompare.length === 4);
  }, [jobsToCompare]);

  // Methods
  const handleAddToCompare = async (jobId: string) => {
    if (!jobId) {
      return;
    }

    if (isJobAlreadyAddedToList) {
      return;
    }

    try {
      const result = await client.query({
        query: JobToCompareDocument,
        variables: {
          where: {
            id: jobId,
          },
        },
      });

      if (result.data.job) {
        dispatch(setJobsToCompare([...jobsToCompare, result.data.job]));
      }
    } catch (error) {
      console.error('Failed to add job to compare list', error);
    }
  };
  const handleRemoveJobFromCompare = (jobId?: string) => {
    if (!jobId) {
      return;
    }

    const newJobsToCompare = jobsToCompare.filter((job) => job?.id !== jobId);

    dispatch(setJobsToCompare(newJobsToCompare));
  };

  return (
    <div className="landing-page flex flex-col gap-1 bg-white rounded-xl relative shadow-md shadow-blue-light01">
      <div className="pt-7 px-7 pb-3">
        <div className="flex space-x-3 bg-base-300 rounded-xl p-2 gap-2  bg-white">
          <div className="shadow-md rounded-xl p-2 text-center">
            <a href={job.externalUrl} target="_blank" rel="noreferrer">
              <CompanyLogoImage
                classNames="w-full"
                company={job.company}
                imgWidth="75px"
                imgHeight="75px"
              />
            </a>
          </div>
          <div className="w-full flex flex-col gap-2">
            <div className="flex items-center w-full justify-between">
              <a
                href={job.externalUrl}
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <h4 className="text-grey-dark text-sm font-bold leading-4 mr-2">
                  {job.title}
                </h4>
                <ArrowTopRightOnSquareIcon className="w-6 h-6 stroke-dark-400" />
              </a>
              <div className="text-blue-dark flex flex-col items-center">
                
                  <JobOptionsMenu
                    isManuallyCreated={job.isManuallyCreated}
                    jobCreatedByMe={job.createdById === myUser?.id}
                    openDeleteModal={() => setConfirmDeleteJobModal(true)}
                    openEditModal={() => setEditJobModalOpened(true)}
                    data={{
                      job: {
                        id: job.id,
                        externalUrl: job.externalUrl,
                      },
                    }}
                  />
                
                <button
                  title={
                    job.addedToFavourites
                      ? 'Remove from favourites'
                      : 'Add to favourites'
                  }
                  className="text-primary w-6 h-6 text-blue-dark"
                  onClick={() => jobContext.handleToggleFavourite()}
                >
                  {job.addedToFavourites ? (
                    <BookmarkSolid />
                  ) : (
                    <BookmarkOutline />
                  )}
                </button>

                  {isJobAlreadyAddedToList ? (
                    <CompareIconSolid
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => handleRemoveJobFromCompare(job.id)}
                    />
                  ) : (
                    <CompareIconOutline
                      className={`w-5 h-5 cursor-pointer ${
                        compareJobListIsFull
                          ? 'pointer-events-none opacity-30'
                          : ''
                      } `}
                      onClick={() => handleAddToCompare(job.id)}
                    />
                  )}
                
              </div>
            </div>
            <p className=" text-xs text-grey-light/90 font-medium">
              {job.company.name ?? ''}
            </p>
            <p className="text-xxs text-grey-dark">
              {job.location ?? ''}
            </p>
            <div className="p-2 top-[4%] right-[4%]">
              <div className="flex gap-2"></div>
            </div>
          </div>
        </div>
        <JobTagging job={job} />
      </div>
      <div className="grid px-5">
        <div className="grid grid-cols-4 divide-x divide-blue-light02 text-grey-light/90 py-2">
          <div className="w-full text-center" title="Salary">
            <div className="flex justify-center">
              <DollarIconOutline className="w-6 h-6" />
            </div>
            <h4 className="text-xxs break-words">
              <h4 className="text-xxs text-grey-light-00 font-medium break-words">
                {job.computedRates ? (
                  <Fragment>
                    {myUser?.profile.rate === JobRate.Hourly &&
                      `${job.computedRates?.hourlyRate} ${myUser?.profile.currency?.code} / hour`}
                    {myUser?.profile.rate === JobRate.Monthly &&
                      `${job.computedRates?.monthlyRate} ${myUser?.profile.currency?.code} / month`}
                    {myUser?.profile.rate === JobRate.Yearly &&
                      `${job.computedRates?.yearlyRate} ${myUser?.profile.currency?.code} / year`}
                  </Fragment>
                ) : (
                  <Fragment>{job.salary ?? ''}</Fragment>
                )}
              </h4>
            </h4>
          </div>
          <div className="w-full text-center" title={job.type ?? ''}>
            <div className="flex justify-center">
              <TimeIconOutline className="w-6 h-6" />
            </div>
            <h4 className="text-xxs break-words">
              {job.type ? job.type : 'Unknown'}
            </h4>
          </div>
          <div
            className="w-full text-center "
            title={job.locationType ?? ''}
          >
            <div className="flex justify-center">
              <LocationIconOutline className="w-6 h-6" />
            </div>
            <h4 className="text-xxs break-words">
              {job.locationType ? job.locationType : 'Unknown'}
            </h4>
          </div>
          <div className="w-full text-center " title={job.industry ?? ''}>
            <div className="flex justify-center">
              <OfficeBuildingIconOutline className="w-6 h-6" />
            </div>
            <h4 className="text-xxs break-words">
              {job.industry ? job.industry : 'Unknown'}
            </h4>
          </div>
        </div>
      </div>

      {job.myCompatibility && (
        <div className="flex items-center justify-center">
          <CompatibilityRadialChart
            myCompatibility={job.myCompatibility}
          />
        </div>
      )}
      {!job.myCompatibility && (
        <div className="flex items-center justify-center">
          <div className="relative w-1/2 mt-5 h-52">
            <div className="">
              <img
                className="absolute right-[30%] top-0 blur-lg h-full"
                src={CompatibilityBlur}
                alt="no img"
              />
              <img
                className="absolute right-[30%] top-0 h-full"
                src={CompatibilityBlurLines}
                alt="no img"
              />
            </div>
            <div className="absolute border-0 text-end top-1/20 left-[15%]">
              <h4 className="font-lexend text-sm font-medium leading-tight bg-clip-text text-transparent bg-gradient-to-tr from-start-gradient3 to-end-gradient3">
                Discover Your
              </h4>
              <h4 className="font-lexend text-base font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-tr from-start-gradient3 to-end-gradient3">
                Matching Ration
              </h4>
              <h4 className="font-lexend text-sm font-medium leading-tight bg-clip-text text-transparent bg-gradient-to-tr from-start-gradient3 to-end-gradient3">
                in Seconds!
              </h4>
            </div>

            {!jobContext.loading && (
              <div
                className={clsx(
                  'absolute top-[33%] left-[25%]',
                  'flex flex-col justify-center items-center',
                  !jobContext.loading ? 'bg-white rounded-full' : '',
                )}
              >
                <UnlockComaptibilityButton
                  handleLoadCompatibility={() =>
                    jobContext.handleCompatibilityCalculation()
                  }
                  jobId={job.id}
                />
              </div>
            )}
            {jobContext.loading && (
              <div
                className={clsx(
                  'absolute top-2/5 left-2/5',
                  'flex flex-col justify-center items-center',
                  !jobContext.loading ? 'bg-white rounded-full' : '',
                )}
              >
                <div
                  className={clsx(
                    'flex items-center group w-full h-full justify-center',
                    'rounded-full p-1 text-blue-dark font-medium text-sm',
                    'animate-spin border-0 ',
                  )}
                >
                  <LoadingIcon className="w-7 h-7" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {job.knowledge.length > 0 && (
        <div className="flex justify-start p-7">
          <div className="w-full">
            <div className="flex flex-wrap gap-2 my-4">
              <p className="text-lexend font-semibold text-dark-blue-light w-full">
                Technical knowledge
              </p>
              <div className="flex flex-wrap gap-1">
                {job.knowledge.map((skill, idx) => {
                  return (
                    <span
                      key={idx}
                      className={`${
                        commonKnowledge?.includes(skill.word)
                          ? 'border-2 border-blue-dark '
                          : ' '
                      }text-blue-dark inline-flex items-center rounded-full bg-blue-light-02 px-2 py-1 text-xxs font-medium `}
                    >
                      {skill.word}
                    </span>
                  );
                })}
              </div>
            </div>
            <hr></hr>

            <div className="flex flex-wrap gap-2 my-4">
              <p className="text-lexend font-semibold text-dark-blue-light w-full">
                Languages
              </p>
              <div className="flex flex-wrap gap-1">
                {job.languages.map((skill, idx) => {
                  return (
                    <span
                      key={idx}
                      className={`${
                        commonKnowledge?.includes(skill)
                          ? 'border-2 border-yellow-600 '
                          : ' '
                      }text-yellow-800 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xxs font-medium `}
                    >
                      {skill}
                    </span>
                  );
                })}
              </div>
            </div>
            <hr></hr>

            <div className="flex flex-wrap gap-2 my-4">
              <p className="text-lexend font-semibold text-dark-blue-light w-full">
                Qualifications
              </p>
              <div className="flex flex-wrap gap-2">
                {job.qualifications.map((skill, idx) => {
                  return (
                    <span
                      key={idx}
                      className={`${
                        commonKnowledge?.includes(skill)
                          ? 'border-2 border-purple-700 '
                          : ' '
                      }text-purple-700 inline-flex items-center rounded-full bg-purple-100 px-2 py-1 text-xxs font-medium `}
                    >
                      {skill}
                    </span>
                  );
                })}
              </div>
            </div>
            <hr></hr>

            <div className="flex flex-wrap gap-2 my-4">
              <p className="text-lexend font-semibold text-dark-blue-light w-full">
                Key technologies
              </p>
              <div className="flex flex-wrap gap-2">
                {job.technologies.map((skill, idx) => {
                  return (
                    <span
                      key={idx}
                      className={`${
                        commonKnowledge?.includes(skill)
                          ? 'border-2 border-green-700 '
                          : ' '
                      }text-green-700 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xxs font-medium `}
                    >
                      {skill}
                    </span>
                  );
                })}
              </div>
            </div>
            <hr></hr>

            <div className="flex flex-wrap gap-2 my-4">
              <p className="text-lexend font-semibold text-dark-blue-light w-full">
                Skills
              </p>
              <div className="flex flex-wrap gap-2">
                {job.skills.map((skill, idx) => {
                  return (
                    <span
                      key={idx}
                      className={`${
                        commonSkills?.includes(skill)
                          ? 'border-2 border-gray-600 '
                          : ' '
                      }text-gray-600 gap-x-1.5 inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xxs font-medium `}
                    >
                      <svg
                        className="h-1.5 w-1.5 fill-gray-400"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {skill}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="px-7 pb-7">
        <h4 className="text-sm font-bold text-grey-dark">About this job</h4>
        <p className="text-xs text-grey-light/80">{job.description}</p>
      </div>
      <EditJobModal
        closeEditModal={() => setEditJobModalOpened(false)}
        modalIsOpened={editJobModalOpened}
        refetch={() => refetch?.()}
        job={job}
      />
      <DeleteJobModal
        closeDeleteJobModal={() => setConfirmDeleteJobModal(false)}
        deleteJobModalOpened={confirmDeleteJobModal}
        deleteJob={() => deleteJob()}
        refetch={() => refetch?.()}
      />
    </div>
  );
};

export default JobFullCard;
