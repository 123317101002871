import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';
import { useAuth } from '@src/hooks/useAuth';

const transitionClass = 'transition-all duration-100';

export const ProfileHeader = () => {
  // Hooks
  const userContext = useContext(MyUserContext);
  const subscriptionContext = useContext(SubscriptionsContext);

  const { t } = useTranslation();
  const { logout } = useAuth();

  // State
  const [isAvatarLoading] = useState(false);

  // Methods
  const getUserDisplayName = () => {
    if (
      userContext.myUser?.profile.firstName &&
      userContext.myUser?.profile.lastName
    ) {
      return `${userContext.myUser?.profile.firstName} ${userContext.myUser?.profile.lastName}`;
    }

    const emailBeforeAt =
      userContext.myUser?.profile.primaryEmail.split('@')[0];

    return emailBeforeAt;
  };

  const getUserDisplayJoinedDate = () => {
    const joinedDate = userContext.myUser?.createdAt;

    if (!joinedDate) {
      return t('views.profile.joinDate.unknown');
    }

    const weeksAgo = Math.floor(
      (new Date().getTime() - new Date(joinedDate).getTime()) /
        (1000 * 60 * 60 * 24 * 7),
    );

    if (weeksAgo === 0) {
      return t('views.profile.joinDate.thisWeek');
    }

    if (weeksAgo === 1) {
      return t('views.profile.joinDate.lastWeek');
    }

    return t('views.profile.joinDate.weeksAgo', { weeks: weeksAgo });
  };

  const clickOnFileInput = () => {
    const fileInput = document.getElementById('user-avatar');
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center md:items-start gap-5 justify-center">
      <div className="group relative rounded-full">
        <img
          src={userContext.profilePicture}
          alt="profile-avatar"
          className={clsx(
            'inline-block h-24 w-24 rounded-full',
            'shadow-lg shadow-grey-dark/25',
            'outline outline-2',
            'outline-offset-0 hover:outline-offset-4',
            'outline-grey-light-01 hover:outline-blue-dark',
            transitionClass,
            isAvatarLoading ? 'opacity-25' : null,
          )}
          onClick={clickOnFileInput}
        />
        <span
          className={clsx(
            'absolute left-1/2 transform -translate-x-1/2 -bottom-3 block rounded-full py-1 px-3',
            'text-xxs uppercase text-white font-bold leading-tight',
            'border border-white/75',
            subscriptionContext.isPro
              ? 'bg-gradient-to-tr from-purple-light to-blue-dark'
              : 'bg-grey-light-00',

            'block lg:block',
          )}
        >
          {subscriptionContext.isPro ? 'Premium' : 'Free'}
        </span>
      </div>
      <div className="flex flex-col gap-1 ml-5 self-center">
        <h1 className="text-2xl font-lexend font-medium">
          {getUserDisplayName()}
        </h1>
        <p className="font-inter text-sm text-grey-light-00">
          {userContext.myUser?.profile.primaryEmail}
        </p>
        <p className="font-inter text-sm text-grey-light-00">
          {getUserDisplayJoinedDate()}
        </p>
        <div>
          <button onClick={() => logout()}>{t('views.profile.signOut')}</button>
        </div>
      </div>
    </div>
  );
};
