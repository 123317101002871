import { Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';

import JpAiCredits from '@src/components/navbars/misc/JpAiCredits';
import JpMenu, { menuItemClass } from '@src/components/navbars/misc/JpMenu';
import JobpediaLogo from '@src/images/JobpediaLogo.svg';

import JpUserInfo from '../misc/JpUserInfo';

const JpTopbar: React.FC = () => {
  return (
    <Popover
      as="header"
      className={({ open }) =>
        clsx(
          open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
          'bg-white shadow-sm lg:static lg:overflow-y-visible',
        )
      }
    >
      {({ open }) => (
        <>
          <div
            className={clsx(
              'flex justify-between items-center gap-5 px-5',
              'h-20 w-full',
              'bg-white text-grey-dark',
              'border-b border-blue-light-01',
            )}
          >
            <div className={clsx('flex items-baseline')}>
              <img src={JobpediaLogo} alt="Jobpedia" width={35} />

              <span className={clsx('text-lg font-lexend leading-none')}>
                Jobpedia
              </span>
            </div>

            <Popover.Button className={clsx(menuItemClass)}>
              {open ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </Popover.Button>
          </div>

          <Popover.Panel
            as="nav"
            className="lg:hidden h-[calc(100vh-80px)]"
            aria-label="Global"
          >
            {({ close }) => (
              <div className="flex flex-col h-full px-20 py-4 justify-between items-center mb-4 gap-4">
                <JpMenu onMenuClick={close} />

                <JpUserInfo onUserInfoClick={close} />

                <JpAiCredits key={nanoid()} />
              </div>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default JpTopbar;
