import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { jobsSlice } from '@src/store/reducers/jobs';
import { onboardingSlice } from '@src/store/reducers/onboarding';
import { uiSlice } from '@src/store/reducers/ui';

import { type AppStoreState } from './types';

const rootReducer = combineReducers<AppStoreState>({
  [uiSlice.name]: uiSlice.reducer,
  [jobsSlice.name]: jobsSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});
