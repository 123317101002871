import { createSlice } from '@reduxjs/toolkit';

import { Job, JobToCompareQuery } from '@src/apollo/types/graphql';
import { type AppStoreState } from '@src/store/types';

import { type JobsState } from './types';

const initialState: JobsState = {
  jobsToCompare: [],
};

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobsToCompare(state, action: { payload: JobToCompareQuery['job'][] }) {
      state.jobsToCompare = action.payload;
    },
    setJobData(state, action: { payload: Job }) {
      const { payload } = action;
      const index = state.jobsToCompare.findIndex(
        (job) => job?.id === payload.id,
      );

      if (index === -1) return;

      state.jobsToCompare[index] = payload;
    },
  },
});

export const { setJobsToCompare, setJobData } = jobsSlice.actions;

export const jobsToCompare = (state: AppStoreState) => state.jobs.jobsToCompare;

export default jobsSlice.reducer;
