import { XMarkIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import JpAvatarDropzone from '@src/components/misc/JpAvatarDropzone';
import {
  userProfile as userProfileSelector,
  setFirstName,
  setLastName,
  setProfession,
} from '@src/store/reducers/onboarding';

const ProfileStep: React.FC = () => {
  // ⁡⁢⁣⁣𝗛𝗼𝗼𝗸𝘀⁡

  const { t } = useTranslation();

  const userProfile = useSelector(userProfileSelector);

  const dispatch = useDispatch();

  // ⁡⁢⁣⁣𝗖𝗼𝗻𝘀𝘁𝗮𝗻𝘁𝘀⁡

  const inputStyle = [
    'block w-full rounded-md border-0 py-1.5',
    'text-grey-dark shadow-sm',
    'ring-inset focus:ring-inset',
    'ring-1 focus:ring-2',
    'ring-grey-light-00 focus:ring-blue-dark',
    'sm:text-sm sm:leading-6',
    'placeholder:text-grey-light-01',
  ];

  // ⁡⁢⁣⁣𝗠𝗲𝘁𝗵𝗼𝗱𝘀⁡

  // ⁡⁢⁣⁣𝗠𝘂𝘁𝗮𝘁𝗶𝗼𝗻𝘀⁡

  return (
    <form
      id="onboarding-form"
      className="flex h-fit lg:h-full w-full gap-6 flex-col lg:flex-row"
    >
      <div className="flex flex-1 justify-center items-center">
        <JpAvatarDropzone />
      </div>

      <div className="flex flex-1 flex-col gap-2 justify-around items-start">
        <div className="relative sm:col-span-3 w-full">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-grey-dark"
          >
            {t('views.profile.form.firstName')}
          </label>

          <div className="mt-1 w-full">
            <input
              type="text"
              name="firstName"
              value={userProfile.firstName}
              placeholder="Please provide your first name"
              onChange={(e) => dispatch(setFirstName(e.target.value))}
              className={clsx(inputStyle)}
            />
          </div>

          {userProfile.firstName && (
            <button
              type="button"
              className="absolute right-2 top-7 bottom-0 flex items-center justify-center ml-2"
              onClick={() => dispatch(setFirstName(''))}
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
        </div>

        <div className="relative sm:col-span-3 w-full">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium leading-6 text-grey-dark"
          >
            {t('views.profile.form.lastName')}
          </label>

          <div className="mt-1 w-full">
            <input
              type="text"
              name="lastName"
              value={userProfile.lastName}
              placeholder="Please provide your last name"
              onChange={(e) => dispatch(setLastName(e.target.value))}
              className={clsx(inputStyle)}
            />

            {userProfile.lastName && (
              <button
                type="button"
                className="absolute right-2 top-7 bottom-0 flex items-center justify-center ml-2"
                onClick={() => dispatch(setLastName(''))}
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        </div>

        <div className="relative sm:col-span-4 w-full">
          <label
            htmlFor="profession"
            className="block text-sm font-medium leading-6 text-grey-dark"
          >
            {t('views.profile.form.profession')}
          </label>

          <div className="mt-1 w-full">
            <input
              type="text"
              name="profession"
              value={userProfile.profession}
              placeholder="Specify your profession or role"
              onChange={(e) => dispatch(setProfession(e.target.value))}
              className={clsx(inputStyle)}
            />
          </div>

          {userProfile.profession && (
            <button
              type="button"
              className="absolute right-2 top-7 bottom-0 flex items-center justify-center ml-2"
              onClick={() => dispatch(setProfession(''))}
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ProfileStep;
