import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { nanoid } from '@reduxjs/toolkit';

import { Resume } from '@src/apollo/types/graphql';

type ResumeItemProps = {
  resume: Pick<
    Resume,
    | 'id'
    | 'createdAt'
    | 'knowledge'
    | 'languages'
    | 'qualifications'
    | 'skills'
    | 'technologies'
  > & {
    file: Pick<Resume['file'], 'name' | 'url'>;
  };
  isDefault?: boolean;
  showButtons: boolean;
  handleUpdateResume: (id: string) => void;
  handleDeleteResume: (id: string) => void;
};

export const ResumeItem: React.FC<ResumeItemProps> = ({
  resume,
  isDefault,
  showButtons,
  handleUpdateResume,
  handleDeleteResume,
}: ResumeItemProps) => {
  return (
    <div className="card bg-white shadow-md rounded-10 w-full max-h-[550px] overflow-auto relative">
      <div className="p-5 flex flex-col gap-5 sticky top-0 bg-white">
        <div className="">
          {showButtons ? (
            <a
              href={resume.file.url}
              className="font-lexend text-grey-dark font-medium text-xl flex justify-between grid grid-cols-10"
            >
              <span className="break-all col-span-9">{resume.file.name}</span>
              <CloudArrowDownIcon className="h-7 w-7" />
            </a>
          ) : (
            <span className="font-lexend text-grey-dark font-medium text-xl flex justify-between">
              {resume.file.name}
            </span>
          )}
          <p className="font-lexend text-grey-light-00 text-xs">
            {new Date(resume.createdAt).toLocaleString()}
          </p>
        </div>

        {showButtons && (
          <div className="flex justify-between">
            <div className="flex gap-2">
              {isDefault ? (
                <button
                  disabled
                  className="px-4 py-1 rounded-md bg-gradient-to-tr from-purple-dark to-blue-dark font-lexend text-white"
                >
                  Primary
                </button>
              ) : (
                <button
                  onClick={() => handleUpdateResume(resume.id)}
                  className="px-4 py-1 rounded-5 border ai-border-gradient"
                >
                  <span className="bg-clip-text text-transparent bg-gradient-to-tr from-purple-dark to-blue-dark font-lexend">
                    Set as primary
                  </span>
                </button>
              )}
            </div>

            <div className="">
              <button
                onClick={() => handleDeleteResume(resume.id)}
                className="text-red-500 border border-red-500 px-4 py-1 rounded-md font-lexend"
              >
                Delete
              </button>
            </div>
          </div>
        )}

        <hr className="border border-blue-dark"></hr>
      </div>

      <div className="p-5 pt-0">
        <div className="pb-5">
          <p className="text-lexend font-semibold text-dark-blue-light pb-5">
            Technical knowledge
          </p>
          {resume.knowledge.map((skill) => (
            <span
              key={nanoid()}
              className={
                'text-blue-dark inline-flex items-center rounded-full bg-blue-light-02 px-2 py-1 text-xxs font-medium m-1'
              }
            >
              {skill.word}
            </span>
          ))}
        </div>
        <hr></hr>
        <div className="py-5">
          <p className="text-lexend font-semibold text-dark-blue-light pb-5">
            Languages
          </p>
          {resume.languages.map((skill: string) => (
            <span
              key={nanoid()}
              className={
                'text-yellow-800 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xxs font-medium m-1'
              }
            >
              {skill}
            </span>
          ))}
        </div>
        <hr></hr>
        <div className="py-5">
          <p className="text-lexend font-semibold text-dark-blue-light pb-5">
            Qualifications
          </p>
          {resume.qualifications.map((skill: string) => (
            <span
              key={nanoid()}
              className={
                'text-purple-700 inline-flex items-center rounded-full bg-purple-100 px-2 py-1 text-xxs font-medium m-1'
              }
            >
              {skill}
            </span>
          ))}
        </div>
        <hr></hr>
        <div className="py-5">
          <p className="text-lexend font-semibold text-dark-blue-light pb-5">
            Key technologies
          </p>
          {resume.technologies.map((skill: string) => (
            <span
              key={nanoid()}
              className={
                'text-green-700 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xxs font-medium m-1'
              }
            >
              {skill}
            </span>
          ))}
        </div>
        <hr></hr>

        <div className="py-5">
          <p className="text-lexend font-semibold text-dark-blue-light pb-5">
            Skills
          </p>
          {resume.skills.map((skill: string) => (
            <span
              key={nanoid()}
              className={
                'inline-flex items-center gap-x-1.5 rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600'
              }
            >
              <svg
                className="h-1.5 w-1.5 fill-gray-400"
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              {skill}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
