import React, { useEffect, useRef, useState } from 'react';

import { JpWaitingSpinner } from '@src/components/misc/JpWaitingSpinner';

interface JpChatFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>, message: string) => void;
  chatResponseLoading: boolean;
}

export const JpChatForm = ({
  onSubmit,
  chatResponseLoading,
}: JpChatFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event, message);
    setMessage('');
  };

  useEffect(() => {
    if (!chatResponseLoading && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [chatResponseLoading]);

  return (
    <form
      className="chat-form flex self-center items-center w-1/2 h-16 bg-primary-content/10 p-2 rounded-xl gap-3"
      onSubmit={handleSubmit}
    >
      {chatResponseLoading ? (
        <JpWaitingSpinner displayLoadingText={false} />
      ) : (
        <>
          <input
            ref={inputRef}
            type="text"
            name="message"
            className="input w-full"
            placeholder="Type your message here"
            value={message}
            onChange={handleChange}
          />

          <button
            className="btn btn-primary"
            type="submit"
            disabled={message.length === 0}
          >
            Send
          </button>
        </>
      )}
    </form>
  );
};
