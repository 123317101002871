import React from 'react';

export type WidgetHeaderProps = {
  title: string;
  subtitle: string;

  rightTitleContent?: React.ReactNode;
};

const WidgetHeader: React.FC<WidgetHeaderProps> = (
  props: WidgetHeaderProps,
) => {
  // Template
  return (
    <div className="widget-header">
      <div className="widget-header-title">
        <span className="text-md font-lexend font-semibold text-grey-dark">{props.title}</span>

        {props.rightTitleContent && (
          <span className="float-right text-dark-blue-light border-dark-blue-light rounded-md">{props.rightTitleContent}</span>
        )}
      </div>

      <div className="widget-header-title">
        <span className="text-xs text-dark-blue-light font-semibold">{props.subtitle}</span>
      </div>
    </div>
  );
};

export default WidgetHeader;
