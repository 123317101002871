const daysInMonth = (iMonth: number, iYear: number) => {
  return 32 - new Date(iYear, iMonth, 32).getDate();
};

const isWeekday = (year: number, month: number, day: number) => {
  const weekDay = new Date(year, month, day).getDay();

  return weekDay != 0 && weekDay != 6;
};

export const getWeekdaysInMonth = (month: number, year: number) => {
  const days = daysInMonth(month, year);
  let weekdays = 0;
  for (let i = 0; i < days; i++) {
    if (isWeekday(year, month, i + 1)) weekdays++;
  }

  return weekdays;
};
