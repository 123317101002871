import React, { useState } from 'react';

import { client } from '@src/apollo/apollo-client';
import {
  CreateJobDocument,
  CreateNewJobData,
  useAddJobToMyFavouritesMutation,
  useExploreSingleJobQuery,
  useLoadCompatibilityMutation,
  useRemoveJobFromMyFavouritesMutation,
  ExploreSingleJobQuery,
  Job,
} from '@src/apollo/types/graphql';
import Modal from '@src/components/core/Modal';
import JobFullCard from '@src/components/jobs/JobFullCard';

export const JobViewContext = React.createContext<{
  jobData: ExploreSingleJobQuery | undefined;
  viewJob: (id: string) => void;
  loading: boolean;
  handleToggleFavourite: () => void;
  handleCompatibilityCalculation: (
    jobId?: string,
  ) => Promise<Job | null | undefined>;
  refetch: () => void;
  createNewJob: (value: CreateNewJobData) => void;
  setClickedJobId: React.Dispatch<React.SetStateAction<string>>;
}>({
  jobData: undefined,
  viewJob: () => undefined,
  loading: false,
  refetch: () => undefined,
  handleToggleFavourite: () => undefined,
  handleCompatibilityCalculation: () => Promise.resolve(null),
  createNewJob: () => undefined,
  setClickedJobId: () => undefined,
});

export const JobViewProvider: React.FC<{ children: React.ReactNode}> = ({
  children,
}) => {
  const [clickedJobId, setClickedJobId] = useState('');

  const [modalOpened, setModalOpened] = useState(false);

  // Queries
  const { data, loading, refetch } = useExploreSingleJobQuery({
    variables: {
      where: {
        id: clickedJobId,
      },
    },
    skip: !clickedJobId,
  });

  // Mutations
  const [loadCompatibility] = useLoadCompatibilityMutation({
    variables: {
      jobId: clickedJobId,
    },
  });

  const [addJobToMyFavourites] = useAddJobToMyFavouritesMutation({
    variables: {
      jobId: clickedJobId,
    },
  });

  const [removeJobFromMyFavourites] = useRemoveJobFromMyFavouritesMutation({
    variables: {
      jobId: clickedJobId,
    },
  });

  const handleToggleFavourite = async () => {
    if (!data?.job) return;
    if (data.job.addedToFavourites) {
      removeJobFromMyFavourites();
    } else {
      addJobToMyFavourites();
    }
  };

  const viewJob = (id: string) => {
    setClickedJobId(id);
    setModalOpened(true);
  };

  const handleCompatibilityCalculation = async (jobId?: string) => {
    await loadCompatibility({ variables: { jobId: jobId || clickedJobId } });
    const jobResult = await refetch();

    return jobResult.data?.job as Job;
  };

  const createNewJob = async (newJobData: CreateNewJobData) => {
    await client.mutate({
      mutation: CreateJobDocument,
      variables: { data: newJobData },
    });
  };

  return (
    <JobViewContext.Provider
      value={{
        jobData: data,
        viewJob,
        loading,
        handleToggleFavourite,
        handleCompatibilityCalculation,
        refetch,
        createNewJob,
        setClickedJobId,
      }}
    >
      <Modal
        isOpen={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
      >
        {data?.job && (
          <JobFullCard
            job={data.job}
            closeModal={() => setModalOpened(false)}
          />
        )}
      </Modal>
      {children}
    </JobViewContext.Provider>
  );
};
