import { Combobox } from '@headlessui/react';
import { HashtagIcon, XCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useContext, useState, useRef, useMemo, FC } from 'react';

import {
  Job,
  PersonalTag,
  useAddPersonalTagMutation,
  useAvailablePersonalTagsQuery,
  useRemovePersonalTagMutation,
} from '@src/apollo/types/graphql';
import { JobViewContext } from '@src/context/JobViewContext';

interface JobTaggingProps {
  job: Pick<Job, 'id'> & {
    myPersonalTags: Pick<PersonalTag, 'id' | 'tag'>[];
  };
}

export const JobTagging: FC<JobTaggingProps> = ({ job }) => {
  const jobContext = useContext(JobViewContext);
  // Methods
  const [newTagValue, setNewTagValue] = useState('');
  const tagRef = useRef(null);
  const availableTags = useAvailablePersonalTagsQuery();
  const unusedTags = useMemo(
    () =>
      availableTags.data?.personalTags
        .filter(
          (tag) => !job.myPersonalTags.map((t) => t.tag).includes(tag.tag),
        )
        .map((tag) => tag.tag) ?? [],
    [availableTags.data, job.myPersonalTags],
  );

  const tags = useMemo(
    () => [newTagValue, ...unusedTags],
    [newTagValue, unusedTags],
  );
  const [addPersonalTag] = useAddPersonalTagMutation();
  const [removePersonalTag] = useRemovePersonalTagMutation();

  const handleAddNewTag = async (tag: string) => {
    if (tag === '') return;

    setNewTagValue('');

    await addPersonalTag({
      variables: {
        tag,
        jobId: job.id,
      },
    });
    await jobContext.refetch();
    await availableTags.refetch();
    if (tagRef.current) {
      (tagRef.current as HTMLInputElement).value = '';
    }
  };

  const handleTagRemove = async (tagId: string) => {
    await removePersonalTag({ variables: { tagId } });
    await jobContext.refetch();
    await availableTags.refetch();
  };

  return (
    <div className="flex flex-wrap items-center space-x-5 w-100 p-2">
      {job.myPersonalTags.map((tag) => (
        <div key={tag.id} className="group relative">
          <span className="text-blue-dark flex items-center">
            <HashtagIcon className="w-5 h-5" />
            {tag.tag}
          </span>
          <a
            className="hidden group-hover:block absolute right-0 top-0 text-red-500"
            href="#"
            onClick={() => handleTagRemove(tag.id)}
          >
            <XCircleIcon className="w-4 h-4" />
          </a>
        </div>
      ))}
      <Combobox as="div">
        <div className="relative mt-0 flex has-tooltip">
          <Combobox.Button
            className={classNames('flex items-center rounded-r-md ')}
          >
            <HashtagIcon className="w-4 h-4 text-blue-dark" />
          </Combobox.Button>
          <Combobox.Input
            ref={tagRef}
            className={classNames(
              'w-full text-blue-dark placeholder-blue-dark border-0 bg-white ring-none ring-inset ring-gray-300 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
            )}
            onChange={(event) => setNewTagValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleAddNewTag(event.currentTarget.value);
              }
            }}
          />

          {tags.length > 0 && (
            <Combobox.Options
              className={classNames(
                'top-10 absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              )}
            >
              {tags.map((tag) => (
                <Combobox.Option
                  key={tag}
                  value={tag}
                  onClick={() => handleAddNewTag(tag)}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-blue-dark text-white' : 'text-gray-900',
                    )
                  }
                >
                  {() => (
                    <span
                      className={classNames('block truncate', 'font-semibold')}
                    >
                      #{tag}
                    </span>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
};
