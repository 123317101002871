import React from 'react';

// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var InstallTrigger: any;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chrome: any;
  }
}

export const useBrowserDetect = () => {
  const [isChrome, setIsChrome] = React.useState<boolean>(false);
  const [isFirefox, setIsFirefox] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.chrome ? setIsChrome(true) : setIsChrome(false);
    typeof InstallTrigger !== 'undefined'
      ? setIsFirefox(true)
      : setIsFirefox(false);
  }, []);

  return {
    isChrome,
    isFirefox,
  };
};
