import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ProductKind,
  SubscriptionPlanKind,
  useManageSubscriptionsMutation,
  useTopupCreditsMutation,
  useUpgradeSubscriptionPlanMutation,
} from '@src/apollo/types/graphql';

import { MyUserContext } from './MyUserContext';

export const SubscriptionsContext = React.createContext<{
  isPro: boolean;
  upgrade: () => void;
  topup: () => void;
  manage: () => void;
}>({
  isPro: false,
  upgrade: () => undefined,
  topup: () => undefined,
  manage: () => undefined,
});

export const SubscriptionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const userContent = useContext(MyUserContext);

  const [searchParams] = useSearchParams();

  const [topupCredits] = useTopupCreditsMutation({
    variables: {
      redirectUrl: window.location.href,
    },
  });
  const [upgradeSubscription] = useUpgradeSubscriptionPlanMutation({
    variables: {
      redirectUrl: window.location.href,
    },
  });
  const [manageSubscriptions] = useManageSubscriptionsMutation({
    variables: {
      redirectUrl: window.location.href,
    },
  });

  const handleCheckout = async (
    lookupKey: ProductKind | SubscriptionPlanKind,
  ) => {
    if (lookupKey === ProductKind.Topup) {
      topupCredits()
        .then((res) => {
          if (!res.data?.topupCredits) return;
          window.location.href = res.data?.topupCredits;
        })
        .catch(() => {
          toast("Couldn't topup credits. Please try again later.");
        });
    }

    if (lookupKey === SubscriptionPlanKind.PremiumPlan) {
      upgradeSubscription()
        .then((res) => {
          if (!res.data?.upgradePlan) return;
          window.location.href = res.data?.upgradePlan;
        })
        .catch(() => {
          toast("Couldn't upgrade subscription. Please try again later.");
        });
    }
  };

  const handleManageSubscription = async () => {
    manageSubscriptions()
      .then((res) => {
        if (!res.data?.manageSubscriptions) return;
        window.location.href = res.data?.manageSubscriptions;
      })
      .catch(() => {
        toast("Couldn't manage subscription. Please try again later.");
      });
  };

  useEffect(() => {
    if (
      searchParams.get('checkout') === 'topup' &&
      searchParams.get('auto') === 'true'
    ) {
      handleCheckout(ProductKind.Topup);
    }

    if (
      searchParams.get('checkout') === 'subscription' &&
      searchParams.get('auto') === 'true'
    ) {
      handleCheckout(SubscriptionPlanKind.PremiumPlan);
    }

    if (searchParams.get('subscription-success') === 'true') {
      toast.success('Subscription successful!');
    }
    if (searchParams.get('topup-success') === 'true') {
      toast.success(`Topup successful! 
      Credits will be added to your account.`);
    }
  }, [searchParams]);

  return (
    <SubscriptionsContext.Provider
      value={{
        isPro: !!userContent.myUser?.isPro,
        topup: () => handleCheckout(ProductKind.Topup),
        upgrade: () => handleCheckout(SubscriptionPlanKind.PremiumPlan),
        manage: () => {
          handleManageSubscription();
        },
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};
