import React from 'react';

import backgroundImage from '@src/images/background-auth.jpg';

const JpAuthLayout = (props: React.PropsWithChildren) => {
  return (
    <>
      <div className="relative flex h-screen justify-center md:px-12 lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {props.children}
          </div>
          {/* 
          //TODO!! UNCOMENT THIS WHEN WE HAVE MORE LANGUAGES
          <div className="flex self-center gap-4 items-center border-y border-gray-200 mt-10 px-3">
            <span>{t('preferences.languageLabel')}: </span>

            <LanguageDropdown />
          </div> */}
        </div>

        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImage}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default JpAuthLayout;
