import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Select, { type MultiValue } from 'react-select';

import { FilterOptions } from '@src/apollo/types/graphql';
import { ListContext } from '@src/context/JobListContext';
import { FilterOption, OptionDetail } from '@src/types/sorting';

export const FiltersPanel: React.FC<{
  filtersToggled: boolean;
  toggleFilters: (value: boolean) => void;
  filterOptions?: FilterOptions;
}> = ({ filtersToggled, toggleFilters, filterOptions }) => {
  const { applyFilters, currentFilters } = useContext(ListContext);

  const [filtersOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: MultiValue<OptionDetail>;
  }>({});

  useEffect(() => {
    if (filtersOptions.length > 0 || !filterOptions) return;

    setFilterOptions([
      {
        id: 'hourlyRate',
        name: 'Salary intervals',
        options:
          filterOptions?.hourlyRate?.map((item) => ({
            value: item,
            label: item.label,
            checked: false,
          })) ?? [],
      },
      {
        id: 'type',
        name: 'Type',
        options:
          filterOptions?.jobTypes?.map((type) => ({
            value: type,
            label: type,
            checked: false,
          })) ?? [],
      },
      {
        id: 'industry',
        name: 'Industry',
        options:
          filterOptions?.industries?.map((industry) => ({
            value: industry,
            label: industry,
            checked: false,
          })) ?? [],
      },
      {
        id: 'location',
        name: 'Location',
        options:
          filterOptions?.locations?.map((location) => ({
            value: location,
            label: location,
            checked: false,
          })) ?? [],
      },
      {
        id: 'personalTags',
        name: 'Tag',
        options:
          filterOptions?.tags?.map((tag) => ({
            value: tag,
            label: `#${tag}`,
            checked: false,
          })) ?? [],
      },
    ]);
  }, [filterOptions]);

  useEffect(() => {
    const newFilters = filtersOptions.reduce((acc, option) => {
      const values = option.options
        .filter((o) => o.checked)
        .map((o) => o.value);

      if (values.length === 0) {
        return { ...acc, [option.id]: undefined };
      }

      switch (option.id) {
        case 'type':
          acc.type = {
            in: values,
          };

          break;
        case 'industry':
          acc.industry = {
            in: values,
          };

          break;
        case 'location':
          acc.location = {
            in: values,
          };
          break;
        case 'personalTags':
          acc.personalTags = {
            some: {
              tag: {
                in: values,
              },
            },
          };
          break;
        case 'hourlyRate':
          acc.hourlyRate = {
            lte: Math.max(...values.map((v) => v.lte)),
            gte: Math.min(...values.map((v) => v.gte)),
          };
          break;
        default:
          break;
      }

      return acc;
    }, currentFilters);

    applyFilters(newFilters);
  }, [filtersOptions]);

  const onOptionChange = (
    sectionId: string,
    selectedItems: MultiValue<OptionDetail>,
  ) => {
    const newFilters = filtersOptions.map((section) => {
      if (section.id === sectionId) {
        section.options.map((option) => {
          if (selectedItems.includes(option)) {
            option.checked = true;
          } else {
            option.checked = false;
          }
        });
      }

      return section;
    });

    setSelectedFilters({ ...selectedFilters, [sectionId]: selectedItems });

    setFilterOptions(newFilters);
  };

  return (
    filtersOptions.length > 0 && (
      <>
        <Transition.Root show={filtersToggled} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40"
            onClose={() => toggleFilters(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                      onClick={() => toggleFilters(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="px-4">
                    <h2 className="sr-only">Filters</h2>
                    <div>
                      <form className="mt-5 space-y-0">
                        {filtersOptions.map((section) => (
                          <div key={section.name} className="pt-4">
                            <fieldset>
                              <legend className="block text-sm font-medium text-gray-900">
                                {section.name}
                              </legend>

                              <Select
                                options={section.options}
                                isMulti={true}
                                className="react-multi-select w-[288px] text-sm"
                                onChange={(selected) =>
                                  onOptionChange(section.id, selected)
                                }
                                value={selectedFilters[section.id] || []}
                              />
                            </fieldset>
                          </div>
                        ))}
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  );
};
