import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useMemo } from 'react';

import { Task_Code } from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';

import WidgetFrame from './misc/WidgetFrame';
import WidgetHeader from './misc/WidgetHeader';

type TaskCodeDetails = {
  title: string;
  subtitle: string;
};

const taskCodeDetails: Record<Task_Code, TaskCodeDetails> = {
  DOWNLOAD_EXTENSION: {
    title: 'Unleash the superpowers',
    subtitle: 'Install our browser extension for exclusive features',
  },
  CALCULATE_COMPATIBILITIES: {
    title: 'Find compatibilities',
    subtitle: 'Reveal 5 job matches that align with your skills',
  },
  JOB_BOOKMARK: {
    title: 'Create personal wishlist',
    subtitle: 'Bookmark 5 of your favorite jobs for easy access',
  },
  JOB_DISCOVERY: {
    title: 'Discover opportunities',
    subtitle: 'Explore 10 exciting jobs that match your interests',
  },
  UPLOAD_RESUME: {
    title: 'Ignite your potential',
    subtitle: 'Upload your resume and stand out from the crowd',
  },
};

const TasksWidget: React.FC = () => {
  const { myUser } = useContext(MyUserContext);

  const possibleCredits = useMemo(() => {
    const total = (myUser?.taskList ?? []).reduce(
      (acc, task) => acc + task.reward,
      0,
    );

    return total;
  }, [myUser?.taskList]);

  const creditsEarned = useMemo(() => {
    const total = (myUser?.taskList ?? []).reduce((acc, task) => {
      if (task.rewardPaid) {
        return acc + task.reward;
      } else {
        return acc;
      }
    }, 0);

    return total;
  }, [myUser?.taskList]);

  return (
    <WidgetFrame
      queriesReady={true}
      queriesLoading={false}
      queriesError={undefined}
      extraClasses="w-full p-3"
    >
      <WidgetHeader
        title="Tasks"
        subtitle={`Earn up to ${possibleCredits} AI Credits through rewarding tasks`}
      />

      <div className="tasks-notice items-center mt-5 inline-flex p-3">
        <div className="mr-1">
          <TrophyIcon className="stroke-blue-dark h-9"></TrophyIcon>
        </div>
        <div className="">
          <div className="tasks-notice-title">
            <span className="text-md font-bold bg-clip-text text-transparent bg-gradient-to-tr from-blue-dark via-purple-dark to-purple-light">
              {creditsEarned > 0 ? 'Congratulations!' : 'Oh no!'}
            </span>
          </div>
          <div className="tasks-notice-subtitle">
            <p className="text-xs text-blue-dark flex-wrap">
              You've earned a total of{' '}
              <span className="font-bold bg-clip-text text-transparent bg-gradient-to-tr from-blue-dark via-purple-dark to-purple-light">
                {creditsEarned} AI Credits
              </span>{' '}
              so far!
            </p>
          </div>
        </div>
      </div>

      <div className="tasks-list gap-5 grid grid-cols-12 items-center p-3">
        {myUser?.taskList.map((task) => {
          return (
            <React.Fragment key={task.id}>
              <div className="col-span-11">
                <div className="tasks-list-reward">
                  {!task.isSolved && !task.rewardPaid && (
                    <span className="text-sm font-semibold font-lexend float-right bg-clip-text text-transparent bg-gradient-to-tr from-blue-dark via-purple-dark to-purple-light">
                      +{task.reward} credits
                    </span>
                  )}
                </div>
                <div className="tasks-list-title">
                  <span className="text-md font-lexend text-grey-dark">
                    {taskCodeDetails[task.taskCode].title}
                  </span>
                </div>
                <div className="tasks-list-subtitle">
                  <span className="text-xs text-grey-dark">
                    {taskCodeDetails[task.taskCode].subtitle}
                  </span>
                </div>
              </div>
              <div className="col-span-1">
                {task.isSolved && (
                  <CheckCircleIcon className="w-6 stroke-green-500"></CheckCircleIcon>
                )}

                {!task.isSolved &&
                  (task.rewardPaid ? (
                    <ExclamationCircleIcon className="w-6 stroke-red-500"></ExclamationCircleIcon>
                  ) : (
                    <ExclamationCircleIcon className="w-6 stroke-yellow-500"></ExclamationCircleIcon>
                  ))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </WidgetFrame>
  );
};

export default TasksWidget;
