import { PropsWithChildren } from 'react';

interface SalientLabelProps extends PropsWithChildren {
  id: string;
}

export const SalientLabel: React.FC<SalientLabelProps> = (
  props: SalientLabelProps,
) => {
  return (
    <label htmlFor={props.id} className="label">
      {props.children}
    </label>
  );
};
