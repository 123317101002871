import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';

import { JpWaitingSpinner } from '@src/components/misc/JpWaitingSpinner';
import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';
import {
  AiCreditsIconOutline,
  AiCreditsIconSolid,
} from '@src/images/GradientSvgIcons';

const primaryGradientClass = 'bg-gradient-to-tr from-purple-dark to-blue-dark';

export default function AiCredits() {
  const { myUser, loading, error } = useContext(MyUserContext);
  const { topup, isPro } = useContext(SubscriptionsContext);

  const credits = useMemo(() => {
    return isPro ? '∞' : myUser?.credits || 0;
  }, [myUser?.credits]);

  if (loading) {
    return (
      <button
        disabled
        className="btn btn-ghost btn-sm relative flex justify-center items-center w-8 h-8 ring-1 ring-white/20 rounded-md"
      >
        <JpWaitingSpinner displayLoadingText={false} />
      </button>
    );
  }

  if (error) {
    return (
      <button
        disabled
        className="btn btn-ghost btn-sm relative flex justify-center items-center w-8 h-8 ring-1 ring-white/20 rounded-md"
      >
        <FontAwesomeIcon icon={faCoins} className="w-5 h-5" />
      </button>
    );
  }

  return (
    <button className="w-32 md:w-10 lg:w-32" onClick={() => topup()}>
      <div
        className={classNames(
          'group flex flex-row justify-around items-center',
          'px-4 md:px-1 lg:px-4 py-1',
          'ai-border-gradient',
        )}
      >
        <AiCreditsIconOutline
          id={nanoid()}
          className="w-5 h-5 block group-hover:hidden"
        />

        <AiCreditsIconSolid
          id={nanoid()}
          className="w-5 h-5 hidden group-hover:block"
        />

        <div className="block md:hidden lg:block">
          <h2
            className={classNames(
              'font-inter text-xxxs font-normal leading-tight',
              'bg-clip-text text-transparent',
              primaryGradientClass,
            )}
          >
            AI Credits
          </h2>

          <h1
            className={classNames(
              'font-lexend text-sm font-bold leading-tight bg-clip-text text-transparent',
              primaryGradientClass,
            )}
          >
            {credits}
          </h1>
        </div>
      </div>
    </button>
  );
}
