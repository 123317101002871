import { formatDistance } from 'date-fns';
import { enUS, ro } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  NotificationSeverity,
  NotificationKind,
  Scalars,
} from '@src/apollo/types/graphql';
import { ThemeKind, currentTheme } from '@src/store/reducers/ui';

type SeverityMapper = {
  [NotificationSeverity.Info]: 'info';
  [NotificationSeverity.Success]: 'success';
  [NotificationSeverity.Warning]: 'warning';
  [NotificationSeverity.Error]: 'error';
};

export const useNotifications = () => {
  // Constants
  const severityMapper: SeverityMapper = {
    [NotificationSeverity.Info]: 'info',
    [NotificationSeverity.Success]: 'success',
    [NotificationSeverity.Warning]: 'warning',
    [NotificationSeverity.Error]: 'error',
  };

  // Hooks
  const { t, i18n } = useTranslation();

  const theme = useSelector(currentTheme);

  // Methods
  const getNotificationSeverity = (severity?: NotificationSeverity) => {
    if (!severity) {
      return 'info';
    }

    return severityMapper[severity];
  };

  const getNotificationMessage = (message?: NotificationKind) => {
    switch (message) {
      case NotificationKind.AccountActivated:
        return t('notifications.message.accountActivated');
      default: {
        return null;
      }
    }
  };

  const getNotificationAge = (createdAt?: Scalars['DateTimeISO']) => {
    const currentDate = new Date();

    const notificationDate = new Date(createdAt || currentDate);

    const locale = () => {
      switch (i18n.language) {
        case 'en':
          return { locale: enUS };
        case 'ro':
          return { locale: ro };
      }
    };

    return formatDistance(notificationDate, currentDate, locale());
  };

  const getNotificationTheme = () => {
    switch (theme) {
      case ThemeKind.LIGHT:
        return 'light';
      case ThemeKind.DARK:
        return 'dark';
      default:
        return 'light';
    }
  };

  return {
    getNotificationSeverity,
    getNotificationMessage,
    getNotificationTheme,
    getNotificationAge,
  };
};
