import { useFindManyPortalsQuery } from '@src/apollo/types/graphql';
import AverageSalaryWidget from '@src/components/dashboard/widgets/AverageSalaryWidget';
import CompatibilityWidget from '@src/components/dashboard/widgets/CompatibilityWidget';
import ExploreWidget from '@src/components/dashboard/widgets/ExploreWidget';
import FeaturedJobsWidget from '@src/components/dashboard/widgets/FeaturedJobsWidget';
import JobNumbersChartWidget from '@src/components/dashboard/widgets/JobNumbersChartWidget';
import MapWidget from '@src/components/dashboard/widgets/MapWidget';
import TasksWidget from '@src/components/dashboard/widgets/TasksWidget';
import TopSkillsWidget from '@src/components/dashboard/widgets/TopSkillsWidget';
import TotalJobsWidget from '@src/components/dashboard/widgets/TotalJobsWidget';

export const Dashboard = () => {
  const { data, loading } = useFindManyPortalsQuery();

  return (
    <>
      <div className="flex h-full max-w-full flex-col gap-2 container">
        <div id="scrollableDiv" className="h-full overflow-y-scroll m-1 p-2">
          <div className="gap-4 grid grid-cols-9 xl:grid-cols-12">
            <div className="col-span-9 flex flex-col">
              <div className="gap-4 grid grid-cols-4 xl:grid-cols-12 flex-1">
                <div className="col-span-4 flex flex-col">
                  <TotalJobsWidget />
                </div>
                <div className="col-span-4 flex flex-col">
                  <AverageSalaryWidget />
                </div>
                <div className="col-span-4 flex flex-col">
                  <CompatibilityWidget />
                </div>
              </div>
              <div className="gap-4 grid grid-cols-8 grid-rows-12 xl:grid-cols-12 mt-4 flex-1">
                <div className="col-span-8 xl:col-span-4 flex flex-col">
                  <TasksWidget />
                </div>
                <div className="col-span-8 flex flex-col">
                  <JobNumbersChartWidget />
                </div>
              </div>
            </div>
            <div className="col-span-9 xl:col-span-3 flex flex-col">
              <MapWidget />
            </div>
            <div className="col-span-9 xl:col-span-12 flex flex-col">
              <div className="gap-4 grid grid-cols-12 flex-1">
                <div className="col-span-12 xl:col-span-6 flex flex-col">
                  <FeaturedJobsWidget />
                </div>
                <div className="col-span-12 xl:col-span-3 flex flex-col">
                  <TopSkillsWidget />
                </div>
                {!loading && data ? (
                  <div className="col-span-12 xl:col-span-3 flex flex-col">
                    <ExploreWidget />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
