import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { type PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Job, JobToCompareQuery } from '@src/apollo/types/graphql';
import CompatibilityBlurLines from '@src/assets/radial-chart/ColorBG.png';
import CompatibilityBlur from '@src/assets/radial-chart/RadarChart.png';
import CompanyLogoImage from '@src/components/company/CompanyLogo';
import { UnlockComaptibilityButton } from '@src/components/compatibility/misc/UnlockCompatibilityButton';
import Modal from '@src/components/core/Modal';
import { CompatibilityRadialChart } from '@src/components/jobs/CompatibilityRadialChart';
import { JobViewContext } from '@src/context/JobViewContext';
import { MyUserContext } from '@src/context/MyUserContext';
import { LoadingIcon } from '@src/images/SvgIcons';
import {
  jobsToCompare as jobsToCompareSelector,
  setJobsToCompare,
  setJobData,
} from '@src/store/reducers/jobs';
// import { formatArray, formatString } from '@src/utils/utilities';

type JobToCompare = JobToCompareQuery['job'];
type JobToCompareKey =
  | 'company'
  | 'company.logo'
  | 'company.name'
  | 'title'
  | 'industry'
  | 'type'
  | 'salary'
  | 'knowledge'
  | 'address.streetAddress'
  | 'externalUrl'
  | 'myCompatibility'
  | 'skills'
  | 'qualifications'
  | 'languages'
  | 'technologies';

type CompareRowLabelTypes = {
  name: string;
  id: JobToCompareKey;
};

const JobsCompareBoard = () => {
  // Hooks
  const jobContext = useContext(JobViewContext);
  const { myUser } = useContext(MyUserContext);

  const jobsToCompare: JobToCompare[] = useSelector(jobsToCompareSelector);

  const dispatch = useDispatch();

  // Methods
  const handleRemoveJobFromCompare = (jobId?: string) => {
    if (!jobId) {
      return;
    }

    const newJobsToCompare = jobsToCompare.filter((job) => job?.id !== jobId);

    dispatch(setJobsToCompare(newJobsToCompare));
  };

  const getNestedProperty = (
    job: JobToCompare,
    propertyString: JobToCompareKey,
  ) => {
    const properties = propertyString.split('.');

    const values = properties.reduce(
      (obj, prop) => obj && obj[prop as keyof JobToCompare],
      job,
    );

    if (Array.isArray(values)) {
      return values.join(', ');
    }

    return String(values);
  };

  const mySkillsToLowerCase = myUser?.resume?.knowledge.map((skill) =>
    skill.toLocaleLowerCase(),
  );

  const compareRowLabelList: CompareRowLabelTypes[] = useMemo(
    () => [
      { name: 'Company', id: 'company' },
      { name: 'Job Title', id: 'title' },
      { name: 'Industry', id: 'industry' },
      { name: 'Employment Type', id: 'type' },
      { name: 'Salary', id: 'salary' },
      { name: 'Location', id: 'address.streetAddress' },
      { name: 'Compatibility', id: 'myCompatibility' },
      { name: 'Technical Knowledge', id: 'knowledge' },
      { name: 'Languages', id: 'languages' },
      { name: 'Qualifications', id: 'qualifications' },
      { name: 'Key technologies', id: 'technologies' },
      { name: 'Skills', id: 'skills' },
    ],
    [jobContext.jobData],
  );

  return (
    <div className="container max-w-full px-14 py-10">
      {compareRowLabelList.map((row) => {
        return (
          <div
            key={row.id}
            className="grid grid-flow-col auto-cols-fr gap-x-2 card border-b last:border-none"
          >
            <div
              className="hidden justify-center items-center  text-center lg:flex card-body text-xs font-semibold text-grey-light-00"
              key={row.name}
            >
              {row.name}
            </div>

            {jobsToCompare.map((job: JobToCompare, index: number) => {
              return (
                <div
                  key={index}
                  className="flex justify-center items-center text-center py-4"
                >
                  {row.id === 'company' && (
                    <div className="relative grid text-center items-center justify-items-center w-full">
                      <button
                        className="relative sm:absolute sm:top-1 sm:right-1 text-grey-dark hover:text-blue-dark"
                        onClick={() => {
                          handleRemoveJobFromCompare(job?.id);
                        }}
                      >
                        <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                      <div className="shadow-lg rounded-xl p-2 text-center mb-2">
                        {job?.company && (
                          <a
                            href={job?.externalUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CompanyLogoImage
                              company={job.company}
                              classNames="w-16"
                            />
                          </a>
                        )}
                      </div>
                      <p className="text-xs text-grey-light-01 py-1 font-lexend font-medium hidden sm:block">
                        {getNestedProperty(job, 'company.name')}
                      </p>
                    </div>
                  )}
                  {row.id === 'title' && (
                    <p className="text-xs text-grey-light-01 py-1 font-lexend font-medium hidden sm:block">
                      {getNestedProperty(job, 'title')}
                    </p>
                  )}
                  {row.id === 'industry' && (
                    <p className="text-xs text-grey-light-01 py-1 font-lexend font-medium hidden sm:block">
                      {getNestedProperty(job, 'industry')}
                    </p>
                  )}
                  {row.id === 'type' && (
                    <p className="text-xs text-grey-light-01 py-1 font-lexend font-medium hidden sm:block">
                      {getNestedProperty(job, 'type')}
                    </p>
                  )}
                  {row.id === 'salary' && (
                    <p className="text-xs text-grey-light-01 py-1 font-lexend font-medium hidden sm:block">
                      {getNestedProperty(job, 'salary')}
                    </p>
                  )}

                  {row.id === 'address.streetAddress' && (
                    <p className="text-xs text-grey-light-01 py-1 font-lexend font-medium hidden sm:block">
                      {getNestedProperty(job, 'address.streetAddress')}
                    </p>
                  )}
                  {row.id !== 'company' && row.id !== 'myCompatibility' && (
                    <span className="text-xs text-grey-dark font-lexend font-medium break-all sm:break-normal"></span>
                  )}
                  {row.id === 'myCompatibility' && job?.myCompatibility && (
                    <CompatibilityRadialChart
                      chartDivHeight="200px"
                      chartDivWidth="300px"
                      myCompatibility={job.myCompatibility}
                    />
                  )}

                  {row.id === 'myCompatibility' && !job?.myCompatibility && (
                    <div className="flex items-center justify-center">
                      <div className="relative w-1/2 mt-5 h-52">
                        <div className="w-[300px] h-[200px]">
                          <img
                            className="absolute top-[15%] blur-lg"
                            src={CompatibilityBlur}
                            alt="no img"
                          />
                          <img
                            className="absolute top-[15%]"
                            src={CompatibilityBlurLines}
                            alt="no img"
                          />
                        </div>

                        {!jobContext.loading && (
                          <div
                            className={clsx(
                              'absolute top-[33%] left-[25%]',
                              'flex flex-col justify-center items-center',
                              !jobContext.loading
                                ? 'bg-white rounded-full'
                                : '',
                            )}
                          >
                            <UnlockComaptibilityButton
                              handleLoadCompatibility={async () => {
                                await jobContext.setClickedJobId(
                                  job ? job.id : '',
                                );
                                const calculatedJob =
                                  await jobContext.handleCompatibilityCalculation(
                                    job?.id,
                                  );
                                if (!calculatedJob) return;

                                dispatch(setJobData(calculatedJob as Job));
                              }}
                              jobId={job?.id ?? ''}
                            />
                          </div>
                        )}
                        {jobContext.loading && (
                          <div
                            className={clsx(
                              'absolute top-2/5 left-2/5',
                              'flex flex-col justify-center items-center',
                              !jobContext.loading
                                ? 'bg-white rounded-full'
                                : '',
                            )}
                          >
                            <div
                              className={clsx(
                                'flex items-center group w-full h-full justify-center',
                                'rounded-full p-1 text-blue-dark font-medium text-sm',
                                'animate-spin border-0 ',
                              )}
                            >
                              <LoadingIcon className="w-7 h-7" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {row.id === 'knowledge' && (
                    <div className="flex justify-start p-2">
                      <div className="w-full">
                        <div className="flex flex-wrap gap-2 my-4">
                          <div className="flex flex-wrap gap-1">
                            {job?.knowledge.map((skill, idx) => {
                              return (
                                <span
                                  key={idx}
                                  className={`${
                                    job.knowledge
                                      ?.filter((skill) => {
                                        return mySkillsToLowerCase?.includes(
                                          skill.toLowerCase(),
                                        );
                                      })
                                      .includes(skill)
                                      ? 'border-2 border-blue-dark '
                                      : ' '
                                  }text-blue-dark inline-flex items-center rounded-full bg-blue-light-02 px-2 py-1 text-xxs font-medium `}
                                >
                                  {skill}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {row.id === 'languages' && (
                    <div className="flex justify-start p-2">
                      <div className="w-full">
                        <div className="flex flex-wrap gap-2 my-4">
                          <div className="flex flex-wrap gap-1">
                            {job?.languages.map((skill, idx) => {
                              return (
                                <span
                                  key={idx}
                                  className={`${
                                    job.languages
                                      ?.filter((skill) => {
                                        return mySkillsToLowerCase?.includes(
                                          skill.toLowerCase(),
                                        );
                                      })
                                      .includes(skill)
                                      ? 'border-2 border-yellow-600 '
                                      : ' '
                                  }text-yellow-800 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xxs font-medium `}
                                >
                                  {skill}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {row.id === 'qualifications' && (
                    <div className="flex flex-wrap gap-2 my-4">
                      <div className="flex flex-wrap gap-2">
                        {job?.qualifications.map((skill, idx) => {
                          return (
                            <span
                              key={idx}
                              className={`${
                                job.knowledge
                                  ?.filter((skill) => {
                                    return mySkillsToLowerCase?.includes(
                                      skill.toLowerCase(),
                                    );
                                  })
                                  .includes(skill)
                                  ? 'border-2 border-purple-700 '
                                  : ' '
                              }text-purple-700 inline-flex items-center rounded-full bg-purple-100 px-2 py-1 text-xxs font-medium `}
                            >
                              {skill}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {row.id === 'technologies' && (
                    <div className="flex justify-start p-2">
                      <div className="w-full">
                        <div className="flex flex-wrap gap-2 my-4">
                          <div className="flex flex-wrap gap-1">
                            {job?.technologies.map((skill, idx) => {
                              return (
                                <span
                                  key={idx}
                                  className={`${
                                    job.technologies
                                      ?.filter((skill) => {
                                        return mySkillsToLowerCase?.includes(
                                          skill.toLowerCase(),
                                        );
                                      })
                                      .includes(skill)
                                      ? 'border-2 border-green-700 '
                                      : ' '
                                  }text-green-700 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xxs font-medium `}
                                >
                                  {skill}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {row.id === 'skills' && (
                    <div className="flex flex-wrap gap-2 my-4">
                      <div className="flex flex-wrap gap-2">
                        {job?.skills.map((skill, idx) => {
                          return (
                            <span
                              key={idx}
                              className={`${
                                job.skills
                                  ?.filter((skill) => {
                                    return mySkillsToLowerCase?.includes(
                                      skill.toLowerCase(),
                                    );
                                  })
                                  .includes(skill)
                                  ? 'border-2 border-gray-600 '
                                  : ' '
                              }text-gray-600 gap-x-1.5 inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xxs font-medium `}
                            >
                              {skill}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const JobsCompareWidget: React.FC<PropsWithChildren> = () => {
  // Hooks

  const jobsToCompare = useSelector(jobsToCompareSelector);

  const dispatch = useDispatch();

  // States

  const [compareModalOpened, setCompareModalOpened] = useState(false);

  const [compareListMinimized, setCompareListMinimized] = useState(false);

  const [topPosition, setTopPosition] = useState('');
  const hFitRef = useRef<HTMLDivElement>(null);

  // Effects

  useEffect(() => {
    if (jobsToCompare.length <= 1) {
      setCompareModalOpened(false);
    }
  }, [jobsToCompare]);

  useEffect(() => {
    calcPosition();
  });

  // Methods

  const handleRemoveJobFromCompare = (jobId?: string) => {
    if (!jobId) {
      return;
    }

    const newJobsToCompare = jobsToCompare.filter((job) => job?.id !== jobId);

    dispatch(setJobsToCompare(newJobsToCompare));
  };

  const calcPosition = () => {
    if (hFitRef.current) {
      const hFitHeight = hFitRef.current.getBoundingClientRect().height;
      const topValue = `calc(50% - ${hFitHeight / 2}px)`;
      setTopPosition(topValue);
    }
  };

  return (
    jobsToCompare.length > 0 && (
      <div
        className={`
      ${compareListMinimized ? 'h-14' : 'h-fit'}
    bg-white shadow-xl shadow-black/30 rounded-lg w-24 fixed  right-8 z-40 grid justify-items-center
      transition-all ease-in-out
    `}
        ref={hFitRef}
        style={{ top: topPosition }}
      >
        <Modal
          isOpen={compareModalOpened}
          onClose={() => {
            setCompareModalOpened(false);
          }}
          isJobCompareWidget={true}
        >
          <JobsCompareBoard />
        </Modal>

        <button
          className="btn flex justify-center transition-all ease-in-out bg-blue-dark  text-white w-full py-0 hover:py-1 rounded-t-lg shadow-md shadow-blue-dark/40 border-b-[1px]"
          onClick={() => {
            setCompareListMinimized(!compareListMinimized);
            calcPosition();
          }}
        >
          <ChevronDownIcon
            className={`h-3 w-3 ${compareListMinimized ? 'rotate-180' : ''}`}
            aria-hidden="true"
          />
        </button>

        {jobsToCompare.map((job) => {
          return (
            <>
              {job && (
                <div
                  key={job.id}
                  className={`
            cursor-pointer w-16 h-16 mx-4 my-3 p-1
            shadow-lg shadow-blue-dark/30 hover:shadow-blue-dark/50
            rounded-sm grid justify-center items-center
            ${compareListMinimized ? 'hidden' : ''}
          `}
                  onClick={() => {
                    handleRemoveJobFromCompare(job.id);
                  }}
                >
                  {job.company && (
                    <CompanyLogoImage company={job.company} classNames="w-10" />
                  )}
                </div>
              )}
            </>
          );
        })}

        <button
          className="
          btn flex justify-center my-2 px-2 py-1 rounded-md
          bg-blue-dark disabled:bg-grey-light-01
          text-xs text-white
          shadow-sm shadow-blue-dark/50
          hover:shadow-md hover:shadow-blue-dark/30
        "
          disabled={jobsToCompare.length <= 1}
          onClick={() => setCompareModalOpened(true)}
        >
          Compare ({jobsToCompare.length})
        </button>
      </div>
    )
  );
};

export default JobsCompareWidget;
