import classNames from 'classnames';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionPlanKind } from '@src/apollo/types/graphql';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';
import { CheckIcon } from '@src/images/SvgIcons';

export type JpPlanProps = {
  name: string;
  price: string;
  description: string;
  features: string[];
  kind: SubscriptionPlanKind;
  featured?: boolean;
  active?: boolean;
  onSubmitButtonClick?: () => void;
};

export const JpPlan = ({
  name,
  price,
  description,
  features,
  featured = false,
  kind,
  onSubmitButtonClick,
  active = false,
}: JpPlanProps) => {
  const { t } = useTranslation();
  const subscriptionContext = useContext(SubscriptionsContext);

  return (
    <>
      <section
        className={clsx(
          'flex flex-col gap-3 rounded-3xl px-6 sm:px-8',
          'ai-border-gradient',
          featured ? 'py-8 lg:order-none' : 'lg:py-8',
        )}
      >
        <h2 className={classNames('mt-5 font-display font-bold text-xl')}>
          {name}
          {active && (
            <span className="m-3 rounded text-blue-dark bg-blue-light-02 p-1 text-blue-dark font-medium text-sm">
              {t('subscriptionPlans.buttons.currentPlan')}
            </span>
          )}
        </h2>
        <p
          className={classNames(
            'font-display text-4xl font-light tracking-tight',
            featured
              ? 'bg-clip-text text-transparent bg-gradient-to-tr from-blue-dark via-purple-dark to-purple-light'
              : 'text-blue-dark',
          )}
        >
          {kind === SubscriptionPlanKind.PremiumPlan ? (
            <>
              9$ /month{' '}
              <span className="line-through text-blue-dark text-2xl">
                (11$ /month)
              </span>
            </>
          ) : (
            price
          )}
        </p>
        <p className={clsx('mt-2 text-base')}>{description}</p>

        <ul className={clsx('flex flex-col gap-y-3 text-sm', 'text-dark')}>
          {features.map((feature) => (
            <li key={feature} className="flex">
              <CheckIcon
                className={featured ? 'text-dark' : 'text-slate-400'}
              />
              <span className="ml-4">{feature}</span>
            </li>
          ))}
        </ul>
        {!active && (
          <button
            className={classNames(
              'my-5 py-3 px-3 rounded-lg',
              'text-white text-xs font-medium',
              kind === SubscriptionPlanKind.PremiumPlan
                ? 'bg-gradient-to-tr from-purple-light to-blue-dark'
                : '',
              'flex flex-col items-center gap-1',
            )}
            onClick={() => {
              if (onSubmitButtonClick) {
                onSubmitButtonClick();
              }
            }}
          >
            <span>Upgrade now</span>
          </button>
        )}

        {active && kind === SubscriptionPlanKind.PremiumPlan && (
          <button
            className={classNames(
              'my-5 py-3 px-3 rounded-lg bg-blue-light-02',
              'text-grey-dark text-sm font-medium',
              'flex flex-col items-center gap-1',
            )}
            onClick={() => subscriptionContext.manage()}
          >
            <span>Manage subscription</span>
          </button>
        )}
      </section>
    </>
  );
};
