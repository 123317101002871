import classNames from 'classnames';
import clsx from 'clsx';
import React, { useContext } from 'react';

import JpUserInfo from '@src/components/misc/JpUserInfo';
import JpAiCredits from '@src/components/navbars/misc/JpAiCredits';
import JpMenu from '@src/components/navbars/misc/JpMenu';
import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';
import JobpediaLogo from '@src/images/JobpediaLogo.svg';

const JpSidebar: React.FC = () => {
  const subscriptionContext = useContext(SubscriptionsContext);
  const userContext = useContext(MyUserContext);

  return (
    <div
      className={clsx({
        'flex flex-col justify-between items-center gap-5 p-5 lg:p-25': true, // layout
        'h-full w-full': true, // for height and width
        'transition-transform .3s ease-in-out md:translate-x-0': true, // animations
        'bg-white border-r text-grey-dark border-r-blue-light-01': true,
      })}
    >
      <div className={clsx('flex items-baseline mt-20 mr-0 md:mt-10')}>
        <img src={JobpediaLogo} alt="Jobpedia" width={35} />

        <span
          className={clsx(
            'text-lg font-lexend leading-none',
            'hidden lg:block',
          )}
        >
          Jobpedia.AI
        </span>
      </div>

      <JpMenu />

      <div
        className={clsx(
          'flex flex-col w-full justify-end items-center',
          'gap-12 lg:gap-3',
        )}
      >
        <JpUserInfo />
        {userContext.myUser?.profile.emailVerified === true ? (
          <JpAiCredits />
        ) : null}

        {userContext.myUser?.profile.emailVerified === true ? (
          <>
            {!subscriptionContext.isPro && (
              <button
                className={classNames(
                  'my-5 py-2 px-3 rounded-lg',
                  'text-white text-xs font-medium bg-gradient-to-tr from-purple-light to-blue-dark',
                  'flex flex-col items-center gap-1',
                )}
                onClick={() => subscriptionContext.upgrade()}
              >
                <span>Upgrade now</span>
                <span className="text-xxs">Unlock unlimited credits</span>
              </button>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default JpSidebar;
