import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { useEffect, useRef } from 'react';

import { CompatibilityResponse } from '@src/apollo/types/graphql';

interface CompatibilityRadialChartProps {
  chartDivHeight?: string;
  chartDivWidth?: string;

  myCompatibility: Pick<
    CompatibilityResponse,
    'general' | 'skills' | 'knowledge'
  >;
}

export const CompatibilityRadialChart = ({
  myCompatibility,
  chartDivHeight = '275px',
  chartDivWidth = '300px',
}: CompatibilityRadialChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chartDoc = chartRef.current;

    if (!chartDoc || chartDoc.childNodes.length > 0) {
      return;
    }

    // !! HACK I think
    const chartDiv = document.createElement('div');
    chartDiv.style.height = chartDivHeight;
    chartDiv.style.width = chartDivWidth;

    chartDoc.appendChild(chartDiv);

    const root = am5.Root.new(chartDiv);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        innerRadius: am5.percent(20),
        startAngle: -90,
        endAngle: 180,
      }),
    );

    // Data
    const data = [
      {
        category: 'Skills',
        value: myCompatibility.skills,
        full: 100,
        columnSettings: {
          fill: chart.get('colors')?.getIndex(0),
        },
      },
      {
        category: 'Knowledge',
        value: myCompatibility.knowledge,
        full: 100,
        columnSettings: {
          fill: chart.get('colors')?.getIndex(1),
        },
      },
      {
        category: 'Compatibility',
        value: myCompatibility.general,
        full: 100,
        columnSettings: {
          fill: chart.get('colors')?.getIndex(3),
        },
      },
    ];

    const cursor = chart.set(
      'cursor',
      am5radar.RadarCursor.new(root, {
        behavior: 'zoomX',
      }),
    );

    cursor.lineY.set('visible', false);

    const xRenderer = am5radar.AxisRendererCircular.new(root, {
      //minGridDistance: 50
    });

    xRenderer.labels.template.setAll({
      radius: 10,
    });

    xRenderer.grid.template.setAll({
      forceHidden: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'",
      }),
    );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 20,
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: '500',
      fontSize: 12,
      templateField: 'columnSettings',
    });

    yRenderer.grid.template.setAll({
      forceHidden: true,
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: yRenderer,
      }),
    );

    yAxis.data.setAll(data);

    const series1 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: 'full',
        categoryYField: 'category',
        fill: root.interfaceColors.get('alternativeBackground'),
      }),
    );

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20,
    });

    series1.data.setAll(data);

    const series2 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: 'value',
        categoryYField: 'category',
      }),
    );

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: '{category}: {valueX}%',
      cornerRadius: 20,
      templateField: 'columnSettings',
      fillOpacity: 1,
      fillGradient: am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(0x489cf0),
          },
          {
            color: am5.color(0xf53df5),
          },
        ],
      }),
    });

    series2.data.setAll(data);

    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);
  }, [chartRef]);

  return <div className="left-[50%]" ref={chartRef}></div>;
};
