import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import { Company } from '@src/apollo/types/graphql';

interface CompanyLogoProps {
  company: Pick<Company, 'logo'>;
  imgWidth?: string;
  imgHeight?: string;
  classNames?: string;
}

const CompanyLogoImage = ({
  company,
  imgWidth,
  imgHeight,
  classNames,
}: CompanyLogoProps) => {
  if (!company.logo) {
    return (
      <QuestionMarkCircleIcon
        className={`stroke-blue-light-01 ${classNames} `}
        style={{
          maxWidth: '100px',
          minWidth: '60px',
          width: imgWidth,
          height: imgHeight,
        }}
      />
    );
  }

  return (
    <img
      style={{
        maxWidth: '100px',
        minWidth: '60px',
        width: imgWidth,
        height: imgHeight,
      }}
      alt="Company logo"
      src={company?.logo}
      className={`rounded-xl  ${classNames}`}
    />
  );
};
export default CompanyLogoImage;
