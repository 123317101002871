import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { Portals } from '@src/apollo/types/graphql';

import adzunaLogo from '../../assets/logos/adzuna.svg';
import cvlibraryLogo from '../../assets/logos/cv-library.svg';
import cwjobsLogo from '../../assets/logos/cw-jobs.svg';
import devitLogo from '../../assets/logos/devit.svg';
import glassdoorLogo from '../../assets/logos/glassdoor.svg';
import indeedLogo from '../../assets/logos/indeed.svg';
import linkedinLogo from '../../assets/logos/linkedin.svg';
import milkroundLogo from '../../assets/logos/milkround.svg';
import reedLogo from '../../assets/logos/reed.png';
import totaljobsLogo from '../../assets/logos/totalJobs.svg';

type JobPortalIconProps = {
  portal: Pick<Portals, 'portal' | 'logo' | 'url'>;
};

const portalToIcon: Record<string, string> = {
  Indeed: indeedLogo,
  LinkedIn: linkedinLogo,
  Glassdoor: glassdoorLogo,
  TotalJobs: totaljobsLogo,
  Reed: reedLogo,
  'CV-Library': cvlibraryLogo,
  MilkRound: milkroundLogo,
  CwJobs: cwjobsLogo,
  DevIT: devitLogo,
  Adzuna: adzunaLogo,
};

const JobPortalIcon: React.FC<JobPortalIconProps> = ({
  portal,
}: JobPortalIconProps) => {
  const src = portalToIcon[portal.portal];

  return (
    <>
      <a
        href={portal.url}
        target="_blank"
        rel="noreferrer"
        className="flex justify-center items-center h-full w-full px-2"
      >
        {src ? (
          <img
            src={src}
            alt="Job portal logo"
            className="max-h-[25px] drop-shadow-md"
          />
        ) : (
          <QuestionMarkCircleIcon
            className="stroke-cyan-200"
            width={50}
            height={50}
          />
        )}
      </a>
    </>
  );
};

export default JobPortalIcon;
