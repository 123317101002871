import React, { useMemo } from 'react';

import { SortOrder, useJobsGeoDataQuery } from '@src/apollo/types/graphql';
import { JobsMap } from '@src/components/jobs/JobsMap';

import WidgetFrame from './misc/WidgetFrame';

const MapWidget: React.FC = () => {
  // Queries
  const jobsGeoDataQuery = useJobsGeoDataQuery({
    variables: {
      skip: 0,
      take: 50,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
    },
  });

  // Memos
  const queriesLoading = useMemo(() => {
    return jobsGeoDataQuery.loading;
  }, [jobsGeoDataQuery]);

  const queriesError = useMemo(() => {
    return jobsGeoDataQuery.error;
  }, [jobsGeoDataQuery]);

  const queriesReady = useMemo(() => {
    return !queriesLoading && !queriesError;
  }, [queriesLoading, queriesError]);

  const jobsList = useMemo(() => {
    return (
      jobsGeoDataQuery.data?.me.myHistory.nodes.map((job) => job.job) ?? []
    );
  }, [jobsGeoDataQuery]);

  // Template
  return (
    <WidgetFrame
      queriesReady={queriesReady}
      queriesLoading={queriesLoading}
      queriesError={queriesError}
    >
      <div className="xl:h-full h-60">
        <JobsMap jobs={jobsList} />
      </div>
    </WidgetFrame>
  );
};

export default MapWidget;
