import {
  BellIcon,
  InboxIcon,
  ArchiveBoxIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { JpWaitingSpinner } from '@src/components/misc/JpWaitingSpinner';
import { NotificationList } from '@src/components/notifications/NotificationList';
import { MyUserContext } from '@src/context/MyUserContext';

export enum NotificationTab {
  Inbox,
  Archive,
}

export const HeadbarNotifications: React.FC = () => {
  const { myUser, refetch, loading } = useContext(MyUserContext);

  // States
  const [currentTab, setCurrentTab] = useState(NotificationTab.Inbox);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const notifications = useMemo(() => {
    if (!myUser || !myUser.notifications) {
      return [];
    }

    return myUser.notifications;
  }, [myUser]);

  // Memos
  const unarchivedNotifications = useMemo(() => {
    return notifications.filter((notification) => !notification.archived);
  }, [notifications]);

  const archivedNotifications = useMemo(() => {
    return notifications.filter((notification) => notification.archived);
  }, [notifications]);

  // Methods
  const handleNotificationArchive = async () => {
    await refetch();
  };

  if (loading) {
    return (
      <button
        disabled
        className="btn btn-ghost btn-sm relative flex justify-center items-center w-8 h-8 ring-1 ring-white/20 rounded-md"
      >
        <JpWaitingSpinner displayLoadingText={false} />
      </button>
    );
  }

  return (
    <>
      <div
        className="tooltip tooltip-info"
        data-tip={`${t('notifications.youHaveLabel')} ${t(
          'notifications.notificationCount',
          { count: notifications.length },
        )}`}
      >
        <button
          className="btn btn-ghost btn-sm relative flex justify-center items-center w-8 h-8 ring-1 ring-white/20 rounded-md"
          onClick={() => setIsModalOpen(true)}
        >
          <BellIcon className="h-7" />

          {!!unarchivedNotifications && unarchivedNotifications.length > 0 && (
            <span className="absolute -right-2 -top-2 h-2 w-2 rounded-full m-1 bg-green-400" />
          )}
        </button>
      </div>
      {isModalOpen && (
        <div
          id="notificationsModal"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="card max-w-lg bg-white text-info-content border-2 border-info-content/50 h-1/2 right-16 top-4 absolute p-3 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <nav
              className="flex space-x-4 justify-center p-2"
              aria-label="Tabs"
            >
              <a
                className={`rounded-md px-3 py-2 text-sm font-lexend flex gap-2 items-center cursor-pointer ${
                  currentTab === NotificationTab.Inbox
                    ? 'rounded-md border border-blue-light-02 text-blue-dark'
                    : 'text-gray-500 hover:text-gray-700'
                } `}
                onClick={() => setCurrentTab(NotificationTab.Inbox)}
              >
                <InboxIcon className="h-5" />
                <span>
                  {t('notifications.inboxLabel')}
                  <span className="ml-2">
                    {'('}
                    {t('notifications.notificationCount', {
                      count: unarchivedNotifications?.length || 0,
                    })}
                    {')'}
                  </span>
                </span>
              </a>

              <a
                className={`rounded-md px-3 py-2 text-sm font-lexend flex gap-2 items-center cursor-pointer ${
                  currentTab === NotificationTab.Archive
                    ? ' rounded-md border border-blue-light-02 text-blue-dark'
                    : 'text-gray-500 hover:text-gray-700'
                } `}
                onClick={() => setCurrentTab(NotificationTab.Archive)}
              >
                <ArchiveBoxIcon className="h-5" />
                <span>
                  {t('notifications.archiveLabel')}
                  <span className="ml-2">
                    {'('}
                    {t('notifications.notificationCount', {
                      count: archivedNotifications?.length || 0,
                    })}
                    {')'}
                  </span>
                </span>
              </a>
            </nav>

            <div
              id="scrollableDiv"
              className="card-body m-0 p-2 overflow-y-auto"
            >
              {currentTab === NotificationTab.Inbox && (
                <NotificationList
                  notificationList={unarchivedNotifications || []}
                  onNotificationArchive={handleNotificationArchive}
                />
              )}

              {currentTab === NotificationTab.Archive && (
                <NotificationList
                  notificationList={archivedNotifications || []}
                  onNotificationArchive={handleNotificationArchive}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
