import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { Fragment } from 'react';

import { Job } from '@src/apollo/types/graphql';

interface JobOptionsMenuProps {
  isManuallyCreated: boolean;
  jobCreatedByMe: boolean;
  openEditModal: () => void;
  openDeleteModal: () => void;
  data: {
    job: Pick<Job, 'id' | 'externalUrl'>;
  };
}

export const JobOptionsMenu: React.FC<JobOptionsMenuProps> = ({
  isManuallyCreated,
  jobCreatedByMe,
  openEditModal,
  openDeleteModal,
  data,
}: JobOptionsMenuProps) => {
  return (
    <button>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="mt-auto text-primary w-5 h-5"
            title={'Options'}
          >
            <EllipsisHorizontalCircleIcon />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {isManuallyCreated && jobCreatedByMe && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        openEditModal();
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full',
                      )}
                    >
                      Edit
                    </button>
                  )}
                </Menu.Item>
              )}
              {jobCreatedByMe && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        openDeleteModal();
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      Sentry.captureMessage(`Job reported - ${data.job.id}`, {
                        level: 'warning',
                        extra: {
                          job: data,
                          url: data.job.externalUrl,
                        },
                      });
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Report
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </button>
  );
};
