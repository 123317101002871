import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import {
  useGetChatResponseMutation,
  useMeQuery,
} from '@src/apollo/types/graphql';
import { useChatScroll } from '@src/hooks/useChatScroll';

import { JpChatForm } from './JpChatForm';

interface Message {
  text: string;
  sender: 'user' | 'bot';
}

const JpChat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([
    {
      text: "Welcome! I'm here to assist you with any questions or tasks you have. How can I help you today?",
      sender: 'bot',
    },
  ]);

  const meQuery = useMeQuery();

  const [getChatResponse, chatResponseResult] = useGetChatResponseMutation();

  useEffect(() => {
    setMessages((prevMessages) => {
      if (
        chatResponseResult.data !== null &&
        chatResponseResult.data !== undefined
      ) {
        return [
          ...prevMessages,
          {
            text: chatResponseResult.data.getChatResponse.outputMessage,
            sender: 'bot',
          },
        ];
      }

      return prevMessages;
    });
  }, [chatResponseResult.data]);

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    message: string,
  ) => {
    event.preventDefault();

    // Add the user message to the chat log
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, sender: 'user' },
    ]);

    getChatResponse({
      variables: {
        inputMessage: message,
      },
    });
  };

  const chatRef = useChatScroll(messages);

  return (
    <div className="chat-container flex flex-col h-full">
      <div
        id="scrollableDiv"
        ref={chatRef}
        className="chat-log flex-1 overflow-auto p-4"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat ${
              message.sender === 'bot' ? 'chat-start' : 'chat-end'
            }`}
          >
            <div className="chat-image avatar">
              <div className="w-10 rounded-full">
                {message.sender === 'bot' && (
                  <FontAwesomeIcon
                    className="w-full h-full text-primary-content/75"
                    icon={faRobot}
                  />
                )}

                {message.sender !== 'bot' && (
                  <img src={meQuery.data?.me.profile.photos[0]} alt="" />
                )}
              </div>
            </div>

            <div
              className={`chat-bubble ${
                message.sender === 'bot'
                  ? 'chat-bubble-primary'
                  : 'chat-bubble-secondary'
              }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>

      <JpChatForm
        onSubmit={handleSubmit}
        chatResponseLoading={chatResponseResult.loading}
      />
    </div>
  );
};

export default JpChat;
