import { PlusCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useEffect, useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { MyUserContext } from '@src/context/MyUserContext';

import { JpWaitingSpinner } from './JpWaitingSpinner';

interface ComponentProps {
  handleUpdateCompleted?: () => void;
  disabled?: boolean;
}

const JpAvatarDropzone: React.FC<ComponentProps> = ({ disabled }) => {
  // States
  const [rejections, setRejections] = useState<FileRejection[]>([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const userContext = useContext(MyUserContext);

  // Hooks
  const { t } = useTranslation();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled,
    maxFiles: 1,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      'image/gif': ['.gif'],
    },
    onDropRejected: (fileRejections) => {
      setRejections(fileRejections);
    },
  });

  // Methods
  const uploadProfilePhoto = async () => {
    setUploadInProgress(true);

    const file = acceptedFiles[0];

    const dataTransfer = new DataTransfer();

    const fileList = dataTransfer.files;

    dataTransfer.items.add(file);

    userContext.uploadAvatar(fileList);

    setUploadInProgress(false);
  };

  // Effects
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      uploadProfilePhoto();
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (rejections.length === 1) {
      toast('Only PNG, JPG and GIF files are allowed for profile photos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      const timer = setTimeout(() => {
        setRejections([]);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [rejections]);

  return (
    <section className="container flex justify-center text-center flex-col w-full h-full gap-2">
      <div
        {...getRootProps()}
        className={clsx(
          'dropzone flex flex-1 gap-3 flex-col h-40 p-6 justify-evenly font-lexend items-center text-center text-grey-dark bg-white',
          'border-2 border-dashed border-grey-light-01 rounded-xl',
        )}
      >
        <input {...getInputProps()} />

        {uploadInProgress === true && <JpWaitingSpinner />}

        {uploadInProgress === false && (
          <div
            className={clsx(
              'flex justify-center items-center',
              'gap-6 lg:gap-1',
              'flex-col 2xl:flex-row',
            )}
          >
            <img
              src={userContext.profilePicture}
              alt="profile-avatar"
              className="object-center h-24 w-24 rounded-full border-2 border-grey-light-01"
            />

            <div className="flex flex-col flex-1 justify-center items-center gap-2">
              <PlusCircleIcon
                className="h-6 w-6 text-grey-light-00 block sm:block lg:hidden xl:block "
                aria-hidden="true"
              />

              <span className="text-xs text-grey-dark leading-tight">
                <button
                  type="button"
                  className="btn btn-primary btn-xs font-lexend text-blue-dark"
                >
                  {t('uploadDropzone.uploadFile')}
                </button>

                {t('uploadDropzone.avatarDropText')}
              </span>
            </div>
          </div>
        )}
      </div>

      {rejections.length > 1 ? (
        <div className="flex justify-center text-xxs font-bold text-grey-light-02 mx-2 bg-red-600 animate-fade-in">
          <span>{t('uploadDropzone.errorMessage')}</span>
        </div>
      ) : (
        <div className="flex text-grey-dark font-lexend justify-between text-xxs mx-2">
          <span>{t('uploadDropzone.avatarSupportedFormat')}</span>

          <span>{t('uploadDropzone.maximumSize')}</span>
        </div>
      )}
    </section>
  );
};

export default JpAvatarDropzone;
