import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as amplitude from '@amplitude/analytics-browser';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { use as useI18n } from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { client } from '@src/apollo/apollo-client';
import { router } from '@src/router/router';

import { enMessages } from './i18n/en';
import { roMessages } from './i18n/ro';
import { LanguageName } from './i18n/types';
import { store } from './store/store';

import 'react-toastify/dist/ReactToastify.css';
import './styles/globals.css';

am4core.useTheme(am4themes_animated);

useI18n(initReactI18next).init({
  resources: {
    [LanguageName.en]: {
      translation: enMessages,
    },
    [LanguageName.ro]: {
      translation: roMessages,
    },
  },
  fallbackLng: LanguageName.en,
  interpolation: {
    escapeValue: false,
  },
});

amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, { serverZone: 'EU' });

Sentry.init({
  dsn: 'https://af3f2d29068bc0f91aec364c1b72ab36@o4506490674872320.ingest.sentry.io/4506490745126912',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: [
      //   'localhost',
      //   'https://dev.api.jobpedia.ai',
      //   'https://staging.api.jobpedia.ai',
      //   'https://api.jobpedia.ai',
      // ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
    }),
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        'https://api.jobpedia.ai/api',
        'https://staging.api.jobpedia.ai/api',
        'https://dev.api.jobpedia.ai/api',
      ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  profilesSampleRate: 1.0, // Capture 100% of the profiles
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ToastContainer newestOnTop={true} pauseOnFocusLoss />
        <RouterProvider router={router} />
      </Provider>
    </ApolloProvider>
);
