import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';

import { NavItem } from '@src/hooks/useSidebarNavigationItems';

import { HeadbarNotifications } from '../notifications/HeadbarNotifications';
interface JpTitleBarProps {
  activeNavItem?: NavItem;
  showSearchField?: boolean;
  handleOnChange?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const JpTitleBar: React.FC<JpTitleBarProps> = ({
  activeNavItem,
  handleOnChange,
  showSearchField = false,
}: JpTitleBarProps) => {
  // Methods
  const debouncedHandleOnChange = handleOnChange
    ? debounce(handleOnChange, 500)
    : undefined;

  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 sm:justify-between sm:items-center sm:h-16 p-3 bg-white bg-opacity-5">
      {activeNavItem && (
        <section className="flex flex-col ml-1 gap-1">
          <h1 className="text-2xl font-lexend text-grey-dark">
            {activeNavItem.label}
          </h1>
          {activeNavItem.subTitle && (
            <span className="text-sm font-lexend text-grey-light-00">
              {activeNavItem.subTitle}
            </span>
          )}
        </section>
      )}

      <section className="flex  justify-end items-center gap-3 p-0 m-0">
        {showSearchField && (
          <div className="relative mt-2 flex items-center w-full">
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Title, tags or position"
              onKeyDown={debouncedHandleOnChange}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        )}
        <HeadbarNotifications />
        {/* <HeadbarFeedback /> */}
      </section>
    </div>
  );
};
