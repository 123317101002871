import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AddToFirefox from '@src/assets/add-to-firefox.svg';
import WebStoreIcon from '@src/assets/web-store-icon.svg';

type WebStoreLinkProps = {
  className?: string;
  isChrome: boolean;
  isFirefox: boolean;
};

const WebStoreLink: React.FC<WebStoreLinkProps> = ({
  className,
  isChrome,
  isFirefox,
}: WebStoreLinkProps) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {isChrome && (
        <a
          href="https://chrome.google.com/webstore/detail/jobpediaai/oknkajdjbifimeegcmaamjcfnmhkmphh"
          aria-label="Download Google Chrome Extension"
          className={clsx(className)}
        >
          <div className="flex flex-row items-center gap-3 p-3 border border-grey-light-02 rounded-lg">
            <img src={WebStoreIcon} alt="Chrome Web Store" width={50} />

            <div className="text-grey-dark/70 font-lexend leading-none">
              <span className="text-xs">{t('webStore.availableIn')}</span>

              <p>{t('webStore.storeName')}</p>
            </div>
          </div>
        </a>
      )}
      {isFirefox && (
        <a
          className="flex flex-row items-center gap-3 bg-blue-500 text-white p-3 rounded-md text-lg"
          href="#"
        >
          <img src={AddToFirefox} alt="Firefox Logo" className="w-10 h-10" />
          Get add-on
        </a>
      )}
    </React.Fragment>
  );
};

export default WebStoreLink;
