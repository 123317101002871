import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useState } from 'react';

import {
  UnlockIconOutline,
  UnlockIconSolid,
} from '@src/images/GradientSvgIcons';

interface UnlockComaptibilityButtonProps {
  handleLoadCompatibility: (id: string) => void;
  jobId: string;
}

export const UnlockComaptibilityButton = ({
  handleLoadCompatibility,
  jobId,
}: UnlockComaptibilityButtonProps) => {
  const [isUnlockHovered, setIsUnlockHovered] = useState<boolean>(false);
  const primaryGradientClass =
    'bg-gradient-to-tr from-purple-dark to-blue-dark';

  const transitionClass = 'transition-all duration-300';

  return (
    <button
      className="w-full"
      onMouseEnter={() => setIsUnlockHovered(true)}
      onMouseLeave={() => setIsUnlockHovered(false)}
      onClick={() => {
        handleLoadCompatibility(jobId);
      }}
    >
      <div
        className={clsx(
          'flex flex-row justify-around items-center',
          'px-4 py-2',
          'ai-border-gradient rounded-full',
          transitionClass,
        )}
      >
        {isUnlockHovered ? (
          <UnlockIconSolid id={nanoid()} className="h-3 w-3" />
        ) : (
          <UnlockIconOutline id={nanoid()} className="h-3 w-3" />
        )}

        <div className="px-2">
          <h1
            className={clsx(
              'font-lexend text-xxs font-bold leading-tight bg-clip-text text-transparent',
              primaryGradientClass,
            )}
          >
            Unlock
          </h1>
        </div>
      </div>
    </button>
  );
};
