import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  JobWhereInput,
  NullsOrder,
  QueryMode,
  SortOrder,
  useExploreMultipleJobsQuery,
} from '@src/apollo/types/graphql';
import { EndMessage } from '@src/components/core/EndMessage';
import { FiltersPanel } from '@src/components/filters/FiltersPanel';
import JobCard from '@src/components/jobs/JobCard';
import { JobListItem } from '@src/components/jobs/JobListItem';
import { JobsMap } from '@src/components/jobs/JobsMap';
import { JpExplorerLayout } from '@src/components/layouts/JpExplorerLayout';
import { JpTitleBar } from '@src/components/misc/JpTitleBar';
import { JpWaitingSpinner } from '@src/components/misc/JpWaitingSpinner';
import { ListContext } from '@src/context/JobListContext';
import { JobViewProvider } from '@src/context/JobViewContext';
import { MyUserContext } from '@src/context/MyUserContext';
import { useSidebarNavigationItems } from '@src/hooks/useSidebarNavigationItems';
import { SortOption } from '@src/types/sorting';

export const Explore: React.FC = () => {
  const userContext = useContext(MyUserContext);

  // Constants
  const pageSize = 50;
  const sortOptionList: SortOption[] = ['createdAt', 'title', 'hourlyRate'];

  // Hooks
  const { activeNavItem } = useSidebarNavigationItems();

  const { t } = useTranslation();

  // States
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Desc);
  const [sortBy, setSortBy] = useState<SortOption>('createdAt');
  const [viewMode, setViewMode] = useState<'grid' | 'map'>('grid');
  const [filters, setFilters] = useState<JobWhereInput>({});
  const [filtersToggled, setFiltersToggled] = useState(false);

  const orderBy = useMemo(() => {
    switch (sortBy) {
      case 'createdAt':
        return {
          createdAt: sortOrder,
        };
      case 'title':
        return {
          title: sortOrder,
        };
      case 'hourlyRate':
        return {
          hourlyRate: { sort: sortOrder, nulls: NullsOrder.Last },
        };
      default:
        return {
          createdAt: sortOrder,
        };
    }
  }, [sortBy, sortOrder]);

  // Queries
  const exploreMultipleJobsQuery = useExploreMultipleJobsQuery({
    variables: {
      where: filters,
      take: pageSize,
      skip: 0,
      orderBy,
    },
  });

  useEffect(() => {
    exploreMultipleJobsQuery.refetch();
  }, [
    userContext.myUser?.resume,
    userContext.myUser?.profile.rate,
    userContext.myUser?.profile.currency,
  ]);

  // Methods
  const handleNextPage = () => {
    exploreMultipleJobsQuery.fetchMore({
      variables: {
        skip: exploreMultipleJobsQuery.data?.jobs.nodes.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          jobs: {
            ...fetchMoreResult.jobs,
            nodes: [...prev.jobs.nodes, ...fetchMoreResult.jobs.nodes],
          },
        };
      },
    });
  };

  const handleOnChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    if (value === filters?.title?.contains) {
      return;
    }

    setFilters({
      ...filters,
      title: {
        contains: value,
        mode: QueryMode.Insensitive,
      },
    });
  };

  const handleSortButtonClick = (sortBy: SortOption) => {
    setSortOrder((prev) => {
      if (prev === SortOrder.Asc) {
        return SortOrder.Desc;
      } else {
        return SortOrder.Asc;
      }
    });

    setSortBy(sortBy);
  };

  const getSortOptionLabel = (sortOption: SortOption) => {
    switch (sortOption) {
      case 'createdAt':
        return t('views.explore.sortOptions.createdAt');
      case 'title':
        return t('views.explore.sortOptions.title');
      case 'hourlyRate':
        return t('views.explore.sortOptions.salary');
      default:
        return t('views.explore.sortOptions.unknown');
    }
  };

  const hasMore = useMemo(() => {
    const count = exploreMultipleJobsQuery.data?.jobs.count;
    const length = exploreMultipleJobsQuery.data?.jobs.nodes.length;

    return count && length ? length < count : false;
  }, [
    exploreMultipleJobsQuery.data?.jobs.count,
    exploreMultipleJobsQuery.data?.jobs.nodes.length,
  ]);

  return (
    <>
      <div className="flex h-full max-w-full flex-col gap-3 container">
        <JpTitleBar
          activeNavItem={activeNavItem}
          showSearchField={true}
          handleOnChange={handleOnChange}
        />

        <JobViewProvider>
          <ListContext.Provider
            value={{
              resultsCount: exploreMultipleJobsQuery.data?.jobs.count || 0,
              sortOptionList,
              sortBy,
              sortOrder,
              handleSortButtonClick,
              getSortOptionLabel,
              currentFilters: filters,
              applyFilters: (filters: JobWhereInput) => {
                setFilters(filters);
                exploreMultipleJobsQuery.refetch({ where: { ...filters } });
              },
              viewMode,
              changeViewMode: (viewMode: 'grid' | 'map') => {
                setViewMode(viewMode);
              },
              toggleFilters: () => {
                setFiltersToggled(true);
              },
            }}
          >
            <JpExplorerLayout>
              {viewMode === 'map' && (
                <div className="col-span-12 xl:col-span-7 lg:col-span-6 lg:h-full h-60 order-0 lg:order-last">
                  <JobsMap
                    jobs={exploreMultipleJobsQuery.data?.jobs.nodes ?? []}
                  />
                </div>
              )}
              <div
                id="scrollableDiv"
                className={classNames(
                  viewMode === 'grid'
                    ? 'col-span-12'
                    : 'lg:col-span-6 xl:col-span-5 col-span-12',
                  'h-full overflow-y-auto',
                )}
              >
                <InfiniteScroll
                  dataLength={
                    exploreMultipleJobsQuery.data?.jobs.nodes.length ?? 0
                  }
                  next={handleNextPage}
                  hasMore={hasMore}
                  loader={
                    <>
                      Loading...
                      <button onClick={handleNextPage}></button>
                    </>
                  }
                  endMessage={
                    <div
                      className={classNames(
                        viewMode === 'grid' ? 'col-span-12' : 'col-span-6',
                        'h-full flex justify-center items-center gap-2 w-full py-5',
                      )}
                    >
                      {exploreMultipleJobsQuery.loading ? (
                        <JpWaitingSpinner />
                      ) : (
                        <EndMessage />
                      )}
                    </div>
                  }
                  scrollableTarget="scrollableDiv"
                  className={classNames(
                    viewMode === 'grid'
                      ? 'grid grid-cols-12 gap-3 md:pr-2'
                      : 'grid grid-flow-row auto-rows-max mt-2 gap-5',
                  )}
                >
                  {viewMode === 'grid'
                    ? exploreMultipleJobsQuery.data?.jobs.nodes.map(
                        (job, key) => (
                          <div
                            key={`${key}_${nanoid()}`}
                            className="md:col-span-6 lg:col-span-4 col-span-12"
                          >
                            <JobCard key={nanoid()} job={job} />
                          </div>
                        ),
                      )
                    : exploreMultipleJobsQuery.data?.jobs.nodes.map((job) => (
                        <JobListItem key={nanoid()} job={job} />
                      ))}
                </InfiniteScroll>
              </div>
              <FiltersPanel
                filtersToggled={filtersToggled}
                toggleFilters={(val) => setFiltersToggled(val)}
                filterOptions={
                  exploreMultipleJobsQuery.data?.jobs.filterOptions
                }
              />
            </JpExplorerLayout>
          </ListContext.Provider>
        </JobViewProvider>
      </div>
    </>
  );
};
