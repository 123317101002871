import clsx from 'clsx';
import React from 'react';

import { useFindManyPortalsQuery } from '@src/apollo/types/graphql';
import { JpWaitingSpinner } from '@src/components/misc/JpWaitingSpinner';
import JobPortalIcon from '@src/components/portals/JobPortalcon';

type PortalListProps = {
  columns: number;
};

const PortalList: React.FC<PortalListProps> = ({ columns }) => {
  const { data, loading } = useFindManyPortalsQuery();

  return (
    <>
      {loading && <JpWaitingSpinner />}

      {!loading && data && data.findManyPortals && (
        <div
          className={clsx(
            'w-full h-fit gap-3',
            'grid grid-flow-row',
            `grid-cols-2 lg:grid-cols-${columns}`,
          )}
        >
          {data.findManyPortals.map((portal) => {
            return (
              <div
                key={crypto.randomUUID()}
                className={clsx(
                  'flex justify-center items-center shadow-xs shadow-grey-light-01',
                  'bg-blue-light-03 hover:bg-blue-light-02',
                  'rounded-lg overflow-hidden',
                  'w-full h-full sm:h-14',
                )}
              >
                <JobPortalIcon portal={portal} />
              </div>
            );
          })}
        </div>
      )}

      {!loading && !data && (
        <div className="w-full h-full flex justify-center items-center text-sm text-grey-light-00">
          We're sorry, we couldn't find any portals at this time.
        </div>
      )}
    </>
  );
};

export default PortalList;
