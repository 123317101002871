import clsx from 'clsx';

interface IconProps {
  progress: number;
  id: string;
  className?: string;
}

export function GradientSvgBar(props: IconProps) {
  return (
    <svg className={clsx('app-slider__progress', props.className)}>
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor="#489CF0" />
          <stop offset="50%" stopColor="#9E6DF2" />
          <stop offset="100%" stopColor="#F53DF5" />
        </linearGradient>

        <clipPath id={props.id}>
          <rect
            id={`${props.id}Rect`}
            width={`${props.progress}%`}
            height="14px"
            rx="7px"
            style={{ transition: 'width 0.2s ease-in-out' }}
          />
        </clipPath>
      </defs>

      <rect
        className={clsx('app-slider__progress__bg', '', props.className)}
        height="14px"
        width="100%"
        rx="8px"
        fill="hsl(210, 85%, 90%)"
      />

      <rect
        height="14px"
        width="100%"
        className={clsx('app-slider__progress__bar', '', props.className)}
        clipPath={`url(#${props.id})`}
        fill="url(#gradient)"
      />
    </svg>
  );
}
