import { useTranslation } from 'react-i18next';

import PortalList from '@src/components/dashboard/widgets/misc/PortalList';

const ExploreStep: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h1 className="font-lexend font-semibold text-lg text-blue-dark">
        {t('onboarding.steps.exploreStep.content.title')}
      </h1>

      <p className="font-inter text-grey-dark text-sm leading-tight mb-4">
        {t('onboarding.steps.exploreStep.content.text')}
      </p>

      <PortalList columns={4} />
    </div>
  );
};

export default ExploreStep;
