import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Fragment, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';

export const ResumeSelector = () => {
  const userContext = useContext(MyUserContext);
  const subscriptionContext = useContext(SubscriptionsContext);

  const navigate = useNavigate();
  const [selected, setSelected] = useState(userContext.myUser?.resume);

  const onChangeHandler = useCallback(
    (value: typeof selected) => {
      if (!value) {
        return;
      }

      setSelected(value);
      userContext.updateResume(value);
    },
    [selected],
  );

  return userContext.myUser?.myResumes.length === 0 ? (
    <div className="flex flex-col my-3">
      <button
        className=""
        onClick={() => navigate('/dashboard/profile?tab=resume')}
      >
        <span
          className={classNames(
            'underline text-indigo-600',
            'truncate text-xs',
          )}
        >
          Upload a resume
        </span>
      </button>
    </div>
  ) : (
    <Listbox value={selected} onChange={onChangeHandler}>
      {({ open }) => (
        <>
          <div className="relative w-auto">
            <Listbox.Button className="max-w-[180px] relative w-auto cursor-default rounded-md bg-white py-1.5 p-2 pr-10 text-left text-gray-900 ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-xs sm:leading-6">
              <span className="inline-flex w-full truncate truncate text-gray-500 text-xs">
                {userContext.myUser?.resume?.file.name}
              </span>

              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {userContext.myUser?.myResumes.map((resume) => (
                  <Listbox.Option
                    key={resume.id}
                    className={() =>
                      classNames(
                        resume.id === userContext.myUser?.resume?.id
                          ? 'bg-indigo-600 text-white'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={resume}
                  >
                    {({ selected }) => (
                      <>
                        <div className="flex flex-col">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'truncate',
                            )}
                          >
                            {resume.file.name}
                          </span>
                          <span
                            className={classNames(
                              resume.id === userContext.myUser?.resume?.id
                                ? 'text-indigo-200'
                                : 'text-gray-500',
                              'truncate text-xs',
                            )}
                          >
                            {resume.createdAt}
                          </span>
                        </div>

                        {resume.id === userContext.myUser?.resume?.id ? (
                          <span
                            className={classNames(
                              'text-white',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                <Listbox.Option
                  className={() =>
                    classNames(
                      'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                    )
                  }
                  onClick={() => navigate('/dashboard/profile?tab=resume')}
                  value={null}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex flex-col">
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'truncate',
                          )}
                        >
                          New resume
                          {!subscriptionContext.isPro && (
                            <span
                              className={classNames(
                                'ml-3 inline-flex items-center rounded-full  px-1.5 py-0.5 text-xxs ring-1 ring-inset ring-yellow-600/20',
                                'text-white bg-gradient-to-tr from-purple-light to-blue-dark',
                                'font-thin font-lexend',
                              )}
                            >
                              PRO
                            </span>
                          )}
                        </span>
                        <span
                          className={classNames(
                            'text-gray-500',
                            'truncate text-xs',
                          )}
                        >
                          Upload a new resume
                        </span>
                      </div>

                      <span
                        className={classNames(
                          'text-gray-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    </>
                  )}
                </Listbox.Option>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
