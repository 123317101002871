import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { JpTitleBar } from '@src/components/misc/JpTitleBar';
import { ProfileHeader } from '@src/components/profile/ProfileHeader';
import { ProfilePersonalInformation } from '@src/components/profile/ProfilePersonalInformation';
import { ProfileResume } from '@src/components/profile/ProfileResume';
import { SubscriptionPlanBody } from '@src/components/subscription-plan/SubscriptionPlanBody';

export enum ProfileTab {
  PersonalInformation,
  Subscriptions,
  Resume,
}

export const Profile = () => {
  // Hooks

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // States
  const [currentTab, setCurrentTab] = useState(ProfileTab.PersonalInformation);

  useEffect(() => {
    if (searchParams.get('tab') === 'resume') {
      setCurrentTab(ProfileTab.Resume);

      return;
    }
    if (searchParams.get('tab') === 'personal-information') {
      setCurrentTab(ProfileTab.PersonalInformation);

      return;
    }
    if (searchParams.get('tab') === 'subscriptions') {
      setCurrentTab(ProfileTab.Subscriptions);

      return;
    }
    setCurrentTab(ProfileTab.PersonalInformation);
  }, [searchParams]);

  return (
    <>
      <div className="flex h-full max-w-full flex-col gap-2 container">
        <JpTitleBar
          activeNavItem={{
            id: 'profile',
            label: t('views.profile.title'),
            href: '/dashboard/profile',
            icon: UserCircleIcon,
          }}
          showSearchField={false}
        />

        <div className="h-full overflow-y-scroll p-3">
          <ProfileHeader />

          <div className="mt-10 flex flex-col md:flex-row items-center md:items-start justify-center">
            <div className="tabs grid sm:flex gap-1 sm:gap-5 text-center cursor-pointer">
              <a
                className={`tab tab-lifted  font-lexend font-medium hover:bg-blue-light-02 p-5 rounded-5 hover:text-blue-dark ${
                  currentTab === ProfileTab.PersonalInformation
                    ? 'bg-blue-light-02 text-blue-dark'
                    : 'text-grey-light-00'
                }`}
                onClick={() => {
                  navigate('/dashboard/profile?tab=personal-information');
                }}
              >
                {t('views.profile.tabs.personalInformation')}
              </a>
              <a
                className={`tab tab-lifted  font-lexend font-medium hover:bg-blue-light-02 p-5 rounded-5 hover:text-blue-dark ${
                  currentTab === ProfileTab.Subscriptions
                    ? 'bg-blue-light-02 text-blue-dark'
                    : 'text-grey-light-00'
                }`}
                onClick={() => {
                  navigate('/dashboard/profile?tab=subscriptions');
                }}
              >
                {t('views.profile.tabs.subscriptions')}
              </a>

              <a
                className={`tab tab-lifted font-lexend font-medium hover:bg-blue-light-02 p-5 rounded-5 hover:text-blue-dark ${
                  currentTab === ProfileTab.Resume
                    ? 'bg-blue-light-02 text-blue-dark'
                    : 'text-grey-light-00'
                }`}
                onClick={() => {
                  navigate('/dashboard/profile?tab=resume');
                }}
              >
                {t('views.profile.tabs.resume')}
              </a>
            </div>
          </div>

          <div className="divider mt-0 pb-5 sm:hidden md:flex"></div>

          {currentTab === ProfileTab.PersonalInformation && (
            <ProfilePersonalInformation />
          )}
          {currentTab === ProfileTab.Subscriptions && <SubscriptionPlanBody />}

          {currentTab === ProfileTab.Resume && <ProfileResume />}
        </div>
      </div>
    </>
  );
};
