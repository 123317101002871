import { createSlice } from '@reduxjs/toolkit';

import { type AppStoreState } from '@src/store/types';

import { ThemeKind, type UIState } from './types';

const initialState: UIState = {
  currentTheme: ThemeKind.DARK,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme(state, action: { payload: ThemeKind }) {
      state.currentTheme = action.payload;
    },
  },
});

export const { setTheme } = uiSlice.actions;

export const currentTheme = (state: AppStoreState) => state.ui.currentTheme;

export default uiSlice.reducer;
