import JpChat from '@src/components/misc/JpChat';
import { JpTitleBar } from '@src/components/misc/JpTitleBar';
import { useSidebarNavigationItems } from '@src/hooks/useSidebarNavigationItems';

export const Chat: React.FC = () => {
  const { activeNavItem } = useSidebarNavigationItems();

  return (
    <>
      <div className="flex h-full max-w-full flex-col gap-2 container">
        <JpTitleBar activeNavItem={activeNavItem} />

        <div className="h-full m-3 p-3 rounded-xl bg-white bg-opacity-5 border border-white border-opacity-10 overflow-hidden">
          <JpChat />
        </div>
      </div>
    </>
  );
};
