import { ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import React, { useContext, useMemo } from 'react';

import { MyUserContext } from '@src/context/MyUserContext';

import WidgetFrame from './misc/WidgetFrame';

const CompatibilityWidget: React.FC = () => {
  const { myUser, loading } = useContext(MyUserContext);
  const statistics = useMemo(() => {
    if (!myUser || !myUser.statistics) {
      return null;
    }

    return myUser.statistics;
  }, [myUser]);

  const compatibilityPercentageChange = useMemo(() => {
    if (!statistics?.lastWeekStatistics.avgCompatibility) {
      return '0%';
    }

    const percentageChange =
      statistics?.lastWeekStatistics.avgCompatibilityChangePercentage;

    if (percentageChange > 0) {
      return `+${percentageChange}%`;
    }

    return `${percentageChange}%`;
  }, [statistics]);

  return (
    <WidgetFrame
      queriesReady={!!statistics}
      queriesLoading={loading}
      extraClasses="py-3"
    >
      <div className="h-full flex flex-wrap items-center justify-center">
        <div className="flex w-4/5 justify-start">
          <span className="text-xl inline-flex items-center gap-5 text-grey-dark font-lexend">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M12.2352 20.1903L11.0835 24.2222L9.93175 20.1903C9.63407 19.1489 9.07594 18.2005 8.31003 17.4346C7.54412 16.6686 6.59569 16.1105 5.55425 15.8128L1.521 14.6597L5.55283 13.5079C6.59428 13.2102 7.54271 12.6521 8.30861 11.8862C9.07452 11.1203 9.63265 10.1719 9.93033 9.13042L11.0835 5.09717L12.2352 9.129C12.5329 10.1704 13.0911 11.1189 13.857 11.8848C14.6229 12.6507 15.5713 13.2088 16.6127 13.5065L20.646 14.6597L16.6142 15.8114C15.5727 16.1091 14.6243 16.6672 13.8584 17.4331C13.0925 18.199 12.5343 19.1475 12.2367 20.1889L12.2352 20.1903ZM24.2004 10.0059L23.8335 11.4722L23.4666 10.0059C23.2566 9.16525 22.822 8.39746 22.2094 7.78463C21.5969 7.17179 20.8292 6.73695 19.9887 6.52658L18.521 6.15967L19.9887 5.79275C20.8292 5.58239 21.5969 5.14754 22.2094 4.53471C22.822 3.92187 23.2566 3.15408 23.4666 2.31342L23.8335 0.847168L24.2004 2.31342C24.4106 3.15426 24.8453 3.92217 25.4581 4.53502C26.071 5.14787 26.8389 5.58261 27.6797 5.79275L29.146 6.15967L27.6797 6.52658C26.8389 6.73672 26.071 7.17147 25.4581 7.78432C24.8453 8.39717 24.4106 9.16508 24.2004 10.0059ZM22.2667 26.7962L21.7085 28.4722L21.1503 26.7962C20.9938 26.3268 20.7302 25.9002 20.3803 25.5503C20.0304 25.2004 19.6039 24.9368 19.1344 24.7803L17.4585 24.2222L19.1344 23.664C19.6039 23.5075 20.0304 23.2439 20.3803 22.894C20.7302 22.5441 20.9938 22.1175 21.1503 21.6481L21.7085 19.9722L22.2667 21.6481C22.4231 22.1175 22.6868 22.5441 23.0367 22.894C23.3866 23.2439 23.8131 23.5075 24.2826 23.664L25.9585 24.2222L24.2826 24.7803C23.8131 24.9368 23.3866 25.2004 23.0367 25.5503C22.6868 25.9002 22.4231 26.3268 22.2667 26.7962Z"
                stroke="url(#paint0_linear_191_436)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_191_436"
                  x1="1.521"
                  y1="28.4722"
                  x2="34.8171"
                  y2="13.26"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#489CF0" />
                  <stop offset="0.5" stopColor="#9E6DF2" />
                  <stop offset="1" stopColor="#F53DF5" />
                </linearGradient>
              </defs>
            </svg>
            Compatibility
          </span>
        </div>
        <div className="flex flex-wrap w-4/5 justify-between">
          <div>
            <span className="text-xl font-semibold text-grey-dark font-lexend">
              {statistics?.lastWeekStatistics.avgCompatibility}%
            </span>
          </div>
          <div>
            <div>
              <span className="text-sm text-green-500 flex items-center gap-1 font-bold gap-1">
                <ArrowTrendingUpIcon className="h-5 stroke-2"></ArrowTrendingUpIcon>
                {compatibilityPercentageChange}
              </span>
            </div>
            <div>
              <span className="text-xs text-grey-light-01 font-semibold">
                vs last 7 days
              </span>
            </div>
          </div>
        </div>
      </div>
    </WidgetFrame>
  );
};

export default CompatibilityWidget;
