import { createSlice } from '@reduxjs/toolkit';

import { type AppStoreState } from '@src/store/types';

import { OnboardingState } from './types';

const initialState: OnboardingState = {
  profile: {
    firstName: '',
    lastName: '',
    profession: '',
    completed: false,
  },
  resume: {
    completed: false,
  },
  extension: {
    completed: false,
    installed: false,
  },
  explore: {
    completed: false,
  },
  visible: true,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setFirstName(state, action: { payload: string }) {
      state.profile.firstName = action.payload;
    },
    setLastName(state, action: { payload: string }) {
      state.profile.lastName = action.payload;
    },
    setProfession(state, action: { payload: string }) {
      state.profile.profession = action.payload;
    },
    setVisibility(state, action: { payload: boolean }) {
      state.visible = action.payload;
    },
  },
});

export const { setFirstName, setLastName, setProfession, setVisibility } =
  onboardingSlice.actions;

export const userProfile = (state: AppStoreState) => state.onboarding.profile;

export const modalVisible = (state: AppStoreState) => state.onboarding.visible;

export default onboardingSlice.reducer;
