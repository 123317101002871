import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  useMyResumeFeaturesQuery,
  useMyResumeInfoQuery,
  useRemoveMyResumeMutation,
  useUpdateMyResumeMutation,
} from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';

import { ResumeItem } from './ResumeItem';
import JpDropzone from '../misc/JpDropzone';

export type ProfileResumeProps = {
  onResumeUpdate?: () => void;
};

export const ProfileResume: React.FC<ProfileResumeProps> = (
  props: ProfileResumeProps,
) => {
  // Queries
  const myResumeInfoQuery = useMyResumeInfoQuery({
    fetchPolicy: 'network-only',
  });

  const myResumeFeaturesQuery = useMyResumeFeaturesQuery({
    fetchPolicy: 'network-only',
  });

  const [updateUserResume] = useUpdateMyResumeMutation();
  const [deleteUserResume] = useRemoveMyResumeMutation();

  const myUserContext = React.useContext(MyUserContext);
  const subscriptionContext = React.useContext(SubscriptionsContext);

  // Hooks
  const { t } = useTranslation();

  // Methods
  const handleUpdateCompleted = () => {
    myResumeInfoQuery.refetch();
    myResumeFeaturesQuery.refetch();

    if (props.onResumeUpdate) {
      props.onResumeUpdate();
    }
  };

  const handleUpdateResume = async (id: string) => {
    await updateUserResume({
      variables: {
        resumeId: id,
      },
      onCompleted: () => {
        myUserContext.refetch();
      },
    });
  };

  const handleDeleteResume = async (id: string) => {
    await deleteUserResume({
      variables: {
        resumeId: id,
      },
      onError: (err) => {
        if (
          err.message ==
          'You cannot remove your current resume before selecting another one'
        ) {
          toast(t('user.profile.cannotRemoveResume'), {
            type: 'error',
            toastId: 'remove-resume-error',
          });
        }
      },
      onCompleted: (res) => {
        if (!res.removeMyResume) {
          return;
        }
        myResumeInfoQuery.refetch();
        myResumeFeaturesQuery.refetch();
        myUserContext.refetch();
      },
    });
  };

  return (
    <>
      {!subscriptionContext.isPro &&
      myUserContext.myUser?.myResumes &&
      myUserContext.myUser.myResumes.length > 1 ? (
        <div className="flex justify-center relative">
          <button
            className={classNames(
              'z-10 absolute top-1 py-2 px-3 rounded-lg',
              'text-white text-xs font-medium bg-gradient-to-tr from-purple-light to-blue-dark',
              'flex flex-col items-center gap-1',
            )}
            onClick={() => subscriptionContext.upgrade()}
          >
            <span>Upgrade now</span>
            <span className="text-xxs">Upload unlimited resumes</span>
          </button>
          <div
            onClick={() => subscriptionContext.upgrade()}
            className="w-full blur-sm cursor-pointer flex flex-col lg:flex-row items-center md:items-start gap-5 justify-center"
          >
            <JpDropzone disabled={true} />
          </div>
        </div>
      ) : (
        <div className="mt-10 flex flex-col lg:flex-row items-center md:items-start gap-5 justify-center">
          <JpDropzone handleUpdateCompleted={handleUpdateCompleted} />
        </div>
      )}

      <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 md:items-start gap-5 justify-center lg:m-4 lg:p-6">
        {myUserContext.myUser?.myResumes.map((resume) => (
          <ResumeItem
            resume={resume}
            key={resume.id}
            isDefault={resume.id == myUserContext.myUser?.resume?.id}
            showButtons={true}
            handleUpdateResume={handleUpdateResume}
            handleDeleteResume={handleDeleteResume}
          />
        ))}
      </div>
    </>
  );
};
