import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  ApplicationStatus,
  MeQuery,
  MyFavouriteJobsQuery,
  SortOrder,
  useCreateApplicationMutation,
  useDeleteApplicationMutation,
  useMyFavouriteJobsQuery,
} from '@src/apollo/types/graphql';
import JobCard from '@src/components/jobs/JobCard';
import { MyUserContext } from '@src/context/MyUserContext';

export const Applications = () => {
  /*{
    "1":"Jobs",
    "2":"Applied",
    "3":"Interviews",
    "4":"Jobs",
  }*/
  const [jobColumnMap, setJobColumnMap] = useState<{ [key: string]: string }>(
    {},
  );
  const userContext = useContext(MyUserContext);

  const [createApplicationMutation] = useCreateApplicationMutation();
  const [deleteApplicationMutation] = useDeleteApplicationMutation();

  const [favoriteJobs, setFavoriteJobs] = useState<
    MyFavouriteJobsQuery['me']['myFavouriteJobs']['nodes']
  >([]);
  const [initialApplications, setInitialApplications] = useState<
    MeQuery['me']['applications']
  >([]);

  useMyFavouriteJobsQuery({
    variables: {
      //todo!! implement pagination
      take: 9999999,
      skip: 0,
      orderBy: {
        createdAt: SortOrder.Desc,
      },
    },
    onCompleted(data) {
      if (favoriteJobs.length === 0) {
        // set initial favorite jobs from query to avoid re-rendering
        setFavoriteJobs(data.me.myFavouriteJobs.nodes);
      }
    },
  });

  useEffect(() => {
    if (initialApplications.length === 0) {
      // set initial applications from user context to avoid re-rendering
      setInitialApplications(userContext.myUser?.applications ?? []);
    }
  }, [userContext.myUser?.applications]);

  const uniqueColumns = [
    'Jobs',
    ApplicationStatus.Applied,
    ApplicationStatus.Interviewing,
    ApplicationStatus.Offer,
    ApplicationStatus.Rejected,
    ApplicationStatus.Archived,
  ];

  const data = useMemo(() => {
    return (
      favoriteJobs.map((record) => ({
        id: record.id,
        content: record,
        column:
          initialApplications.find((app) => app.job.id === record.id)?.status ??
          'Jobs',
      })) || []
    );
  }, [favoriteJobs, initialApplications]);

  useEffect(() => {
    //set initial column map for each job
    const newMap: Record<string, string> = {};
    data.forEach((item) => {
      newMap[item.id] = item.column;
    });
    setJobColumnMap(newMap);
  }, [data]);

  const handleDragEnd = useCallback(
    async (item: any) => {
      const newColumn = item.destination.droppableId;
      const newMap = { ...jobColumnMap, [item.draggableId]: newColumn };
      const jobId = item.draggableId;

      if (newColumn === 'Jobs') {
        const application = initialApplications.find(
          (app) => app.job.id === jobId,
        );

        if (application) {
          deleteApplicationMutation({
            variables: {
              deleteApplicationId: application.id,
            },
          });
        }
      } else {
        createApplicationMutation({
          variables: {
            input: {
              jobId,
              status: newColumn,
            },
          },
        });
      }

      setJobColumnMap(newMap);
    },
    [jobColumnMap, setJobColumnMap],
  );

  return (
    <div className="overflow-scroll h-full flex flex-row">
      <DragDropContext onDragEnd={handleDragEnd}>
        {uniqueColumns.map((column) => (
          <Droppable droppableId={column} key={column}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className="h-full w-full border rounded-md border-purple-700 overflow-y-scroll mx-1"
              >
                <h2 className="sticky top-0 text-xl font-semibold text-grey-dark font-lexend text-center my-4">
                  {column}
                </h2>
                {data
                  .filter((item) => jobColumnMap[item.id] === column)
                  .map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="mb-2"
                        >
                          <JobCard job={item.content} minimized />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
};
