import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LocalRegisterError } from '@src/apollo/types/graphql';
import JpButton from '@src/components/core/JpButton';
import { Logo } from '@src/components/misc/JpLogo';
import { SalientTextField } from '@src/components/theme/salient/SalientTextField';
import UserAgreementLink from '@src/components/user-agreement/UserAgreementLink';
import { useAuth } from '@src/hooks/useAuth';
import { GoogleIcon, LinkedinIcon, LoadingIcon } from '@src/images/SvgIcons';

export const Register = () => {
  // State
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [userAgreementChecked, setUserAgreementChecked] = React.useState(false);

  const [authenticationInProgress, setAuthenticationInProgress] =
    React.useState(false);

  // Hooks
  const { loginWithGoogle, loginWithLinkedin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAgreementChecked(event.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (authenticationInProgress) {
      return;
    }

    setAuthenticationInProgress(true);

    fetch(`${import.meta.env.VITE_AUTH_ENDPOINT}/local/register`, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          const json = await res.json();
          handleSubmitError(json.error);
          setAuthenticationInProgress(false);

          return;
        }

        handleSubmitSuccess();
        setAuthenticationInProgress(false);
      })
      .finally(() => {
        (window as any)._iub.cons_instructions.push([
          'submit',
          {
            submitElement: document.getElementById('registration-submit'),
            form: {
              selector: document.getElementById('registration-form'),
              map: {
                subject: {
                  email: 'username',
                },
                preferences: {
                  generic: 'generic',
                },
                exclude: ['password'],
              },
            },
            consent: {
              legal_notices: [
                {
                  identifier: 'privacy_policy',
                },
                {
                  identifier: 'terms',
                },
              ],
            },
          },
        ]);
      });
  };

  const handleSubmitError = (registerError: string) => {
    let notificationMessage = '';

    switch (registerError) {
      case LocalRegisterError.EmailValidationError: {
        notificationMessage = t(
          'views.register.notifications.errors.invalidEmail',
        );
        break;
      }
      case LocalRegisterError.PasswordValidationError: {
        notificationMessage = t(
          'views.register.notifications.errors.weakPassword',
        );
        break;
      }
      case LocalRegisterError.EmailAlreadyExistsError: {
        notificationMessage = t(
          'views.register.notifications.errors.emailAlreadyExists',
        );
        break;
      }
      case LocalRegisterError.UnknownError:
      default: {
        notificationMessage = t(
          'views.register.notifications.errors.unknownError',
        );
      }
    }

    toast(notificationMessage, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: 'error',
    });
  };

  const handleSubmitSuccess = () => {
    const notificationMessage = t('views.register.notifications.success');
    const notificationTime = 5000;

    toast(notificationMessage, {
      position: 'top-right',
      autoClose: notificationTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: 'success',
    });

    setTimeout(() => {
      const preChosenPlan = searchParams.get('plan');
      if (preChosenPlan) {
        navigate('/dashboard?checkout=subscription&auto=true');

        return;
      }

      navigate('/dashboard');
    }, notificationTime);
  };

  return (
    <>
      <div className="flex flex-col mb-7">
        <Link to="/login" aria-label="Logo">
          <Logo className="h-10 w-auto" />
        </Link>
        <div className="mt-20">
          <h2 className="text-lg font-semibold text-gray-900">
            {t('views.register.title')}
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            {t('views.register.subtitle')}{' '}
            <Link
              to="/login"
              className="font-medium text-blue-dark hover:underline"
            >
              {t('views.register.subtitleLink')}
            </Link>{' '}
          </p>
        </div>
      </div>
      <form
        className="mt-10 grid grid-cols-1 gap-y-8"
        id="registration-form"
        onSubmit={(ev) => handleSubmit(ev)}
      >
        <SalientTextField
          label={t('views.register.form.email')}
          id="email"
          name="username"
          type="email"
          autoComplete="email"
          required
          onChange={(e) => setUsername(e.target.value)}
        />
        <SalientTextField
          label={t('views.register.form.password')}
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
          onChange={(e) => setPassword(e.target.value)}
          data-cons-exclude
        />

        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              type="checkbox"
              name="user-consent"
              className="h-4 w-4 rounded border-gray-300 text-blue-dark focus:ring-blue-dark"
              checked={userAgreementChecked}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <span className="text-gray-500">
              {t('views.register.userAgreement')}
              {UserAgreementLink({ userAgreementType: 'privacy-policy' })}
              {t('views.register.and')}
              {UserAgreementLink({ userAgreementType: 'terms-and-conditions' })}
              .
            </span>
          </div>
        </div>

        <JpButton
          id="registration-submit"
          type="submit"
          variant="solid"
          color="blue"
          className="w-full rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          disabled={!userAgreementChecked}
        >
          {authenticationInProgress && <LoadingIcon />}
          <span>
            {t('views.register.form.submitButton')}{' '}
            <span aria-hidden="true">&rarr;</span>
          </span>
        </JpButton>
      </form>
      <p className="text-gray-500 text-sm mt-10 text-center mb-4 uppercase">
        {t('views.register.or')}
      </p>
      <div className="mt-10 grid grid-cols-1 gap-y-4">
        <div>
          <JpButton
            href="#"
            type="button"
            variant="solid"
            color="blue"
            className="w-full rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={loginWithGoogle}
            disabled={!userAgreementChecked}
          >
            <GoogleIcon className="fill-current w-5 h-5 mr-5" />
            <span>{t('views.register.registerWithGoogleButton')}</span>
          </JpButton>
        </div>
        <div>
          <JpButton
            href="#"
            type="button"
            variant="solid"
            color="blue"
            className="w-full rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={loginWithLinkedin}
            disabled={!userAgreementChecked}
          >
            <LinkedinIcon className="fill-current w-5 h-5 mr-5" />
            <span>{t('views.register.registerWithLinkedinButton')}</span>
          </JpButton>
        </div>
      </div>
    </>
  );
};
