import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMyTopSkillsFromViewedJobsQuery } from '@src/apollo/types/graphql';

import WidgetFrame from './misc/WidgetFrame';
import WidgetHeader from './misc/WidgetHeader';

const TopSkillsWidget: React.FC = () => {
  // Hooks
  const navigate = useNavigate();

  // Queries
  const myTopSkillsQuery = useMyTopSkillsFromViewedJobsQuery();

  // Memos
  const queriesLoading = useMemo(() => {
    return myTopSkillsQuery.loading;
  }, [myTopSkillsQuery]);

  const queriesError = useMemo(() => {
    return myTopSkillsQuery.error;
  }, [myTopSkillsQuery]);

  const queriesReady = useMemo(() => {
    return !queriesLoading && !queriesError;
  }, [queriesLoading, queriesError]);

  const myTopSkillsFromViewedJobs = useMemo(() => {
    if (queriesReady) {
      return myTopSkillsQuery.data?.me.myTopSkillsFromViewedJobs;
    }

    return [];
  }, [queriesReady, myTopSkillsQuery]);

  const noTopSkillsLoaded = useMemo(() => {
    return (
      queriesReady &&
      (!myTopSkillsFromViewedJobs || myTopSkillsFromViewedJobs.length === 0)
    );
  }, [queriesReady, myTopSkillsFromViewedJobs]);

  // Methods
  const handleExploreJobsClick = () => {
    navigate('/dashboard/explore');
  };

  // Template
  return (
    <WidgetFrame
      queriesReady={queriesReady}
      queriesLoading={queriesLoading}
      queriesError={queriesError}
      extraClasses="max-h-[550px] p-3"
    >
      <WidgetHeader
        title="Top skills"
        subtitle="Explore essential career skills"
      />

      <div className="grid grid-cols-12 items-center mt-5 overflow-y-scroll max-h-[450px]">
        {noTopSkillsLoaded && (
          <div className="col-span-12">
            <div className="text-center text-gray-500">
              No top skills loaded. Start exploring jobs{' '}
              <a
                className="cursor-pointer text-blue-500"
                onClick={handleExploreJobsClick}
              >
                here
              </a>{' '}
              to see your top skills.
            </div>
          </div>
        )}

        {myTopSkillsFromViewedJobs &&
          myTopSkillsFromViewedJobs.map((topSkill) => {
            return (
              <React.Fragment key={topSkill.skill}>
                <div className="col-span-12 w-full mt-5 bg-grey-light-02 rounded-10 p-2">
                  <span className="text-md font-lexend text-grey-dark">
                    {topSkill.skill}
                  </span>

                  <span className="float-right bg-clip-text text-transparent bg-gradient-to-tr from-blue-dark via-purple-dark to-purple-light">
                    {topSkill.percentage}%
                  </span>

                  <div className="bg-blue-light-01 rounded-lg mt-2">
                    <div
                      className="rounded-lg bg-blue-dark h-1.5"
                      style={{ width: `${topSkill.percentage || 0}%` }}
                    ></div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </WidgetFrame>
  );
};

export default TopSkillsWidget;
