import React from 'react';

import { JobWhereInput, SortOrder } from '@src/apollo/types/graphql';
import { SortOption } from '@src/types/sorting';

export const ListContext = React.createContext<{
  resultsCount: number;
  sortOptionList: SortOption[];
  sortBy: SortOption;
  sortOrder: SortOrder;
  handleSortButtonClick: (sortOption: SortOption) => void;
  getSortOptionLabel: (sortOption: SortOption) => string;
  currentFilters: JobWhereInput;
  applyFilters: (filters: JobWhereInput) => void;
  viewMode?: 'map' | 'grid';
  changeViewMode?: (viewMode: 'map' | 'grid') => void;
  toggleFilters?: () => void;
}>({
  resultsCount: 0,
  sortOptionList: [],
  sortBy: 'datePosted',
  sortOrder: SortOrder.Asc,
  handleSortButtonClick: () => undefined,
  getSortOptionLabel: () => '',
  currentFilters: {},
  applyFilters: () => undefined,
  viewMode: 'grid',
  changeViewMode: () => undefined,
  toggleFilters: () => undefined,
});
