import clsx from 'clsx';
import { useContext } from 'react';

import JpDropzone from '@src/components/misc/JpDropzone';
import { ResumeItem } from '@src/components/profile/ResumeItem';
import { MyUserContext } from '@src/context/MyUserContext';

const ResumeStep: React.FC = () => {
  const myUser = useContext(MyUserContext).myUser;

  const handleUpdateResume = () => {
    return;
  };
  const handleDeleteResume = () => {
    return;
  };

  return (
    <div
      className={clsx(
        'flex flex-col p-0 h-full gap-8 justify-start items-center',
      )}
    >
      {myUser?.myResumes.length === 0 && <JpDropzone />}

      {myUser?.myResumes.length !== 0 && (
        <div className="pb-3">
          {myUser?.myResumes.map((resume) => (
            <ResumeItem
              resume={resume}
              key={resume.id}
              isDefault={resume.id == myUser?.resume?.id}
              showButtons={false}
              handleUpdateResume={handleUpdateResume}
              handleDeleteResume={handleDeleteResume}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ResumeStep;
