import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ViewColumnsIcon,
  MapIcon,
  AdjustmentsVerticalIcon,
} from '@heroicons/react/24/outline';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import React, { Fragment, useContext } from 'react';

import { SortOrder } from '@src/apollo/types/graphql';
import { ListContext } from '@src/context/JobListContext';

export const SortingBar: React.FC = () => {
  const {
    resultsCount,
    sortOptionList,
    sortBy,
    sortOrder,
    handleSortButtonClick,
    getSortOptionLabel,
    viewMode,
    changeViewMode,
    toggleFilters,
  } = useContext(ListContext);

  return (
    <>
      <div className="flex justify-end items-center w-full col-span-12 bg-white py-3 px-3 rounded-lg gap-1 md:gap-5">
        <div className="flex-grow">
          <p className="text-gray-500">
            Showing results:
            <span className="text-gray-900"> {resultsCount}</span>
          </p>
        </div>
        <div className="flex gap-1">
          <button
            className={classNames(
              viewMode === 'grid' ? 'bg-blue-light-02' : 'bg-white',
              'rounded p-2 bg-blue-light-02',
            )}
            onClick={() =>
              changeViewMode ? changeViewMode('grid') : undefined
            }
          >
            <ViewColumnsIcon
              className="h-5 w-5 text-blue-dark group-hover:text-gray-500"
              aria-hidden="true"
            />
          </button>
          <button
            className={classNames(
              viewMode === 'map' ? 'bg-blue-light-02' : 'bg-white',
              'rounded p-2 bg-blue-light-02',
            )}
            onClick={() => (changeViewMode ? changeViewMode('map') : undefined)}
          >
            <MapIcon className="h-5 w-5 text-blue-dark" aria-hidden="true" />
          </button>
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center items-center md:gap-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {sortOrder === SortOrder.Asc ? (
                <ArrowUpIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowDownIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              )}

              {getSortOptionLabel(sortBy)}
              <ChevronDownIcon
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {sortOptionList.map((sortOption) => (
                  <Menu.Item key={nanoid()}>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active || sortBy === sortOption
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm',
                        )}
                        onClick={() => handleSortButtonClick(sortOption)}
                      >
                        {getSortOptionLabel(sortOption)}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
              <div className="py-1">
                <Menu.Item key={nanoid()}>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active || sortOrder === SortOrder.Asc
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                      onClick={() => handleSortButtonClick(sortBy)}
                    >
                      <ArrowUpIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Ascending
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item key={nanoid()}>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active || sortOrder === SortOrder.Desc
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                      onClick={() => handleSortButtonClick(sortBy)}
                    >
                      <ArrowDownIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Descending
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <button className="rounded ps-1" onClick={toggleFilters}>
          <AdjustmentsVerticalIcon
            className="h-5 w-5 text-blue-dark group-hover:text-gray-500"
            aria-hidden="true"
          />
        </button>
      </div>
    </>
  );
};
