import {
  CurrencyDollarIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useMemo } from 'react';

import { JobRate } from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';

import WidgetFrame from './misc/WidgetFrame';

const AverageSalaryWidget: React.FC = () => {
  const { myUser, loading } = useContext(MyUserContext);
  const statistics = useMemo(() => {
    if (!myUser || !myUser.statistics) {
      return null;
    }

    return myUser.statistics;
  }, [myUser]);

  const salaryPercentageChange = useMemo(() => {
    const percentageChange =
      statistics?.lastWeekStatistics.avgHourlyRatePrevWeekChangePercentage;
    if (!percentageChange) {
      return null;
    }

    if (percentageChange > 0) {
      return `+${percentageChange}%`;
    }

    return `${percentageChange}%`;
  }, [statistics]);

  const avgSalaryText = useMemo(() => {
    const code = myUser?.profile.currency?.code;

    switch (myUser?.profile.rate) {
      case JobRate.Hourly:
        return `${statistics?.lastWeekStatistics.avgHourlyRate} ${code}/hour`;
      case JobRate.Monthly:
        return `${statistics?.lastWeekStatistics.avgMonthlyRate} ${code}/month`;
      case JobRate.Yearly:
        return `${statistics?.lastWeekStatistics.avgYearlyRate} ${code}/year`;
    }

    return null;
  }, [statistics, myUser?.profile.currency?.code, myUser?.profile.rate]);

  // Template
  return (
    <WidgetFrame
      queriesReady={statistics !== null}
      queriesLoading={loading}
      extraClasses="py-3"
    >
      <div className="h-full flex flex-wrap items-center justify-center">
        <div className="flex w-4/5 justify-start">
          <span className="text-xl inline-flex items-center gap-5 font-lexend text-grey-dark">
            <CurrencyDollarIcon className="stroke-green-500 h-9"></CurrencyDollarIcon>
            Average salary
          </span>
        </div>
        <div className="flex flex-wrap w-4/5 justify-between">
          <span className="text-xl font-semibold text-grey-dark font-lexend">
            {avgSalaryText}
          </span>

          <span className="text-sm text-green-500 flex items-center font-bold gap-1">
            <ArrowTrendingUpIcon className="h-5 stroke-2 "></ArrowTrendingUpIcon>
            {salaryPercentageChange ?? '0%'}
          </span>

          <span className="text-xs text-grey-light-01 font-semibold">
            vs last 7 days
          </span>
        </div>
      </div>
    </WidgetFrame>
  );
};

export default AverageSalaryWidget;
