import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import JpButton from '@src/components/core/JpButton';
import { Logo } from '@src/components/misc/JpLogo';
import { SalientTextField } from '@src/components/theme/salient/SalientTextField';
import { LoadingIcon } from '@src/images/SvgIcons';

export const ForgotPassword = () => {
  // Constants
  const TIME_TO_WAIT_BEFORE_REQUESTING_ANOTHER_PASSWORD_RESET = 1; // minutes

  // Hooks
  const { t } = useTranslation();

  // State
  const [email, setEmail] = React.useState('');
  const [passwordResettedAt, setPasswordResettedAt] =
    React.useState<Date | null>(null);

  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [timeLeftBeforeRequestingAnotherPasswordReset, setTimeLeft] =
    React.useState(0);
  const [interval, setIntervalId] = React.useState<NodeJS.Timer | null>(null);

  // Effects
  React.useEffect(() => {
    if (!passwordResettedAt) {
      return;
    }

    if (interval) {
      clearInterval(interval);
    }

    setTimeLeft(TIME_TO_WAIT_BEFORE_REQUESTING_ANOTHER_PASSWORD_RESET * 60);

    const newInterval = setInterval(() => {
      const now = new Date();

      const timeDifference =
        now.getTime() -
        (passwordResettedAt
          ? passwordResettedAt.getTime()
          : now.getTime() - 100);

      const timeDifferenceInSeconds = timeDifference / 1000;
      const timeLeft =
        TIME_TO_WAIT_BEFORE_REQUESTING_ANOTHER_PASSWORD_RESET * 60 -
        timeDifferenceInSeconds;

      setTimeLeft(Math.ceil(timeLeft));
    }, 1000);

    setIntervalId(newInterval);

    return () => clearInterval(newInterval);
  }, [passwordResettedAt]);

  // Methods
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (requestInProgress) {
      return;
    }

    setRequestInProgress(true);

    try {
      await axios.post(
        `${import.meta.env.VITE_AUTH_ENDPOINT}/local/forgot-password`,
        {
          email,
        },
      );

      handleSubmitSuccess();
      setPasswordResettedAt(new Date());
    } catch (error) {
      handleSubmitError();
    }

    setRequestInProgress(false);
  };

  const handleSubmitError = () => {
    const notificationMessage = t('views.forgotPassword.notifications.error');

    toast(notificationMessage, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: 'error',
    });
  };

  const handleSubmitSuccess = () => {
    const notificationMessage = t('views.forgotPassword.notifications.success');
    const notificationTime = 2000;

    toast(notificationMessage, {
      position: 'top-right',
      autoClose: notificationTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: 'success',
    });
  };

  return (
    <>
      <div className="flex flex-col mb-7">
        <Link to="/login" aria-label="Logo">
          <Logo className="h-10 w-auto" />
        </Link>
        <div className="mt-20">
          <h2 className="text-lg font-semibold text-gray-900">
            {t('views.forgotPassword.title')}
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            {t('views.forgotPassword.subtitle')}{' '}
            <Link
              to="/register"
              className="font-medium text-blue-600 hover:underline"
            >
              {t('views.forgotPassword.subtitleLink')}
            </Link>{' '}
          </p>
        </div>
      </div>
      <form
        action={`${import.meta.env.VITE_AUTH_ENDPOINT}/local/login`}
        method="POST"
        className="mt-10 grid grid-cols-1 gap-y-8"
      >
        <SalientTextField
          label={t('views.forgotPassword.form.email')}
          id="email"
          name="username"
          type="email"
          autoComplete="email"
          required
          onChange={handleEmailChange}
        />

        {timeLeftBeforeRequestingAnotherPasswordReset > 0 && (
          <div
            className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
            role="alert"
          >
            <p className="font-bold">
              {t('views.forgotPassword.alreadyRequestedPasswordReset')}
            </p>
            <p className="text-sm mt-2">
              {t('views.forgotPassword.waitFor', {
                minutes: TIME_TO_WAIT_BEFORE_REQUESTING_ANOTHER_PASSWORD_RESET,
              })}
            </p>
            <p className="text-sm mt-5">
              {t('views.forgotPassword.timeLeft', {
                seconds: timeLeftBeforeRequestingAnotherPasswordReset,
              })}
            </p>
          </div>
        )}

        <JpButton
          type="submit"
          variant="solid"
          color="blue"
          className="w-full rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {requestInProgress && <LoadingIcon />}

          <span>
            {t('views.forgotPassword.form.submitButton')}{' '}
            <span aria-hidden="true">&rarr;</span>
          </span>
        </JpButton>
      </form>
    </>
  );
};
