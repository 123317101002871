import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@src/hooks/useAuth';

export const UserAccountNotVerified = () => {
  // Hooks
  const { t } = useTranslation();
  const { logout } = useAuth();

  // Methods
  const handleResend = () => {
    fetch(`${import.meta.env.VITE_AUTH_ENDPOINT}/resend`, {
      method: 'post',
      credentials: 'include',
    }).then(async (res) => await res.json());
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-5xl font-lexend font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-tr from-purple-dark to-blue-dark">
          {t('accountNotVerified.title')}
        </h1>
        <p className="text-lg mb-8 font-lexend text-blue-dark">
          {t('accountNotVerified.subtitle')}
        </p>
        <button
          className="bg-blue-dark text-white h-10 rounded-md w-56 mt-5 font-lexend"
          onClick={handleResend}
        >
          Resend verification link
        </button>
        <button className="btn btn-primary w-48" onClick={handleLogout}>
          {t('accountNotVerified.logout')}
        </button>
      </div>
    </Fragment>
  );
};
