import { useTranslation } from 'react-i18next';

export type UserAgreementType = 'privacy-policy' | 'terms-and-conditions';

type UserAgreementProps = {
  userAgreementType: UserAgreementType;
};

const UserAgreementLink: React.FC<UserAgreementProps> = (
  props: UserAgreementProps,
) => {
  const { t } = useTranslation();

  return (
    <>
      <a
        target="_blank"
        href={`https://www.jobpedia.ai/${props.userAgreementType}`}
        className="font-medium text-blue-dark hover:underline hover:text-blue-dark"
        rel="noreferrer"
      >
        {props.userAgreementType === 'privacy-policy'
          ? t('views.register.privacyPolicy')
          : t('views.register.termsAndConditions')}
      </a>
    </>
  );
};
export default UserAgreementLink;
