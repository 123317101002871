import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionPlanKind } from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';

import { JpPlan } from '../core/JpPlan';

export const SubscriptionPlanBody = () => {
  // Hooks
  const { t } = useTranslation();
  const userContext = useContext(MyUserContext);
  const subscriptionPlanContext = useContext(SubscriptionsContext);

  return (
    <div className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8">
      <JpPlan
        name={t('subscriptionPlans.free.title')}
        price={t('subscriptionPlans.free.price')}
        description={t('subscriptionPlans.free.description')}
        features={[
          t('subscriptionPlans.free.features.compatibilities'),
          t('subscriptionPlans.free.features.compatibilityCalculation'),
          t('subscriptionPlans.free.features.historyTracking'),
          t('subscriptionPlans.free.features.bookmarks'),
        ]}
        kind={SubscriptionPlanKind.FreePlan}
        active={!userContext.myUser?.isPro}
        featured={false}
      />

      <JpPlan
        name={t('subscriptionPlans.premium.title')}
        price={t('subscriptionPlans.premium.price')}
        description={t('subscriptionPlans.premium.description')}
        features={[
          t('subscriptionPlans.premium.features.compatibilities'),
          t('subscriptionPlans.premium.features.compatibilityCalculation'),
          t('subscriptionPlans.premium.features.historyTracking'),
          t('subscriptionPlans.premium.features.jobsRecommandation'),
          t('subscriptionPlans.premium.features.companyInsights'),
          t('subscriptionPlans.premium.features.advancedAnalytics'),
          t('subscriptionPlans.premium.features.resumeAnalisys'),
          t('subscriptionPlans.premium.features.bookmarks'),
        ]}
        kind={SubscriptionPlanKind.PremiumPlan}
        active={userContext.myUser?.isPro}
        featured={true}
        onSubmitButtonClick={() => subscriptionPlanContext.upgrade()}
      />
    </div>
  );
};
