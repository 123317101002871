import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

export type ModalProps = PropsWithChildren & {
  isOpen: boolean;
  onClose: () => void;
  isJobCompareWidget?: boolean;
  modalClassNames?: string;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isJobCompareWidget = false,
  modalClassNames = '',
}) => {
  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={handleClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>

        <div
          className={clsx({
            [modalClassNames]: true,
            'inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-full':
              true,
            'sm:max-w-5xl overflow-hidden': !isJobCompareWidget,
            'md:max-w-[80vw]': isJobCompareWidget,
            'sm:max-h-[90vh] overflow-y-scroll': isJobCompareWidget,
          })}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
