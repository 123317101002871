import { PhotoIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';

import {
  JobRate,
  OauthProvider,
  UpdateMyPasswordError,
  useCurrenciesQuery,
  useUpdateMyPasswordMutation,
  useUpdateMyPersonalInformationMutation,
} from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';

import { countryListAlpha2 } from './misc/countryListAlpha2';

const transitionClass = 'transition-all duration-100';

export const ProfilePersonalInformation: React.FC = () => {
  // Queries
  const { data: currencies } = useCurrenciesQuery();

  // States
  const [tempAvatar, setTempAvatar] = React.useState<File | null>(null);
  const [tempAvatarPreviewUrl, setTempAvatarPreviewUrl] = React.useState<
    string | null
  >(null);

  const userContext = useContext(MyUserContext);

  // Hooks
  const { t } = useTranslation();

  // Mutations
  const [updateMyPersonalInformationMutation] =
    useUpdateMyPersonalInformationMutation();
  const [updateMyPasswordMutation] = useUpdateMyPasswordMutation();
  const clickOnFileInput = () => {
    const fileInput = document.getElementById('user-avatar');
    if (fileInput) {
      fileInput.click();
    }
  };

  useEffect(() => {
    if (!tempAvatar) {
      return;
    }
    // create the preview
    const objectUrl = URL.createObjectURL(tempAvatar);
    setTempAvatarPreviewUrl(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [tempAvatar]);

  const handleFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const profession: string | undefined =
      (ev.target as HTMLFormElement).profession?.value ?? undefined;
    const avatarFile: FileList | null =
      (ev.target as HTMLFormElement)['user-avatar']?.files ?? null;
    const firstName: string | undefined =
      (ev.target as HTMLFormElement)['first-name']?.value ?? undefined;
    const lastName: string | null =
      (ev.target as HTMLFormElement)['last-name']?.value ?? undefined;
    const email: string | null =
      (ev.target as HTMLFormElement)['email']?.value ?? undefined;
    const currency: string | null =
      (ev.target as HTMLFormElement)['currency']?.value ?? undefined;
    const rate: string | null =
      (ev.target as HTMLFormElement)['rate']?.value ?? undefined;

    if (avatarFile) {
      userContext.uploadAvatar(avatarFile);
    }
    updateMyPersonalInformationMutation({
      variables: {
        profession,
        firstName,
        lastName,
        email,
        currencyCode: currency,
        jobRate: rate as JobRate | null,
      },
    })
      .then(() => {
        toast(t('views.profile.success.personalInformationUpdated'), {
          type: 'success',
        });
      })
      .catch(() => {
        toast(t('views.profile.errors.personalInformationUpdateFailed'), {
          type: 'error',
        });
      });
  };
  const handleSecurityFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const oldPassword: string | undefined =
      (ev.target as HTMLFormElement)['current-password']?.value ?? undefined;
    const newPassword: string | undefined =
      (ev.target as HTMLFormElement)['new-password']?.value ?? undefined;
    const confirmNewPassword: string | undefined =
      (ev.target as HTMLFormElement)['confirm-new-password']?.value ??
      undefined;

    if (!oldPassword || !newPassword || !confirmNewPassword) {
      toast('Please fill all the fields', {
        type: 'error',
      });

      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast(t('views.profile.errors.newPasswordsDoNotMatch'), {
        type: 'warning',
      });

      return;
    }

    updateMyPasswordMutation({
      variables: {
        oldPassword,
        newPassword,
      },
    })
      .then(() => {
        toast(t('views.profile.success.passwordUpdated'), {
          type: 'success',
        });
      })
      .catch((error) => {
        let errorMessage = '';

        switch (error.message) {
          case UpdateMyPasswordError.InvalidOldPassword:
            errorMessage = t('views.profile.errors.invalidOldPassword');
            break;
          case UpdateMyPasswordError.InvalidNewPassword:
            errorMessage = t('views.profile.errors.invalidNewPassword');
            break;
          default: {
            errorMessage = t('views.profile.errors.unknown');
          }
        }

        toast(errorMessage, {
          type: 'error',
        });

        return;
      });
  };

  return (
    <>
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={(ev) => handleFormSubmit(ev)}
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="profession"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t('views.profile.form.profession')}
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        name="profession"
                        id="profession"
                        defaultValue={
                          userContext.myUser?.profile.profession ?? ''
                        }
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Awesome Job Hunter"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="currency"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Favourite currency
                  </label>
                  <div className="mt-2">
                    <Select
                      name="currency"
                      defaultValue={{
                        value: userContext.myUser?.profile.currency?.code,
                        label: userContext.myUser?.profile.currency?.code,
                      }}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      options={currencies?.currencies.map((currency) => ({
                        value: currency.code,
                        label: currency.code,
                      }))}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="rate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Favourite job rate
                  </label>
                  <div className="mt-2">
                    <Select
                      name="rate"
                      defaultValue={{
                        value: userContext.myUser?.profile.rate,
                        label: capitalize(
                          userContext.myUser?.profile.rate ?? '',
                        ),
                      }}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      options={Object.values(JobRate).map((rate) => ({
                        value: rate,
                        label: capitalize(rate),
                      }))}
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Photo
                  </label>
                  <div className="mt-2 flex items-center gap-x-3">
                    <img
                      src={tempAvatarPreviewUrl ?? userContext.profilePicture}
                      alt="profile-avatar"
                      className={clsx(
                        'object-cover cursor-pointer',
                        'inline-block h-40 w-40 rounded-full',
                        'shadow-lg shadow-grey-dark/25',
                        'outline outline-2',
                        'outline-offset-0 hover:outline-offset-4',
                        'outline-grey-light-01 hover:outline-blue-dark',
                        transitionClass,
                      )}
                      onClick={clickOnFileInput}
                    />
                    <button
                      type="button"
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={clickOnFileInput}
                    >
                      Change
                    </button>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <PhotoIcon
                        className="mx-auto h-12 w-12 text-gray-300"
                        aria-hidden="true"
                      />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="user-avatar"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2"
                        >
                          <span>Upload a file</span>
                          <input
                            id="user-avatar"
                            name="user-avatar"
                            type="file"
                            className="sr-only"
                            onChange={(ev) => {
                              const evTarget = ev.target as HTMLInputElement;
                              if (evTarget.files && evTarget.files.length > 0) {
                                const files = evTarget.files;
                                setTempAvatar(files[0]);
                              }
                            }}
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="reset"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form>
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={(ev) => handleFormSubmit(ev)}
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t('views.profile.form.firstName')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={userContext.myUser?.profile.firstName}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={userContext.myUser?.profile.lastName}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      defaultValue={userContext.myUser?.profile.primaryEmail}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Country
                  </label>
                  <div className="mt-2">
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      defaultValue={'Select a country'}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>-</option>
                      {Object.values(countryListAlpha2).map((country) => (
                        <option key={country}>{country}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Street address
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 sm:col-start-1">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    City
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    State / Province
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="reset"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form>
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>
        </div>

        {userContext.myUser?.profile.provider === OauthProvider.Local && (
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <form
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
              onSubmit={(ev) => handleSecurityFormSubmit(ev)}
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t('views.profile.form.currentPassword')}
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        name="current-password"
                        id="current-password"
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t('views.profile.form.newPassword')}
                    </label>
                    <div className="mt-2">
                      <input
                        id="new-password"
                        name="new-password"
                        type="password"
                        autoComplete="new-password"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="confirm-new-password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t('views.profile.form.newPasswordConfirm')}
                    </label>
                    <div className="mt-2">
                      <input
                        id="confirm-new-password"
                        name="confirm-new-password"
                        type="password"
                        autoComplete="new-password"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                  type="reset"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </div>
            </form>
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Account Security
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Set a strong password to protect your account.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
