import { SortingBar } from '../filters/SortingBar';

export const JpExplorerLayout: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <div className="h-full m-3 rounded-xl bg-white bg-opacity-5 border border-white border-opacity-10 overflow-hidden">
      <div className="grid grid-cols-12 grid-rows-[auto,1fr] h-full gap-2">
        <SortingBar />
        {children}
      </div>
    </div>
  );
};
