import clsx from 'clsx';

interface IconProps {
  id: string;
  className?: string;
}

export function AiCreditsIconOutline(props: IconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={clsx('fill-none', props.className)}
    >
      <path
        d="M16.875 5.3125C16.875 7.21083 13.7967 8.75 10 8.75C6.20333 8.75 3.125 7.21083 3.125 5.3125M16.875 5.3125C16.875 3.41417 13.7967 1.875 10 1.875C6.20333 1.875 3.125 3.41417 3.125 5.3125M16.875 5.3125V14.6875C16.875 16.5858 13.7967 18.125 10 18.125C6.20333 18.125 3.125 16.5858 3.125 14.6875V5.3125M16.875 5.3125V8.4375M3.125 5.3125V8.4375M16.875 8.4375V11.5625C16.875 13.4608 13.7967 15 10 15C6.20333 15 3.125 13.4608 3.125 11.5625V8.4375M16.875 8.4375C16.875 10.3358 13.7967 11.875 10 11.875C6.20333 11.875 3.125 10.3358 3.125 8.4375"
        stroke={`url(#${props.id})`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <defs>
        <linearGradient
          id={props.id}
          x1="16.875"
          y1="1.875"
          x2="-0.552507"
          y2="8.61225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function AiCreditsIconSolid(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={clsx('fill-none', props.className)}
    >
      <path
        d="M17.5 5.3125C17.5 7.55583 14.1417 9.375 10 9.375C5.85833 9.375 2.5 7.55583 2.5 5.3125C2.5 3.06917 5.85833 1.25 10 1.25C14.1417 1.25 17.5 3.06917 17.5 5.3125Z"
        fill={`url(#gradient01_${props.id})`}
      />
      <path
        d="M10 10.625C12.2375 10.625 14.325 10.1367 15.8983 9.28419C16.4774 8.97677 17.0097 8.58843 17.4792 8.13086C17.4925 8.23169 17.5 8.33419 17.5 8.43753C17.5 10.6809 14.1417 12.5 10 12.5C5.85833 12.5 2.5 10.6809 2.5 8.43753C2.5 8.33419 2.5075 8.23169 2.52083 8.13086C2.99035 8.58841 3.52264 8.97675 4.10167 9.28419C5.67417 10.1367 7.7625 10.625 10 10.625Z"
        fill={`url(#gradient02_${props.id})`}
      />
      <path
        d="M10 13.75C12.2375 13.75 14.325 13.2617 15.8983 12.4092C16.4774 12.1018 17.0097 11.7134 17.4792 11.2559C17.4925 11.3567 17.5 11.4592 17.5 11.5625C17.5 13.8059 14.1417 15.625 10 15.625C5.85833 15.625 2.5 13.8059 2.5 11.5625C2.5 11.4592 2.5075 11.3567 2.52083 11.2559C2.99034 11.7134 3.52263 12.1018 4.10167 12.4092C5.67417 13.2617 7.7625 13.75 10 13.75Z"
        fill={`url(#gradient03_${props.id})`}
      />
      <path
        d="M10 16.875C12.2375 16.875 14.325 16.3867 15.8983 15.5342C16.4774 15.2268 17.0097 14.8384 17.4792 14.3809C17.4925 14.4817 17.5 14.5842 17.5 14.6875C17.5 16.9309 14.1417 18.75 10 18.75C5.85833 18.75 2.5 16.9309 2.5 14.6875C2.5 14.5842 2.5075 14.4817 2.52083 14.3809C2.99034 14.8384 3.52263 15.2268 4.10167 15.5342C5.67417 16.3867 7.7625 16.875 10 16.875Z"
        fill={`url(#gradient04_${props.id})`}
      />

      <defs>
        <linearGradient
          id={`gradient01_${props.id}`}
          x1="17.5"
          y1="1.25"
          x2="4.73107"
          y2="12.0201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>

        <linearGradient
          id={`gradient02_${props.id}`}
          x1="17.5"
          y1="8.13086"
          x2="11.1845"
          y2="18.0368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>

        <linearGradient
          id={`gradient03_${props.id}`}
          x1="17.5"
          y1="11.2559"
          x2="11.1845"
          y2="21.1618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>

        <linearGradient
          id={`gradient04_${props.id}`}
          x1="17.5"
          y1="14.3809"
          x2="11.1845"
          y2="24.2868"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function UnlockIconOutline(props: IconProps) {
  return (
    <svg
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={clsx('fill-none', props.className)}
    >
      <g clipPath="url(#clip0_424_458)">
        <path
          d="M7.875 6.125V3.9375C7.875 3.24131 8.15156 2.57363 8.64384 2.08134C9.13613 1.58906 9.80381 1.3125 10.5 1.3125C11.1962 1.3125 11.8639 1.58906 12.3562 2.08134C12.8484 2.57363 13.125 3.24131 13.125 3.9375V6.125M2.1875 12.6875H8.3125C8.6606 12.6875 8.99444 12.5492 9.24058 12.3031C9.48672 12.0569 9.625 11.7231 9.625 11.375V7.4375C9.625 7.0894 9.48672 6.75556 9.24058 6.50942C8.99444 6.26328 8.6606 6.125 8.3125 6.125H2.1875C1.8394 6.125 1.50556 6.26328 1.25942 6.50942C1.01328 6.75556 0.875 7.0894 0.875 7.4375V11.375C0.875 11.7231 1.01328 12.0569 1.25942 12.3031C1.50556 12.5492 1.8394 12.6875 2.1875 12.6875Z"
          stroke={`url(#gradient01_${props.id})`}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id={`gradient01_${props.id}`}
          x1="13.125"
          y1="1.3125"
          x2="-1.24338"
          y2="8.38203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>
        <clipPath id="clip0_424_458">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function UnlockIconSolid(props: IconProps) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-none', props.className)}
    >
      <g clipPath="url(#clip0_425_462)">
        <path
          d="M10.5 0.875C12.1917 0.875 13.5625 2.24583 13.5625 3.9375V6.125C13.5625 6.24103 13.5164 6.35231 13.4344 6.43436C13.3523 6.51641 13.241 6.5625 13.125 6.5625C13.009 6.5625 12.8977 6.51641 12.8156 6.43436C12.7336 6.35231 12.6875 6.24103 12.6875 6.125V3.9375C12.6875 3.35734 12.457 2.80094 12.0468 2.3907C11.6366 1.98047 11.0802 1.75 10.5 1.75C9.91984 1.75 9.36344 1.98047 8.9532 2.3907C8.54297 2.80094 8.3125 3.35734 8.3125 3.9375V5.6875C8.77663 5.6875 9.22175 5.87187 9.54994 6.20006C9.87813 6.52825 10.0625 6.97337 10.0625 7.4375V11.375C10.0625 11.8391 9.87813 12.2842 9.54994 12.6124C9.22175 12.9406 8.77663 13.125 8.3125 13.125H2.1875C1.72337 13.125 1.27825 12.9406 0.950063 12.6124C0.621874 12.2842 0.4375 11.8391 0.4375 11.375V7.4375C0.4375 6.97337 0.621874 6.52825 0.950063 6.20006C1.27825 5.87187 1.72337 5.6875 2.1875 5.6875H7.4375V3.9375C7.4375 2.24583 8.80833 0.875 10.5 0.875Z"
          fill={`url(#gradient01_${props.id})`}
          stroke={`url(#gradient02_${props.id})`}
          strokeWidth="0.5"
        />
      </g>

      <defs>
        <linearGradient
          id={`gradient01_${props.id}`}
          x1="13.5625"
          y1="0.875"
          x2="-1.86279"
          y2="8.42583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#489CF0" />
          <stop offset="1" stopColor="#C44DFF" />
        </linearGradient>

        <linearGradient
          id={`gradient02_${props.id}`}
          x1="13.5625"
          y1="0.875"
          x2="-1.86279"
          y2="8.42583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(210, 85%, 60%)" />
          <stop offset="1" stopColor="hsl(280, 100%, 65%)" />
        </linearGradient>
        <clipPath id="clip0_425_462">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
