import classNames from 'classnames';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSidebarNavigationItems } from '@src/hooks/useSidebarNavigationItems';

interface JpMenuProps {
  onMenuClick?: () => void;
}

export const menuItemClass = [
  'flex flex-row justify-center lg:justify-start items-center',
  'rounded-5 lg:pl-6',
  'font-lexend font-medium text-sm text-grey-light-00',
  'hover:bg-blue-light-02 hover:text-blue-dark',
];

const JpMenu: React.FC<JpMenuProps> = (props: JpMenuProps) => {
  // Hooks
  const { navItems, activeNavItem } = useSidebarNavigationItems();

  return (
    <ul className="flex flex-col w-full gap-4">
      {navItems.map((item, index) => {
        return (
          <Link
            key={index}
            to={item.badge === 'comming_soon' ? '/' : item.href}
            className={clsx(
              item.href === activeNavItem?.href ? 'pointer-events-none' : null,
            )}
          >
            <li
              className={clsx(
                'group relative gap-3 h-12 md:h-7',
                ...menuItemClass,
                item.href === activeNavItem?.href ? 'bg-blue-light-02' : null,
              )}
              title={item.label}
              onClick={
                item.badge == 'comming_soon'
                  ? (e) => {
                      e.preventDefault();
                      toast.info('This feature is coming soon!');
                    }
                  : props.onMenuClick
              }
            >
              <item.icon className="h-5 w-5 hidden md:block" />

              <span className="block md:hidden lg:block">{item.label}</span>
              <div
                className={clsx(
                  'absolute h-7 left-12 top-0',
                  'm-0 p-0',
                  'leading-none whitespace-nowrap',
                  'pointer-events-none',
                  'hidden md:block lg:hidden',
                  'transform opacity-0 group-hover:opacity-100 transition-opacity duration-600',
                  menuItemClass,
                )}
              >
                <div
                  className={clsx(
                    'absolute top-2 right-0 -inset-1 rounded-5',
                    'bg-gradient-to-r from-blue-dark/0 to-blue-dark/25 blur-sm',
                  )}
                />

                <div
                  className={clsx(
                    'relative h-7 pr-5',
                    'bg-blue-light-02 group-hover:text-blue-dark',
                    menuItemClass,
                  )}
                >
                  {item.label}
                </div>
              </div>
              {item.badge == 'comming_soon' && (
                <span
                  className={classNames(
                    'absolute right-5 inline-flex items-center rounded-full  px-1.5 py-0.5 text-xxs ring-1 ring-inset ring-yellow-600/20',
                    'text-white bg-gradient-to-tr from-purple-light to-blue-dark',
                    'font-thin font-lexend',
                  )}
                >
                  Soon
                </span>
              )}
            </li>
          </Link>
        );
      })}
    </ul>
  );
};

export default JpMenu;
