import clsx from 'clsx';
import React, {
  useContext,
  type PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { Task_Code } from '@src/apollo/types/graphql';
import { JobViewProvider } from '@src/context/JobViewContext';
import { MyUserContext } from '@src/context/MyUserContext';
import { modalVisible as modalVisibleSelector } from '@src/store/reducers/onboarding';
import { currentTheme } from '@src/store/reducers/ui';
import { handleExtensionTask } from '@src/tasks/handleExtensionTask';

import JobsCompareWidget from './misc/JobsCompareWidget';
import { UserAccountNotVerified } from '../account/UserAccountNotVerified';
import JpSidebar from '../navbars/JpSidebar';
import JpTopbar from '../navbars/JpTopbar';
import OnboardingModal from '../onboarding/OnboardingModal';

const transitionClass = 'transition-all duration-100';

const TIME = 10000; // 10 secs

export const JpDashboardLayout: React.FC<PropsWithChildren> = (
  props: PropsWithChildren,
) => {
  // Hooks
  const currentAppliedTheme = useSelector(currentTheme);

  const { myUser, refetch } = useContext(MyUserContext);

  const modalVisible = useSelector(modalVisibleSelector);

  const extensionTask = useMemo(() => {
    return (
      myUser?.taskList.filter(
        (task) => task.taskCode === Task_Code.DownloadExtension,
      )[0] ?? null
    );
  }, [myUser?.taskList]);

  useEffect(() => {
    if (!extensionTask) return;

    const extensionTaskInterval = setInterval(
      async () => await handleExtensionTask(extensionTask, refetch),
      TIME,
    );

    return () => {
      clearInterval(extensionTaskInterval);
    };
  }, [extensionTask]);

  return (
    <JobViewProvider>
      <div
        className="flex flex-col md:flex-row h-screen bg-grey-light-02"
        data-theme={currentAppliedTheme}
      >
        <div
          className={clsx('hidden md:block md:w-24 lg:w-60', transitionClass)}
        >
          <JpSidebar />
        </div>

        <div className="block md:hidden w-full">
          <JpTopbar />
        </div>

        <div
          className={clsx(
            'relative flex-1 m-3 rounded-10',
            'bg-grey-light-03 border border-white',
            'text-primary-content shadow-lg',
          )}
        >
          {myUser && (
            <>
              <div className="h-full container">
                {!myUser.profile.emailVerified ? (
                  <UserAccountNotVerified />
                ) : (
                  props.children
                )}
              </div>

              {myUser.profile.emailVerified &&
                !myUser.isOnboarded &&
                modalVisible && (
                  <div
                    className={clsx(
                      'fixed z-20 md:absolute',
                      'top-0 left-0 rounded-10',
                      'flex justify-center',
                      'items-start md:items-center',
                      'p-5 md:pt-0',
                      'w-full h-full backdrop-blur-md',
                    )}
                  >
                    <OnboardingModal />
                  </div>
                )}
            </>
          )}

          <JobsCompareWidget />
        </div>
      </div>
    </JobViewProvider>
  );
};

export default JpDashboardLayout;
