import { useNavigate, useSearchParams } from 'react-router-dom';

export const useAuth = () => {
  // Hooks
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // Constants
  const AUTH_ENDPOINT = import.meta.env.VITE_AUTH_ENDPOINT;

  const loginWithGoogle = (): void => {
    const preChosenPlan = searchParams.get('plan');

    if (preChosenPlan) {
      window.location.href = `${AUTH_ENDPOINT}/google?plan=${preChosenPlan}`;

      return;
    }

    window.location.href = `${AUTH_ENDPOINT}/google`;
  };

  const loginWithLinkedin = (): void => {
    const preChosenPlan = searchParams.get('plan');

    if (preChosenPlan) {
      window.location.href = `${AUTH_ENDPOINT}/linkedin?plan=${preChosenPlan}`;

      return;
    }

    window.location.href = `${AUTH_ENDPOINT}/linkedin`;
  };

  const logout = (): void => {
    fetch(`${AUTH_ENDPOINT}/logout`, {
      method: 'POST',
      credentials: 'include',
    }).then(() => navigate('/login'));
  };

  return {
    loginWithGoogle,
    loginWithLinkedin,
    logout,
  };
};
