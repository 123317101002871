import React from 'react';

import { Job } from '@src/apollo/types/graphql';
import { JobViewContext } from '@src/context/JobViewContext';
import { getWeekdaysInMonth } from '@src/utils/date';
import { parseFloatWithDecimals } from '@src/utils/utilities';

import { LoadingPage } from './CreateNewJobAnimationPage';
import { SalaryRange } from './SalaryRange';

interface EditJobProps {
  job: Pick<
    Job,
    | 'salary'
    | 'hourlyRate'
    | 'salaryCurrency'
    | 'type'
    | 'location'
    | 'locationType'
    | 'industry'
    | 'id'
    | 'addedToFavourites'
    | 'knowledge'
    | 'title'
    | 'isManuallyCreated'
    | 'monthlyRate'
    | 'yearlyRate'
    | 'description'
    | 'externalUrl'
  > & { company: Pick<Job['company'], 'logo' | 'name'> };
  closeModal: () => void;
  refetch: () => void;
}

export const EditJobPage: React.FC<EditJobProps> = ({
  job,
  closeModal,
  refetch,
}: EditJobProps) => {
  // Contexts
  const { createNewJob, loading } = React.useContext(JobViewContext);

  // States
  const [salaryRanges, setSalaryRanges] = React.useState<{
    hourlyRate: number;
    hourlyChanged: boolean;
    monthlyRate: number;
    monthlyChanged: boolean;
    yearlyRate: number;
    yearlyChanged: boolean;
  }>({
    hourlyRate: job.hourlyRate ?? 0,
    monthlyRate: job.monthlyRate ?? 0,
    yearlyRate: job.yearlyRate ?? 0,
    hourlyChanged: false,
    monthlyChanged: false,
    yearlyChanged: false,
  });

  const {
    hourlyRate: calculatedHourlyRate,
    monthlyRate: calculatedMonthlyRate,
    yearlyRate: calculatedYearlyRate,
  } = React.useMemo(() => {
    const today = new Date();
    const weekdays = getWeekdaysInMonth(today.getMonth(), today.getFullYear());

    let hourlyCalculate = salaryRanges.hourlyRate;
    let monthlyCalculate = salaryRanges.monthlyRate;
    let yearlyCalculate = salaryRanges.yearlyRate;

    if (salaryRanges.hourlyChanged) {
      monthlyCalculate = parseFloatWithDecimals(
        salaryRanges.hourlyRate * weekdays * 8,
      );
      yearlyCalculate = parseFloatWithDecimals(monthlyCalculate * 12, 2);
    } else if (salaryRanges.monthlyChanged) {
      hourlyCalculate = parseFloatWithDecimals(
        salaryRanges.monthlyRate / (weekdays * 8),
      );
      yearlyCalculate = parseFloatWithDecimals(
        salaryRanges.monthlyRate * 12,
        2,
      );
    } else if (salaryRanges.yearlyChanged) {
      monthlyCalculate = parseFloatWithDecimals(salaryRanges.yearlyRate / 12);
      hourlyCalculate = parseFloatWithDecimals(
        monthlyCalculate / (weekdays * 8),
      );
    }

    return {
      hourlyRate: hourlyCalculate,
      monthlyRate: monthlyCalculate,
      yearlyRate: yearlyCalculate,
    };
  }, [
    salaryRanges.hourlyRate,
    salaryRanges.monthlyRate,
    salaryRanges.yearlyRate,
  ]);

  const handleFormSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const jobTitle: string = (ev.target as HTMLFormElement)['jobTitle'].value;
    const companyName: string = (ev.target as HTMLFormElement)['companyName']
      .value;
    const jobDescription: string = (ev.target as HTMLFormElement)[
      'jobDescription'
    ].value;
    const url = job.externalUrl;
    const html = '';
    const jobType: string = (ev.target as HTMLFormElement)['jobType'].value;
    const jobLocation: string = (ev.target as HTMLFormElement)['jobLocation']
      .value;

    createNewJob({
      jobTitle,
      companyName,
      jobDescription,
      url,
      html,
      jobType,
      jobLocation,
      jobSalary: String(salaryRanges.yearlyRate),
      yearlyRate: calculatedYearlyRate,
      hourlyRate: calculatedHourlyRate,
      monthlyRate: calculatedMonthlyRate,
    });
    refetch();
    closeModal();
  };

  return (
    <>
      {!loading ? (
        <form
          onSubmit={(ev) => handleFormSubmit(ev)}
          className="flex flex-col p-4 border rounded "
        >
          <label
            htmlFor="jobTitle"
            className="lexend font-medium text-sm text-start text-grey-light"
          >
            Job Title
          </label>
          <input
            id="jobTitle"
            name="jobTitle"
            className="text-xs border-2 rounded-md p-2 mb-2"
            defaultValue={job.title ?? ''}
            required
          />

          <label
            htmlFor="companyName"
            className="lexend font-medium text-sm text-start text-grey-light"
          >
            Company Name
          </label>
          <input
            id="companyName"
            name="companyName"
            className="text-xs border-2 rounded-md p-2 mb-2"
            defaultValue={job.company.name ?? ''}
            required
          />
          <label
            htmlFor="jobLocation"
            className="lexend font-medium text-sm text-start text-grey-light"
          >
            Location
          </label>
          <input
            id="jobLocation"
            name="jobLocation"
            className="text-xs border-2 rounded-md p-2 mb-2"
            defaultValue={job.location ?? ''}
            required
          />
          <SalaryRange
            labelValue="Yearly Salary"
            maxRange={500000}
            step={0.01}
            salary={calculatedYearlyRate}
            salaryType="yearlySalary"
            setValue={(value) => {
              setSalaryRanges((prev) => ({
                ...prev,
                yearlyRate: value,
                monthlyChanged: false,
                yearlyChanged: true,
                hourlyChanged: false,
              }));
            }}
          />
          <SalaryRange
            labelValue="Monthly Salary"
            maxRange={41666.667}
            step={0.001}
            salary={calculatedMonthlyRate}
            salaryType="monthlySalary"
            setValue={(value) => {
              setSalaryRanges((prev) => ({
                ...prev,
                monthlyRate: value,
                monthlyChanged: true,
                yearlyChanged: false,
                hourlyChanged: false,
              }));
            }}
          />
          <SalaryRange
            labelValue="Hourly Salary"
            maxRange={236.742}
            step={0.001}
            salary={calculatedHourlyRate}
            salaryType="hourlySalary"
            setValue={(value) => {
              setSalaryRanges((prev) => ({
                ...prev,
                hourlyRate: value,
                monthlyChanged: false,
                yearlyChanged: false,
                hourlyChanged: true,
              }));
            }}
          />

          <label
            htmlFor="jobType"
            className="lexend font-medium text-sm text-start text-grey-light"
          >
            Employment Type
          </label>
          <select
            id="jobType"
            name="jobType"
            className="text-xs border-2 rounded-md p-2 mb-2"
            defaultValue={job.type ?? 'Full-Time'}
          >
            <option value="Full-Time">Full-Time</option>
            <option value="Part-Time">Part-time</option>
          </select>

          <label
            htmlFor="jobDescription"
            className="lexend font-medium text-start text-sm text-grey-light"
          >
            Job Description
          </label>
          <textarea
            id="jobDescription"
            name="jobDescription"
            className="border-2 text-xs max-h-52 h-52 resize-none rounded-md overflow-y-scroll p-2"
            defaultValue={job.description ?? ''}
            required
          />
          <button
            type="submit"
            className="flex justify-center items-center lexend font-medium bg-blue-400 rounded-lg text-white w-auto p-2 mt-3"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};
