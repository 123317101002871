import axios, { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LocalChangePasswordError } from '@src/apollo/types/graphql';
import JpButton from '@src/components/core/JpButton';
import { Logo } from '@src/components/misc/JpLogo';
import { SalientTextField } from '@src/components/theme/salient/SalientTextField';
import { LoadingIcon } from '@src/images/SvgIcons';

export const ResetPassword = () => {
  // State
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

  const [requestInProgress, setRequestInProgress] = React.useState(false);

  // Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  // Effects
  React.useEffect(() => {
    if (!searchParams.has('token')) {
      navigate('/forgot-password');
    }
  }, [searchParams, navigate]);

  // Methods

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (requestInProgress) {
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast('The new passwords do not match', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });

      return;
    }

    setRequestInProgress(true);

    try {
      await axios.post(
        `${import.meta.env.VITE_AUTH_ENDPOINT}/local/change-password`,
        {
          token: searchParams.get('token'),
          newPassword,
        },
      );

      handleSubmitSuccess();
    } catch (error) {
      const authenticationError = (
        (error as AxiosError).response?.data as Record<string, string>
      )?.error;

      if (typeof authenticationError === 'string') {
        handleSubmitError(authenticationError);
      }
    }

    setRequestInProgress(false);
  };

  const handleSubmitError = (loginError: string) => {
    let notificationMessage = '';

    switch (loginError) {
      case LocalChangePasswordError.NewPassowrdTooWeak: {
        notificationMessage = t(
          'views.resetPassword.notifications.errors.newPasswordTooWeak',
        );
        break;
      }
      default: {
        notificationMessage = t(
          'views.resetPassword.notifications.errors.unknownError',
        );
      }
    }

    toast(notificationMessage, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: 'error',
    });
  };

  const handleSubmitSuccess = () => {
    const notificationMessage = t('views.resetPassword.notifications.success');
    const notificationTime = 2000;

    toast(notificationMessage, {
      position: 'top-right',
      autoClose: notificationTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      type: 'success',
    });

    setTimeout(() => {
      navigate('/login');
    }, notificationTime);
  };

  return (
    <>
      <div className="flex flex-col mb-7">
        <Link to="/login" aria-label="Logo">
          <Logo className="h-10 w-auto" />
        </Link>
        <div className="mt-20">
          <h2 className="text-lg font-semibold text-gray-900">
            {t('views.resetPassword.title')}
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            {t('views.resetPassword.subtitle')}{' '}
            <Link
              to="/register"
              className="font-medium text-blue-600 hover:underline"
            >
              {t('views.resetPassword.subtitleLink')}
            </Link>{' '}
          </p>
        </div>
      </div>
      <form
        action={`${import.meta.env.VITE_AUTH_ENDPOINT}/local/login`}
        method="POST"
        className="mt-10 grid grid-cols-1 gap-y-8"
      >
        <SalientTextField
          label={t('views.resetPassword.form.newPassword')}
          id="new-password"
          name="newPassword"
          type="password"
          autoComplete="current-password"
          required
          onChange={handleNewPasswordChange}
        />

        <SalientTextField
          label={t('views.resetPassword.form.newPasswordConfirm')}
          id="confirm-new-password"
          name="confirmNewPassword"
          type="password"
          autoComplete="current-password"
          required
          onChange={handleConfirmNewPasswordChange}
        />

        <JpButton
          type="submit"
          variant="solid"
          color="blue"
          className="w-full rounded-md bg-blue-dark px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
        >
          {requestInProgress && <LoadingIcon />}

          <span>
            {t('views.resetPassword.form.submitButton')}{' '}
            <span aria-hidden="true">&rarr;</span>
          </span>
        </JpButton>
      </form>
    </>
  );
};
