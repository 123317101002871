import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface JpWaitingSpinnerProps {
  displayLoadingText?: boolean;
}

export const JpWaitingSpinner: React.FC<JpWaitingSpinnerProps> = ({
  displayLoadingText = false,
}: JpWaitingSpinnerProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center text-center w-full h-full text-secondary-content text-blue-dark">
      <ArrowPathIcon
          className={`animate-spin text-blue-dark w-7 h-7 bg-base-100`}
        />

      <span
        className={`text-secondary-content/75 text-xs mt-2 animate-pulse ${
          displayLoadingText ? null : 'hidden'
        }`}
      >
        {t('loadingMessage')}
      </span>
    </div>
  );
};
