import clsx from 'clsx';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  NotificationKind,
  useMyNotificationsLazyQuery,
  useNewNotificationSubscription,
} from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';
import { SubscriptionsContext } from '@src/context/SubscriptionsContext';
import { useNotifications } from '@src/hooks/useNotifications';
import DefaultAvatar from '@src/images/DefaultAvatar.svg';

import { ResumeSelector } from '../profile/ResumeSelector';

interface JpUserInfoProps {
  onUserInfoClick?: () => void;
}

const transitionClass = 'transition-all duration-100';

export default function JpUserInfo(props: JpUserInfoProps) {
  const subscriptionContext = useContext(SubscriptionsContext);

  // Hooks

  const {
    getNotificationSeverity,
    getNotificationMessage,
    getNotificationTheme,
  } = useNotifications();

  const userContext = useContext(MyUserContext);

  // Queries

  const myNotificationsQuery = useMyNotificationsLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Subscription

  useNewNotificationSubscription({
    onData: (result) => {
      const newNotification = result.data.data?.newNotification;

      const notificationSeverity = getNotificationSeverity(
        newNotification?.severity,
      );
      const notificationMessage = getNotificationMessage(
        newNotification?.notification,
      );
      const notificationTheme = getNotificationTheme();

      toast(notificationMessage, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        type: notificationSeverity,
        theme: notificationTheme,
      });

      if (newNotification?.notification === NotificationKind.AccountActivated) {
        userContext.refetch();
      }

      myNotificationsQuery[0]();
    },
  });

  // Methods

  const getUserProfilePicture = () => {
    // The avatar that the user set
    const avatar = userContext.myUser?.profile.avatar;
    if (avatar) {
      return avatar.url;
    }

    // The photos (optional) received from SSO
    const photos = userContext.myUser?.profile.photos;
    if (photos && photos.length > 0) {
      return photos[0];
    }

    // Default (if no photo is saved for user)
    return DefaultAvatar;
  };

  return (
    <div
      className={clsx(
        'w-full group flex flex-col items-center',
        'text-grey-dark font-lexend',
      )}
    >
      <span className="relative inline-block mb-4 text-xs">
        <Link to="/dashboard/profile" onClick={props.onUserInfoClick}>
          <img
            className={clsx(
              'inline-block h-24 w-24 md:h-14 md:w-14 lg:h-24 lg:w-24 rounded-full',
              'shadow-lg shadow-grey-dark/25',
              'group-hover:outline outline-2 outline-offset-4 outline-blue-dark',
              transitionClass,
            )}
            src={getUserProfilePicture()}
            alt="profile-pic"
          />
        </Link>
        <span
          className={clsx(
            'absolute left-1/2 transform -translate-x-1/2 -bottom-3 block rounded-full py-1 px-3',
            'text-xxs uppercase text-white font-bold leading-tight',
            'border border-white/75',
            subscriptionContext.isPro
              ? 'bg-gradient-to-tr from-purple-light to-blue-dark'
              : 'bg-grey-light-00',
            'block md:hidden lg:block',
          )}
        >
          {subscriptionContext.isPro ? 'Premium' : 'Free'}
        </span>
      </span>

      <div className={clsx('w-full items-center flex flex-col')}>
        <p className={clsx('text-sm', 'block md:hidden lg:block')}>
          {!!userContext.myUser?.profile.firstName &&
          !!userContext.myUser?.profile.lastName
            ? `${userContext.myUser?.profile.firstName ?? 'User'} ${
                userContext.myUser?.profile.lastName ?? ''
              }`
            : userContext.myUser?.profile.primaryEmail || 'User'}
        </p>
        <p
          className={clsx(
            'font-inter text-xs text-grey-light-00',
            'block md:hidden lg:block',
          )}
        >
          {userContext.myUser?.profile.profession ?? 'Profession not set'}
        </p>
        {userContext.myUser?.profile.emailVerified === true ? (
          <ResumeSelector />
        ) : null}
      </div>

      {userContext.myUser?.profile.emailVerified === false ? (
        <div
          className={clsx(
            'w-2/3 md:w-full lg:w-2/3 m-2 p-1 rounded-md',
            'text-white text-center text-xxs font-lexend font-medium leading-tight',
            'bg-blue-dark',
          )}
        >
          Please activate your account via email
        </div>
      ) : null}
    </div>
  );
}
