export const roMessages = {
  views: {
    home: {
      title: 'Acasă',
    },
    aiChat: {
      title: 'Chat AI',
    },
    explore: {
      title: 'Explorează',
      sortOptions: {
        createdAt: 'Data',
        title: 'Titlu',
        salary: 'Salariu',
        unknown: 'Necunoscut',
      },
      infiniteScroll: {
        endMessage: 'Bravo! Ai văzut totul',
      },
      jobsList: {
        noJobs: 'Nu s-au găsit locuri de muncă',
      },
      jobSingle: {
        selectAJob:
          'Selectează un loc de muncă pentru a vedea informații detaliate',
      },
    },
    history: {
      title: 'Istoric',
      sortOptions: {
        createdAt: 'Data',
        title: 'Titlu',
        salary: 'Salariu',
        unknown: 'Necunoscut',
      },
      infiniteScroll: {
        endMessage: 'Bravo! Ai văzut totul',
      },
      jobsList: {
        noJobs: 'Nu s-au găsit locuri de muncă',
      },
      jobSingle: {
        selectAJob:
          'Selectează un loc de muncă pentru a vedea informații detaliate',
      },
    },
    bookmarks: {
      title: 'Marcaje',
      sortOptions: {
        createdAt: 'Data',
        title: 'Titlu',
        salary: 'Salariu',
        unknown: 'Necunoscut',
      },
      infiniteScroll: {
        endMessage: 'Bravo! Ai văzut totul',
      },
      jobsList: {
        noJobs: 'Nu s-au găsit locuri de muncă',
      },
      jobSingle: {
        selectAJob:
          'Selectează un loc de muncă pentru a vedea informații detaliate',
      },
    },
    profile: {
      title: 'Profil',
      joinDate: {
        unknown: 'Înregistrat la: NECUNOSCUT',
        thisWeek: 'Înregistrat săptămâna aceasta',
        lastWeek: 'Înregistrat săptămâna trecută',
        weeksAgo: 'Înregistrat acum {{weeks}} săptămâni',
      },
      tabs: {
        personalInformation: 'Informații personale',
        security: 'Securitate',
        resume: 'CV',
      },
      errors: {
        unknown: 'A apărut o eroare neașteptată. Încearcă mai târziu.',
        avatarUpdateFailed:
          'Eroare la actualizarea avatarului. Încearcă mai târziu',
        personalInformationUpdateFailed:
          'A apărut o eroare neașteptată la actualizarea informațiilor personale. Încearcă mai târziu.',
        newPasswordsDoNotMatch:
          'Parola nouă și confirmarea parolei nu se potrivesc.',
        invalidOldPassword: 'Parola veche furnizată este invalidă.',
        invalidNewPassword: 'Parola nouă furnizată este invalidă.',
      },
      success: {
        avatarUpdated: 'Avatarul tău a fost actualizat cu succes!',
        personalInformationUpdated:
          'Informațiile tale personale au fost actualizate cu succes!',
        passwordUpdated: 'Parola ta a fost actualizată cu succes!',
      },
      form: {
        firstName: 'Prenume',
        lastName: 'Nume',
        profession: 'Profesia',
        currentPassword: 'Parola actuală',
        newPassword: 'Parola nouă',
        newPasswordConfirm: 'Confirmă parola nouă',
        submitButton: 'Salvează',
        downloadCurrentResume: 'Descarcă CV-ul actual',
      },
      yourSkills: 'Skill-urile tale',
      signOut: 'Deconectare',
    },
    login: {
      title: 'Conectează-te la contul tău',
      subtitle: 'Nu ai un cont încă?',
      subtitleLink: 'Înregistrează-te pentru un trial gratuit.',
      form: {
        email: 'Adresă de email',
        password: 'Parolă',
        submitButton: 'Conectează-te',
      },
      forgotPassword: 'Ai uitat parola?',
      resetYourPassword: 'Resetează-ți parola aici.',
      or: 'sau',
      loginWithGoogleButton: 'Conectează-te cu Google',
      loginWithLinkedinButton: 'Conectează-te cu LinkedIn',
      notifications: {
        errors: {
          invalidCredentials:
            'Datele de autentificare furnizate sunt invalide. Verifică numele de utilizator și parola și încearcă din nou.',
          unknownError: 'Ceva nu a mers bine. Încearcă din nou mai târziu.',
        },
        success:
          'Te-ai conectat cu succes. Vei fi redirecționat către panoul tău de control în câteva secunde.',
      },
    },
    register: {
      title: 'Înregistrează-ți contul',
      subtitle: 'Ai deja un cont?',
      subtitleLink: 'Conectează-te la panoul tău de control.',
      form: {
        email: 'Adresă de email',
        password: 'Parolă',
        submitButton: 'Înregistrează-te',
      },
      or: 'sau',
      registerWithGoogleButton: 'Înregistrează-te cu Google',
      registerWithLinkedinButton: 'Înregistrează-te cu LinkedIn',
      notifications: {
        errors: {
          invalidEmail: 'Adresa de email furnizată este invalidă.',
          emailAlreadyExists: 'Există deja un cont cu această adresă de email.',
          weakPassword: 'Parola furnizată este prea slabă.',
          unknownError: 'Ceva nu a mers bine. Încearcă din nou mai târziu.',
        },
        success:
          'Te-ai înregistrat cu succes. Vei fi redirecționat către panoul tău de control în câteva secunde.',
      },
      userAgreement: 'Am citit și sunt de acord cu ',
      and: ' și ',
      privacyPolicy: 'Politica de Confidențialitate',
      termsAndConditions: 'Termeni și Condiții',
      close: 'Închide',
    },
    forgotPassword: {
      title: 'Resetează-ți parola',
      subtitle: 'Nu ai un cont?',
      subtitleLink: 'Înregistrează-te pentru un trial gratuit.',
      form: {
        email: 'Adresă de email',
        submitButton: 'Resetează',
      },
      alreadyRequestedPasswordReset: 'Ai cerut deja resetarea parolei.',
      waitFor:
        'Te rugăm să aștepți {{minutes}} minute înainte de a încerca din nou.',
      timeLeft: 'Timp rămas: {{seconds}} secunde.',
      notifications: {
        error:
          'Ceva nu a mers bine. Nu am putut reseta parola ta. Încearcă din nou mai târziu.',
        success:
          'Linkul de resetare a parolei a fost trimis cu succes la adresa ta de email! Verifică-ți inboxul.',
      },
    },
    resetPassword: {
      title: 'Resetează-ți parola',
      subtitle: 'Nu ai un cont?',
      subtitleLink: 'Înregistrează-te pentru un trial gratuit.',
      form: {
        oldPassword: 'Parola veche',
        newPassword: 'Parola nouă',
        newPasswordConfirm: 'Confirmă parola nouă',
        submitButton: 'Resetează',
      },
      notifications: {
        errors: {
          invalidOldPassword: 'Parola veche furnizată este invalidă.',
          newPasswordTooWeak: 'Parola furnizată este prea slabă.',
          unknownError: 'Ceva nu a mers bine. Încearcă din nou mai târziu.',
        },
        success:
          'Parola ta a fost schimbată cu succes! Vei fi redirecționat către pagina de conectare în curând.',
      },
    },
    forYou: {
      title: 'Pentru tine',
    },
  },
  subscriptionPlans: {
    free: {
      title: 'Plan de bază',
      price: 'GRATUIT',
      description: 'Bun pentru oricine nu este angajat și abia începe.',
      features: {
        compatibilities: 'Compatibilități de locuri de muncă / zi: 5',
        compatibilityCalculation: 'Calculul compatibilității: MANUAL',
        historyTracking: 'Urmărire istoric locuri de muncă: ULTIMA SĂPTĂMÂNĂ',
        bookmarks: 'Marcaje: NELIMITATE',
      },
    },
    advanced: {
      title: 'Plan avansat',
      price: '$25',
      description:
        'Bun pentru oricine nu este angajat și caută funcții avansate.',
      features: {
        compatibilities: 'Compatibilități de locuri de muncă / zi: 50',
        compatibilityCalculation: 'Calculul compatibilității: MANUAL',
        historyTracking: 'Urmărire istoric locuri de muncă: ULTIMA LUNĂ',
        jobsRecommandation: 'Recomandare locuri de muncă cu rating înalt',
        resumeAnalisys: 'Analiză CV',
        bookmarks: 'Marcaje: NELIMITATE',
      },
    },
    premium: {
      title: 'Plan premium',
      price: '$50',
      description:
        'Bun pentru oricine nu este angajat și caută funcții premium.',
      features: {
        compatibilities: 'Compatibilități de locuri de muncă / zi: NELIMITATE',
        compatibilityCalculation: 'Calculul compatibilității: AUTOMAT',
        historyTracking: 'Urmărire istoric locuri de muncă: NELIMITAT',
        jobsRecommandation: 'Recomandare locuri de muncă cu rating înalt',
        companyInsights: 'Insight-uri companie',
        advancedAnalytics: 'Analiză avansată',
        resumeAnalisys: 'Analiză CV',
        bookmarks: 'Marcaje: NELIMITATE',
      },
    },
    checkout: {
      subscriptionCanceled: 'Abonamentul tău a fost anulat.',
      subscriptionCancelledSubtitle:
        'Poți să folosești abonamentul până la sfârșitul perioadei de facturare.',
      paymentSuccess: 'Plata ta a fost finalizată cu succes.',
    },
    upgrade: {
      title: 'Upgradează-ți abonamentul',
      subtitle: 'Selectează un plan pentru a-ți upgrada abonamentul',
    },
    unpaid: {
      title: 'Abonamentul tău nu a fost plătit încă',
      subtitle:
        'Pentru a continua, trebuie să plătești abonamentul sau să selectezi un plan gratuit.',
    },
    active: {
      title: 'Abonamentul tău este activ',
      subtitle: 'Va fi reînnoit automat la {{date}}',
    },
    canceled: {
      title: 'Abonamentul tău a fost anulat',
      subtitle:
        'A fost anulat la {{cancelDate}} și abonamentul tău va trece automat la planul gratuit la {{planDateEnd}}',
    },
    buttons: {
      paySubscription: 'Plătește abonamentul',
      currentPlan: 'Planul curent',
      getStarted: 'Începe',
    },
  },
  preferences: {
    mainTitle: 'Preferințe',
    languageLabel: 'Limba',
    themeLabel: 'Modul întunecat',
  },
  feedback: {
    buttonLabel: 'Oferă feedback',
    mainTitle: 'Împărtășește-ți părerea',
    placeholderMessage: 'Spune-ne despre experiența ta ...',
    submitButtonLabel: 'Trimite feedback',
  },
  notifications: {
    inboxLabel: 'Inbox',
    archiveLabel: 'Arhivă',
    youHaveLabel: 'Ai ',
    notificationCount_one: '{{count}} notificare',
    notificationCount_few: '{{count}} notificări',
    notificationCount_many: '{{count}} de notificări',
    notificationCount_other: '{{count}} de notificări',
    noNotifications: 'Nu aveti nici o notificare',
    message: {
      accountActivated: 'Contul tău a fost activat cu succes.',
    },
  },
  compatibilities: {
    youHaveLabel: 'Aveti ',
    compatibilityCount_one: '{{count}} calcul de compatibilitate rămas.',
    compatibilityCount_few: '{{count}} calcule de compatibilitate rămase.',
    compatibilityCount_many: '{{count}} de calcule de compatibilitate rămase.',
    compatibilityCount_other: '{{count}} de calcule de compatibilitate rămase.',
    noCompatibilitiesLeft:
      'Nu mai sunt calcule de compatibilitate disponibile.',
    calculateCompatibility: 'Calculează compatibilitatea',
    cannotCalculateCompatibility: 'Nu se poate calcula compatibilitatea',
    cannotCalculateCompatibilityReason:
      'Nu se poate calcula compatibilitatea pentru un loc de muncă dacă nu aveți un CV încărcat.',
    notifications: {
      dailyLimitReached:
        'Ați atins limita zilnică de calcule de compatibilitate. Vă rugăm să faceți upgrade la planul dvs. pentru a continua.',
    },
  },
  accountNotVerified: {
    title: 'Contul tău nu este verificat',
    subtitle:
      'Vă rugăm să verificați contul dvs. făcând clic pe link-ul pe care l-am trimis la adresa dvs. de e-mail.',
    refresh: 'Reîmprospătați',
    logout: 'Deconectare',
  },
  loadingMessage: 'ÎNCĂRCARE',
  errorMessage: 'A apărut o eroare. Vă rugăm să încercați mai târziu.',
  webStore: {
    availableIn: 'Disponibil în',
    storeName: 'Magazinul web Chrome',
  },
  uploadDropzone: {
    uploadFile: 'Încărcați un fișier',
    avatarDropText:
      ' sau pur și simplu trageți și plasați fotografia aici pentru a începe încărcarea.',
    resumeDropText:
      ' sau pur și simplu trageți și plasați documentul aici pentru a începe încărcarea.',
    avatarSupportedFormat: 'Formate acceptate: PNG, JPG, GIF',
    resumeSupportedFormat: 'Format acceptat: PDF',
    maximumSize: 'Dimensiune maximă: 25 MB',
    errorMessage:
      'Eroare la încărcare: Vă rugăm să încărcați doar un singur fișier.',
  },
  onboarding: {
    buttons: {
      continueLabel: 'Continuă',
      finishLabel: 'Finalizează',
      skipLabel: 'Omite pentru moment',
    },
    steps: {
      profileStep: {
        title: 'Creează-ți profilul',
        description:
          'Bine ați venit la Jobpedia.AI! Să începem prin configurarea profilului. Furnizați numele, profesia și adăugați o poză de profil.',
      },
      resumeStep: {
        title: 'Încărcați CV-ul Dvs.',
        description:
          'Excelent, sunteți un pas mai aproape de găsirea locului de muncă al visurilor dumneavoastră. Încărcați CV-ul și vom face potriviri relevante.',
      },
      extensionStep: {
        title: 'Verificare extensie pentru browser',
        description:
          'Să ne asigurăm că aveți cea mai bună experiență. Verificăm extensia Jobpedia pentru a îmbunătăți căutarea de locuri de muncă.',
        extensionInstalled: {
          title: 'Extensia Chrome instalată',
          description:
            'Totul e pregătit! Extensia Chrome necesară este deja instalată în browserul dumneavoastră. Puteți bucura de toate funcționalitățile fără nicio problemă.',
        },
        extensionRequired: {
          title: 'Extensia Chrome este necesară',
          description:
            'Pentru a utiliza toate funcțiile serviciului nostru, trebuie să instalați extensia noastră Chrome. Instalarea este rapidă și simplă!',
        },
      },
      exploreStep: {
        title: 'Felicitări!',
        description:
          'Suntem încântați să vă avem la bord și să vă ajutăm să descoperiți oportunități care se potrivesc abilităților și aspirațiilor dumneavoastră.',
        content: {
          title: 'Simțiți entuziasmul în aer?',
          text: 'Noi simțim la fel! Apăsați butonul "Finalizează" de mai jos și să înceapă aventura. Job-ul visat este la doar un clic distanță. Explorare fericită!',
        },
      },
    },
    messages: {
      onboardDoneSuccess:
        'Felicitări! Onboarding-ul este complet, bun venit la bord!',
      onboardDoneFail:
        'Hopa, onboarding-ul a eșuat. Vă rugăm să încercați din nou mai târziu.',
    },
  },
};
