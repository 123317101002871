import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useContext } from 'react';

import { Task_Code } from '@src/apollo/types/graphql';
import WebStoreLink from '@src/components/misc/WebStoreLink';
import { MyUserContext } from '@src/context/MyUserContext';
import { useBrowserDetect } from '@src/hooks/useBrowserDetect';

const ExtensionStep: React.FC = () => {
  const { isChrome, isFirefox } = useBrowserDetect();

  const { myUser } = useContext(MyUserContext);

  const extensionTask = myUser?.taskList.find(
    (task) => task.taskCode === Task_Code.DownloadExtension,
  );

  const extensionInstalled = extensionTask?.isSolved;

  return (
    <div
      className={clsx(
        'flex flex-col p-0 gap-8 justify-center items-center h-full col-span-full',
      )}
    >
      {extensionInstalled ? (
        <>
          <div className="flex flex-col items-center">
            <CheckCircleIcon className="w-14 h-14 fill-green-600" />

            <h1 className="font-lexend text-2xl font-semibold leading-none">
              Browser Extension Installed
            </h1>
          </div>

          <span className="font-inter text-center leading-tight">
            You're all set! The required browser extension is already installed
            on your browser. You can enjoy all the features and functionality
            without any issues.
          </span>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center">
            <ExclamationTriangleIcon className="w-14 h-14 fill-red-600" />

            <h1 className="font-lexend text-2xl font-semibold leading-none">
              Browser Extension Required
            </h1>
          </div>

          <span className="font-inter text-center leading-tight">
            To use all the features of our service, you need to install our
            browser extension. Installation is quick and easy!
          </span>

          <WebStoreLink isFirefox={isFirefox} isChrome={isChrome} />
        </>
      )}
    </div>
  );
};

export default ExtensionStep;
