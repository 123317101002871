import {
  SolveTaskDocument,
  Task,
  TaskCompletionStatus,
} from '@src/apollo/types/graphql';

import { client } from '../apollo/apollo-client';

export const handleExtensionTask = async (
  extensionTask: Pick<Task, 'isSolved'>,
  refetch: () => void,
): Promise<void> => {

  const isHere = document
    .querySelector<HTMLElement>('plasmo-csui')
    ?.shadowRoot?.querySelector<HTMLElement>('#jp-root');

  if (extensionTask.isSolved !== Boolean(isHere)) {
    await client.mutate({
      mutation: SolveTaskDocument,
      variables: {
        solveTaskInput: {
          taskCompletionStatus: isHere
            ? TaskCompletionStatus.ExtensionInstalled
            : TaskCompletionStatus.ExtensionIsNotInstalled,
        },
      },
    }).then(() => {
      refetch();
    });

    refetch();
  }
};
