import * as Sentry from '@sentry/react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import JpAuthLayout from '@src/components/layouts/JpAuthLayout';
import JpDashboardLayout from '@src/components/layouts/JpDashboardLayout';
import { MyUserProvider } from '@src/context/MyUserContext';
import { Applications } from '@src/views/applications/Applications';
import { Bookmarks } from '@src/views/bookmarks/Bookmarks';
import { Chat } from '@src/views/chat/Chat';
import { Dashboard } from '@src/views/dashboard/Dashboard';
import { Explore } from '@src/views/explore/Explore';
import { Featured } from '@src/views/featured/Featured';
import { ForgotPassword } from '@src/views/forgot-password/ForgotPassword';
import { History } from '@src/views/history/History';
import { Login } from '@src/views/login/Login';
import { Page404 } from '@src/views/not-found/Page404';
import { Profile } from '@src/views/profile/Profile';
import { Register } from '@src/views/register/Register';
import { ResetPassword } from '@src/views/reset-password/ResetPassword';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
  },
  {
    path: '/*',
    element: <Page404 />,
  },
  {
    path: '/login',
    element: (
      <JpAuthLayout>
        <Login />
      </JpAuthLayout>
    ),
  },
  {
    path: '/register',
    element: (
      <JpAuthLayout>
        <Register />
      </JpAuthLayout>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <JpAuthLayout>
        <ResetPassword />
      </JpAuthLayout>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <JpAuthLayout>
        <ForgotPassword />
      </JpAuthLayout>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Dashboard />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/explore',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Explore />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/featured',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Featured />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/history',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <History />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/bookmarks',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Bookmarks />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/chat',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Chat />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/profile',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Profile />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
  {
    path: '/dashboard/applications',
    element: (
      <MyUserProvider>
        <JpDashboardLayout>
          <Applications />
        </JpDashboardLayout>
      </MyUserProvider>
    ),
  },
]);
