import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  useUpdateMyPersonalInformationMutation,
  useUserOnboardDoneMutation,
} from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';
import {
  userProfile as userProfileSelector,
  setVisibility,
} from '@src/store/reducers/onboarding';

import ExploreStep from './misc/ExploreStep';
import ExtensionStep from './misc/ExtensionStep';
import ProfileStep from './misc/ProfileStep';
import ResumeStep from './misc/ResumeStep';

type StepDirection = 'back' | 'forward';

const OnboardingModal: React.FC = () => {
  // ⁡⁢⁣⁣𝗛𝗼𝗼𝗸𝘀⁡

  const { myUser } = useContext(MyUserContext);

  const { t } = useTranslation();

  const userProfile = useSelector(userProfileSelector);

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(
    parseInt(localStorage.getItem('onboardingStep') ?? '0', 10),
  );

  const [updateMyPersonalInformationMutation] =
    useUpdateMyPersonalInformationMutation();

  const [userOnboardDoneMutation] = useUserOnboardDoneMutation();

  // ⁡⁢⁣⁣𝗖𝗼𝗻𝘀𝘁𝗮𝗻𝘁𝘀⁡

  const onboardingStepList = [
    {
      id: 0,
      title: t('onboarding.steps.profileStep.title'),
      description: t('onboarding.steps.profileStep.description'),
      nextButtonLabel: t('onboarding.buttons.continueLabel'),
    },
    {
      id: 1,
      title: t('onboarding.steps.resumeStep.title'),
      description: t('onboarding.steps.resumeStep.description'),
      nextButtonLabel: t('onboarding.buttons.continueLabel'),
    },
    {
      id: 2,
      title: t('onboarding.steps.extensionStep.title'),
      description: t('onboarding.steps.extensionStep.description'),
      nextButtonLabel: t('onboarding.buttons.continueLabel'),
    },
    {
      id: 3,
      title: t('onboarding.steps.exploreStep.title') + ' 🎉',
      description: t('onboarding.steps.exploreStep.description'),
      nextButtonLabel: t('onboarding.buttons.finishLabel'),
    },
  ];

  // ⁡⁢⁣⁣𝗠𝗲𝘁𝗵𝗼𝗱𝘀⁡

  const stepDistance = () => {
    const numberOfSteps = onboardingStepList.length;

    const percentagePerStep = 100 / numberOfSteps;

    return percentagePerStep;
  };

  const handleStepClick = (stepDirection: StepDirection) => {
    if (stepDirection === 'forward') {
      if (currentStep < 3) {
        const nextStep = currentStep + 1;

        setCurrentStep(nextStep);

        localStorage.setItem('onboardingStep', nextStep.toString());
      } else {
        userOnboardDone();
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (onboardingStepList[currentStep].id === 0) {
      updateUserInfo();
    } else {
      handleStepClick('forward');
    }
  };

  // ⁡⁢⁣⁣𝗠𝘂𝘁𝗮𝘁𝗶𝗼𝗻𝘀⁡

  const updateUserInfo = () => {
    updateMyPersonalInformationMutation({
      variables: { ...userProfile },
    })
      .then(() => {
        toast(t('views.profile.success.personalInformationUpdated'), {
          type: 'success',
        });
      })
      .then(() => {
        handleStepClick('forward');
      })
      .catch(() => {
        toast(t('views.profile.errors.personalInformationUpdateFailed'), {
          type: 'error',
        });
      });
  };

  const userOnboardDone = () => {
    userOnboardDoneMutation()
      .then(() => {
        toast(t('onboarding.messages.onboardDoneSuccess'), {
          type: 'success',
        });
      })
      .then(() => {
        dispatch(setVisibility(false));
        localStorage.removeItem('onboardingStep');
      })
      .catch(() => {
        toast(t('onboarding.messages.onboardDoneFail'), {
          type: 'error',
        });
      });
  };

  return (
    <div
      className={clsx(
        'bg-white border border-white shadow-xl',
        'divide-y divide-grey-light-02 overflow-hidden rounded-xl',
        'w-full md:w-2/3',
      )}
    >
      <div className="px-4 py-5 sm:px-6 bg-blue-light-02">
        <h1 className="font-lexend font-semibold text-xl leading-tight text-blue-dark">
          {onboardingStepList[currentStep].title}
        </h1>

        <span className="font-inter text-sm text-grey-light-00">
          {onboardingStepList[currentStep].description}
        </span>

        <div
          className={clsx(
            'w-full overflow-hidden rounded-full bg-grey-light-03 mt-4',
            'shadow-inner',
            'outline outline-1 outline-blue-light-01',
            'shadow-sm shadow-white',
          )}
        >
          <div
            className={clsx('h-2 rounded-full bg-blue-dark transition-all')}
            style={{ width: (currentStep + 1) * stepDistance() + '%' }}
          />
        </div>

        <div className="flex flex-row justify-between mt-1 text-sm font-medium text-grey-light-01">
          {onboardingStepList.map((step) => (
            <div
              key={step.id}
              className={clsx(
                'flex flex-1 justify-end transition-all font-normal',
                step.id <= currentStep ? 'text-blue-dark font-semibold' : '',
              )}
            >
              {step.id + 1}
            </div>
          ))}
        </div>
      </div>

      <div
        className={clsx(
          'h-80 py-6 px-9 gap-4 max-h-fit min-h-full overflow-y-scroll',
          'bg-gradient-to-tl from-blue-dark/5 to-white',
        )}
      >
        {onboardingStepList[currentStep].id === 0 && <ProfileStep />}

        {onboardingStepList[currentStep].id === 1 && <ResumeStep />}

        {onboardingStepList[currentStep].id === 2 && <ExtensionStep />}

        {onboardingStepList[currentStep].id === 3 && <ExploreStep />}
      </div>

      <div
        className={clsx(
          'flex flex-row p-4 ',
          onboardingStepList[currentStep].id === 0
            ? 'justify-end'
            : 'justify-between',
        )}
      >
        {onboardingStepList[currentStep].id !== 0 && (
          <button
            type="button"
            className={clsx(
              'flex w-fit justify-center rounded-md bg-none px-4 py-1.5',
              'text-grey-dark hover:text-blue-dark',
            )}
            onClick={() => handleStepClick('back')}
          >
            <ArrowLeftIcon className="h-5 w-5" />
          </button>
        )}

        <div className="flex gap-2">
          <button
            type="button"
            disabled={currentStep === 1 && myUser?.myResumes.length === 0}
            className={clsx(
              'w-full justify-center rounded-md px-4 py-1.5 shadow-sm',
              'text-sm font-semibold text-grey-light-03 leading-tight',
              'bg-blue-dark hover:bg-blue-dark/90',
              'border hover:border-blue-dark',
              'sm:mt-0 sm:w-auto',
              'disabled:bg-blue-dark/40 disabled:pointer-events-none',
            )}
            onClick={() => handleNextButtonClick()}
          >
            {onboardingStepList[currentStep].nextButtonLabel}
          </button>

          <button
            type="button"
            className={clsx(
              'w-full justify-center rounded-md px-4 text-sm sm:mt-0 sm:w-auto leading-tight',
              'bg-none shadow-sm border',
              'border-grey-light-01 hover:border-grey-dark',
              'text-grey-light-00 hover:text-grey-dark',
            )}
            onClick={() => dispatch(setVisibility(false))}
          >
            {t('onboarding.buttons.skipLabel')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
