// import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import {
//   faClock,
//   faComments,
//   faFire,
//   faGlobe,
//   faHeart,
//   faHouse,
// } from '@fortawesome/free-solid-svg-icons';
import {
  ChatBubbleLeftRightIcon,
  ClockIcon,
  Cog6ToothIcon,
  FireIcon,
  GlobeAltIcon,
  BookmarkSquareIcon,
  HomeIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
// import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
  React.RefAttributes<SVGSVGElement>;

type IconProps = IconSVGProps & {
  title?: string;
  titleId?: string;
};

type HeroIcon = React.FC<IconProps>;

export interface NavItem {
  id: string;
  label: string;
  href: string;
  icon: HeroIcon;
  badge?: 'comming_soon' | 'new' | 'none';
  subTitle?: string | null;
}

export const useSidebarNavigationItems = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navItems: NavItem[] = [
    {
      id: 'home',
      label: t('views.home.title'),
      href: '/dashboard',
      icon: HomeIcon,
    },
    {
      id: 'explore',
      label: t('views.explore.title'),
      href: '/dashboard/explore',
      icon: GlobeAltIcon,
      subTitle: t('views.explore.subTitle'),
    },
    {
      id: 'history',
      label: t('views.history.title'),
      href: '/dashboard/history',
      icon: ClockIcon,
      subTitle: t('views.history.subTitle'),
    },
    {
      id: 'bookmarks',
      label: t('views.bookmarks.title'),
      href: '/dashboard/bookmarks',
      icon: BookmarkSquareIcon,
      subTitle: t('views.bookmarks.subTitle'),
    },
    {
      id: 'forYou',
      label: t('views.forYou.title'),
      href: '/dashboard/featured',
      icon: FireIcon,
    },
    // {
    //   id: 'aiChat',
    //   label: t('views.aiChat.title'),
    //   href: '/dashboard/chat',
    //   badge: 'comming_soon',
    //   icon: ChatBubbleLeftRightIcon,
    // },
    {
      id: 'settings',
      label: t('views.settings.title'),
      href: '/dashboard/profile',
      icon: Cog6ToothIcon,
    },
    {
      id: 'applications',
      label: t('views.applications.title'),
      href: '/dashboard/applications',
      icon: QueueListIcon,
    },
  ];

  const activeNavItem = navItems.find(
    (navItem) => navItem.href === location.pathname,
  );

  return {
    navItems,
    activeNavItem,
  };
};
