import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import {
  MyNotificationsQuery,
  useArchiveNotificationMutation,
} from '@src/apollo/types/graphql';
import { useNotifications } from '@src/hooks/useNotifications';

export type NotificationListProps = {
  notificationList: MyNotificationsQuery['me']['notifications'];
  onNotificationArchive?: () => Promise<void>;
};

export const NotificationList: React.FC<NotificationListProps> = ({
  notificationList,
  onNotificationArchive,
}: NotificationListProps) => {
  // Hooks
  const { getNotificationMessage, getNotificationAge } = useNotifications();

  const { t } = useTranslation();

  // Mutations
  const [archiveNotification] = useArchiveNotificationMutation();

  // Methods
  const handleArchiveClick = async (notificationId: string) => {
    const archiveResult = await archiveNotification({
      variables: {
        id: notificationId,
      },
    });

    if (
      archiveResult.data?.archiveNotification?.id === notificationId &&
      onNotificationArchive
    ) {
      onNotificationArchive();
    }
  };

  return (
    <ul className="divide-y divide-secondary-content/25">
      {notificationList.map((notification) => (
        <li
          key={notification.id}
          className="flex justify-between py-2 px-3 bg-blue-light-03 rounded-md"
        >
          <div className="flex w-100 flex-col justify-center">
            <p className="text-md font-lexend font-medium text-grey-dark">
              {getNotificationMessage(notification.notification)}
            </p>

            <p className="text-sm font-inter leading-6 text-dark-blue-light">
              {getNotificationAge(notification.createdAt)}
            </p>
          </div>
          {notification.archived === false && (
            <div className="flex justify-center items-start">
              <ArchiveBoxIcon
                className={
                  'w-8 h-8 bg-secondary-content/5 ring-1 ring-secondary-content/25 p-2 rounded-full cursor-pointer text-white bg-blue-dark'
                }
                onClick={() => {
                  handleArchiveClick(notification.id);
                }}
              />
            </div>
          )}
        </li>
      ))}

      {notificationList.length === 0 && (
        <li className="flex justify-start items-center py-2 px-3 bg-blue-light-03 rounded-md">
          <p className="text-md font-medium font-lexend text-grey-dark">
            {t('notifications.noNotifications')}
          </p>
        </li>
      )}
    </ul>
  );
};
