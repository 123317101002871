import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import React, { useContext, useEffect, useMemo } from 'react';

import { JobRate } from '@src/apollo/types/graphql';
import { MyUserContext } from '@src/context/MyUserContext';

import WidgetFrame from './misc/WidgetFrame';
import WidgetHeader from './misc/WidgetHeader';

const JobNumbersChartWidget: React.FC = () => {
  const { myUser, loading } = useContext(MyUserContext);
  const statistics = useMemo(() => {
    if (!myUser || !myUser.statistics) {
      return null;
    }

    return myUser.statistics;
  }, [myUser]);

  const chartData = useMemo(() => {
    if (loading || !statistics) {
      return [];
    }

    return statistics.perDateStatistics.slice(7);
  }, [myUser?.statistics]);

  // Effects
  useEffect(() => {
    if (!chartData) {
      return;
    }

    const chart = am4core.create('job-numbers-chart', am4charts.XYChart);

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fill = am4core.color('#555555');
    categoryAxis.renderer.labels.template.fontFamily = 'Inter';
    categoryAxis.renderer.grid.template.strokeWidth = 1;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#999999');

    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis1.renderer.labels.template.disabled = false;
    valueAxis1.renderer.labels.template.fontSize = 10;
    valueAxis1.renderer.labels.template.fill = am4core.color('#4299f0');
    valueAxis1.renderer.labels.template.fontFamily = 'Lexend';
    valueAxis1.renderer.grid.template.strokeWidth = 1;
    valueAxis1.renderer.grid.template.strokeOpacity = 0.5;
    valueAxis1.renderer.grid.template.stroke = am4core.color('#4299f0');

    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.opposite = true;
    valueAxis2.renderer.labels.template.disabled = false;
    valueAxis2.renderer.labels.template.fontSize = 10;
    valueAxis2.renderer.labels.template.fill = am4core.color('#109d56');
    valueAxis2.renderer.labels.template.opacity = 0.8;
    valueAxis2.renderer.labels.template.fontFamily = 'Lexend';
    valueAxis2.renderer.grid.template.strokeWidth = 1;
    valueAxis2.renderer.grid.template.strokeOpacity = 0.2;
    valueAxis2.renderer.grid.template.stroke = am4core.color('#109d56');

    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.name = 'Total jobs created';
    columnSeries.dataFields.valueY = 'jobsCount';
    columnSeries.dataFields.categoryX = 'date';
    columnSeries.yAxis = valueAxis1;

    const gradient = new am4core.LinearGradient();

    gradient.addColor(am4core.color('#489CF0'), 1);
    gradient.addColor(am4core.color('#f53df5'), 1);

    columnSeries.columns.template.tooltipHTML = `
      <p style="font-size: 11px; color: #eeeeee;">{categoryX}</p>
      <hr />
      <p style="font-size: 16px; font-weight: bold; color: #ffffff;">{valueY}</p>
      `;

    columnSeries.columns.template.propertyFields.fillOpacity = 'fillOpacity';
    columnSeries.columns.template.propertyFields.stroke = 'stroke';
    columnSeries.columns.template.propertyFields.strokeWidth = 'strokeWidth';
    columnSeries.columns.template.propertyFields.strokeDasharray = 'columnDash';

    columnSeries.columns.template.column.cornerRadiusTopLeft = 10;
    columnSeries.columns.template.column.cornerRadiusTopRight = 10;
    columnSeries.columns.template.column.strokeWidth = 0;

    function generateColors(numColors: number) {
      const blue = am4core.color('#4299f0').rgb;

      const purple = am4core.color('#7a78f6').rgb;

      const colors = [];

      for (let i = 0; i < numColors; i++) {
        const color = am4core.color(
          am4core.colors.interpolate(blue, purple, i / (numColors - 1)),
        );
        colors.push(color);
      }

      return colors;
    }

    const barColors = generateColors(chartData.length);

    columnSeries.columns.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem) {
        fill = barColors[target.dataItem.index];
      }

      return fill;
    });

    if (columnSeries.tooltip) {
      columnSeries.tooltip.label.textAlign = 'middle';
    }

    if (myUser?.profile.rate) {
      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = 'Average salary';
      switch (myUser?.profile.rate) {
        case JobRate.Hourly:
          lineSeries.dataFields.valueY = 'avgHourlyRate';
          break;
        case JobRate.Monthly:
          lineSeries.dataFields.valueY = 'avgMonthlyRate';
          break;
        case JobRate.Yearly:
          lineSeries.dataFields.valueY = 'avgYearlyRate';
          break;
        default:
          lineSeries.dataFields.valueY = 'avgHourlyRate';
      }

      lineSeries.dataFields.categoryX = 'date';
      lineSeries.yAxis = valueAxis2;

      lineSeries.stroke = am4core.color('#42f099');

      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.strokeDasharray = 'lineDash';

      if (lineSeries.tooltip) {
        lineSeries.tooltip.label.textAlign = 'middle';
      }

      const bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color('#109d56');
      bullet.tooltipHTML = `
      <p style="font-size: 11px;">{categoryX}</center>
      <hr />
      <p style="font-size: 14px; font-weight: bold;">{valueY} ${myUser.profile.currency?.code}</p>
      `;
      const circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color('#fff');
    }
    chart.data = chartData;

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fontSize = 11;
    chart.legend.labels.template.fontWeight = 'bold';
    chart.legend.labels.template.scale = 1;

    chart.legend.labels.template.fill = am4core.color('#222222');

    chart.legend.markers.template.scale = 0.7;

    return () => {
      chart.dispose();
    };
  }, [chartData, myUser?.profile.currency]);

  const title = useMemo(() => {
    if (myUser?.profile.rate) {
      switch (myUser?.profile.rate) {
        case JobRate.Hourly:
          return `Average Hourly Rate (${myUser.profile.currency?.code} / hour)`;
        case JobRate.Monthly:
          return `Average Monthly Rate (${myUser.profile.currency?.code} / month)`;
        case JobRate.Yearly:
          return `Average Yearly Rate (${myUser.profile.currency?.code} / year)`;
        default:
          return `Average Hourly Rate (${myUser.profile.currency?.code} / hour)`;
      }
    }

    return 'Insights';
  }, [myUser?.profile.rate]);

  // Template
  return (
    <WidgetFrame
      queriesReady={statistics !== null}
      queriesLoading={loading}
      extraClasses="p-3"
    >
      <WidgetHeader
        title={title}
        subtitle="Average salary and total jobs explored"
      />

      <div id="job-numbers-chart" className="h-[90%]"></div>
    </WidgetFrame>
  );
};

export default JobNumbersChartWidget;
