export const enMessages = {
  views: {
    home: {
      title: 'Home',
    },
    aiChat: {
      title: 'AI Chat',
    },
    explore: {
      title: 'Explore',
      subTitle: 'Discover new opportunities',
      sortOptions: {
        createdAt: 'Date',
        title: 'Title',
        salary: 'Salary',
        unknown: 'Unknown',
      },
      infiniteScroll: {
        endMessage: "That's it! Scroll back or explore anew.",
      },
      jobsList: {
        noJobs: 'No jobs found',
      },
      jobSingle: {
        selectAJob: 'Please select a job to see detailed informations',
      },
    },
    history: {
      title: 'History',
      subTitle: 'Latest seen jobs',
      sortOptions: {
        createdAt: 'Date',
        title: 'Title',
        salary: 'Salary',
        unknown: 'Unknown',
      },
      infiniteScroll: {
        endMessage: "That's it! Scroll back or explore anew.",
      },
      jobsList: {
        noJobs: 'No jobs found',
      },
      jobSingle: {
        selectAJob: 'Please select a job to see detailed informations',
      },
    },
    bookmarks: {
      title: 'Bookmarks',
      subTitle: 'My favourite jobs',
      sortOptions: {
        createdAt: 'Date',
        title: 'Title',
        salary: 'Salary',
        unknown: 'Unknown',
      },
      infiniteScroll: {
        endMessage: "That's it! Scroll back or explore anew.",
      },
      jobsList: {
        noJobs: 'No jobs found',
      },
      jobSingle: {
        selectAJob: 'Please select a job to see detailed informations',
      },
    },
    profile: {
      title: 'Profile',
      joinDate: {
        unknown: 'Joined at: UNKNOWN',
        thisWeek: 'Joined this week',
        lastWeek: 'Joined last week',
        weeksAgo: 'Joined {{weeks}} weeks ago',
      },
      tabs: {
        personalInformation: 'Personal information',
        subscriptions: 'Subscriptions',
        security: 'Security',
        resume: 'Resume',
      },
      errors: {
        unknown: 'An unexpected error occured. Please try again later.',
        avatarUpdateFailed:
          'Error updating your avatar. Please try again later',
        personalInformationUpdateFailed:
          'An unexpected error occured while updating your personal information. Please try again later.',
        newPasswordsDoNotMatch:
          'The new password and confirm password do not match.',
        invalidOldPassword: 'The provided old password is invalid.',
        invalidNewPassword: 'The provided new password is invalid.',
      },
      success: {
        avatarUpdated: 'Your avatar has been successfuly updated!',
        personalInformationUpdated:
          'Your personal information has been successfuly updated!',
        passwordUpdated: 'Your password has been successfuly updated!',
      },
      form: {
        firstName: 'First name',
        lastName: 'Last name',
        profession: 'Profession',
        currentPassword: 'Current password',
        newPassword: 'New password',
        newPasswordConfirm: 'Confirm new password',
        submitButton: 'Save',
        downloadCurrentResume: 'Download current resume',
      },
      yourSkills: 'Your skills',
      signOut: 'Sign-out',
    },
    applications: {
      title: 'Applications',
    },
    login: {
      title: 'Sign in to your account',
      subtitle: "Don't have an account yet?",
      subtitleLink: 'Sign up for a free trial.',
      form: {
        email: 'Email address',
        password: 'Password',
        submitButton: 'Sign in',
      },
      forgotPassword: 'Forgot your password?',
      resetYourPassword: 'Reset your password here.',
      or: 'or',
      loginWithGoogleButton: 'Sign in with Google',
      loginWithLinkedinButton: 'Sign in with LinkedIn',
      notifications: {
        errors: {
          invalidCredentials:
            'The provided credentials are invalid. Please check the username and password and try again.',
          unknownError: 'Something went wrong. Please try again later.',
        },
        success:
          "You've been successfully logged in. You will be redirected to you dashboard in a few seconds.",
      },
    },
    register: {
      title: 'Register your account',
      subtitle: 'Already have an account?',
      subtitleLink: 'Log in into the dashboard.',
      form: {
        email: 'Email address',
        password: 'Password',
        submitButton: 'Register',
      },
      or: 'or',
      registerWithGoogleButton: 'Register with Google',
      registerWithLinkedinButton: 'Register with LinkedIn',
      notifications: {
        errors: {
          invalidEmail: 'The provided email is invalid.',
          emailAlreadyExists: 'There is already an account with this email.',
          weakPassword: 'The provided password is too weak.',
          unknownError: 'Something went wrong. Please try again later.',
        },
        success:
          "You've been successfully registered. You will be redirected to you dashboard in a few seconds.",
      },
      userAgreement: 'I have read and agree to the ',
      and: ' and ',
      privacyPolicy: 'Privacy Policy',
      termsAndConditions: 'Terms and Conditions',
      close: 'Close',
    },
    forgotPassword: {
      title: 'Reset your password',
      subtitle: "Don't have an account?",
      subtitleLink: 'Sign up for a free trial.',
      form: {
        email: 'Email address',
        submitButton: 'Reset',
      },
      alreadyRequestedPasswordReset:
        'You have already requested a password reset.',
      waitFor: 'Please wait for {{minutes}} minute before trying again.',
      timeLeft: 'Time left: {{seconds}} seconds.',
      notifications: {
        error:
          "Something went wrong. We couldn't reset your password. Please try again later.",
        success:
          'Password reset link has been successfuly sent to your email address! Please check your inbox.',
      },
    },
    resetPassword: {
      title: 'Reset your password',
      subtitle: "Don't have an account?",
      subtitleLink: 'Sign up for a free trial.',
      form: {
        oldPassword: 'Old password',
        newPassword: 'New password',
        newPasswordConfirm: 'Confirm new password',
        submitButton: 'Reset',
      },
      notifications: {
        errors: {
          invalidOldPassword: 'The provided old password is invalid.',
          newPasswordTooWeak: 'The provided password is too weak.',
          unknownError: 'Something went wrong. Please try again later.',
        },
        success:
          'Your password has been successfuly changed! Your will be redirected to login page shortly.',
      },
    },
    forYou: {
      title: 'For You',
    },
    settings: {
      title: 'Settings',
    },
  },
  subscriptionPlans: {
    free: {
      title: 'Starter plan',
      price: 'FREE',
      description:
        'Good for anyone who is not employed and just getting started.',
      features: {
        compatibilities: 'Job compatibilities / day: 5',
        compatibilityCalculation: 'Compatibility calculation: MANUAL',
        historyTracking: 'Jobs history tracking: LAST WEEK',
        bookmarks: 'Bookmarks: UNLIMITED',
      },
    },
    advanced: {
      title: 'Advanced plan',
      price: '$25',
      description:
        'Good for anyonw who is not employed and is looking for advanced features.',
      features: {
        compatibilities: 'Job compatibilities / day: 50',
        compatibilityCalculation: 'Compatibility calculation: MANUAL',
        historyTracking: 'Jobs history tracking: LAST MONTH',
        jobsRecommandation: 'High rated jobs recommendation',
        resumeAnalisys: 'Resume analisys',
        bookmarks: 'Bookmarks: UNLIMITED',
      },
    },
    premium: {
      title: 'Premium plan',
      price: '$5 /month',
      description:
        'Good for anyonw who is not employed and is looking for premium features.',
      features: {
        compatibilities: 'Job compatibilities / day: UNLIMITED',
        compatibilityCalculation: 'Compatibility calculation: AUTOMATIC',
        historyTracking: 'Jobs history tracking: UNLIMITED',
        jobsRecommandation: 'High rated jobs recommendation',
        companyInsights: 'Company insights',
        advancedAnalytics: 'Advanced analytics',
        resumeAnalisys: 'Resume analisys',
        bookmarks: 'Bookmarks: UNLIMITED',
      },
    },
    checkout: {
      subscriptionCanceled: 'Your subscription has been canceled.',
      subscriptionCancelledSubtitle:
        'You can still use your subscription until the end of the billing period.',
      paymentSuccess: 'Your payment has been successfuly finished.',
    },
    upgrade: {
      title: 'Upgrade your subscription',
      subtitle: 'Select a plan to upgrade your subscription',
    },
    unpaid: {
      title: 'Your subscription has not been paid yet',
      subtitle:
        'To continue, you need to pay your subscription or select a free plan.',
    },
    active: {
      title: 'Your subscription is active',
      subtitle: 'It will be automatically renewed at {{date}}',
    },
    canceled: {
      title: 'Your subscription has been cancelled',
      subtitle:
        'It has been canceled on {{cancelDate}} and your subscription will automatically switch to free plan on {{planDateEnd}}',
    },
    buttons: {
      paySubscription: 'Pay subscription',
      currentPlan: 'Current plan',
      getStarted: 'Get started',
    },
  },
  preferences: {
    mainTitle: 'Preferences',
    languageLabel: 'Language',
    themeLabel: 'Dark mode',
  },
  feedback: {
    buttonLabel: 'Give feedback',
    mainTitle: 'Share your thoughts',
    placeholderMessage: 'Tell us about your experience ...',
    submitButtonLabel: 'Send feedback',
  },
  notifications: {
    inboxLabel: 'Inbox',
    archiveLabel: 'Archive',
    youHaveLabel: 'You have ',
    notificationCount_one: '{{count}} notification',
    notificationCount_few: '{{count}} notifications',
    notificationCount_many: '{{count}} notifications',
    notificationCount_other: '{{count}} notifications',
    noNotifications: "You don't have any notifications.",
    message: {
      accountActivated: 'Your account has been activated successfully.',
    },
  },
  compatibilities: {
    youHaveLabel: 'You have ',
    compatibilityCount_one: '{{count}} compatibility calculations left.',
    compatibilityCount_few: '{{count}} compatibility calculations left.',
    compatibilityCount_many: '{{count}} compatibility calculations left.',
    compatibilityCount_other: '{{count}} compatibility calculations left.',
    noCompatibilitiesLeft: 'No compatibility calculations left.',
    calculateCompatibility: 'Calculate compatibility',
    cannotCalculateCompatibility: 'Cannot calculate compatibility',
    cannotCalculateCompatibilityReason:
      'You cannot calculate a compatibility for a job if you have no resume uploaded.',
    notifications: {
      dailyLimitReached:
        'You have reached your daily limit of compatibilities. Please upgrade your plan to continue.',
    },
  },
  accountNotVerified: {
    title: 'Please verify your account',
    subtitle: "Click on the link we've sent to your email address.",
    refresh: 'Refresh',
    logout: 'Log out',
  },
  loadingMessage: 'LOADING',
  errorMessage: 'An error has occurred. Please try again later.',
  user: {
    profile: {
      cannotRemoveResume:
        'You cannot remove your current resume. Please select another one to be default and try again.',
    },
  },
  webStore: {
    availableIn: 'Available in',
    storeName: 'Chrome Web Store',
  },
  uploadDropzone: {
    uploadFile: 'Upload a file',
    avatarDropText:
      ' or simply drag and drop your photo here to begin the upload process.',
    resumeDropText:
      ' or simply drag and drop your resume here to begin the upload process.',
    avatarSupportedFormat: 'Supported formats: PNG, JPG, GIF',
    resumeSupportedFormat: 'Supported format: PDF',
    maximumSize: 'Maximum size: 25 MB',
    errorMessage: 'Upload Error: Please upload only one file.',
  },
  onboarding: {
    buttons: {
      continueLabel: 'Continue',
      finishLabel: 'Finish',
      skipLabel: 'Skip for now',
    },
    steps: {
      profileStep: {
        title: 'Create Your Profile',
        description:
          "Welcome to Jobpedia.AI! Let's begin by setting up your profile. Please provide your name, profession, and add a profile photo.",
      },
      resumeStep: {
        title: 'Upload Your Resume',
        description:
          "Great, you're one step closer to finding your dream job. Upload your resume, and we'll help you match with relevant opportunities.",
      },
      extensionStep: {
        title: 'Browser Extension Check',
        description:
          "Let's ensure you have the best experience. We will check if the Jobpedia extension is installed to improve your job search process.",
        extensionInstalled: {
          title: 'Chrome Extension Installed',
          description:
            "You're all set! The required Chrome extension is already installed on your browser. You can enjoy all the features and functionality without any issues.",
        },
        extensionRequired: {
          title: 'Chrome Extension Required',
          description:
            'To use all the features of our service, you need to install our Chrome Extension. Installation is quick and easy!',
        },
      },
      exploreStep: {
        title: 'Congratulations!',
        description:
          " We're thrilled to have you on board and can't wait to help you discover amazing opportunities that match your skills and aspirations.",
        content: {
          title: 'Feel the excitement in the air?',
          text: 'We do too! Click the "Finish" button below and let the adventure begin. Your dream job is just a click away. Happy exploring!',
        },
      },
    },
    messages: {
      onboardDoneSuccess:
        'Congratulations! Onboarding complete, welcome aboard!',
      onboardDoneFail: 'Oops, onboarding failed. Please try again later.',
    },
  },
};
