import React from 'react';

import { SalientLabel } from './SalientLabel';

const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm';

export interface SalientTextFieldProps {
  id: string;
  label: string;
  type: string;
  className?: string;
  value?: string;
  name: string;
  autoComplete?: string;
  required: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SalientTextField: React.FC<SalientTextFieldProps> = ({
  id,
  label,
  type = 'text',
  className = '',
  ...props
}) => {
  return (
    <div className={className}>
      {label && <SalientLabel id={id}>{label}</SalientLabel>}
      <input id={id} type={type} {...props} className={formClasses} />
    </div>
  );
};
