export const formatSalary = (str: string) => {
  const regex = /[^£$€¥]\d+/g;
  const matches = str.match(regex);

  if (matches) {
    const uniqueValues = new Set();
    matches.map((match) => {
      const value = parseFloat(
        match
          .replace(/[^0-9.]/g, '')
          .replace(',', '')
          .replace('.', ''),
      );

      const formattedValue =
        value >= 1000 ? `£${(value / 1000).toFixed(0)}K` : match;
      uniqueValues.add(formattedValue);

      return formattedValue;
    });

    return [...uniqueValues].join(' - ');
  }

  return str;
};

export const trimJobDetails = (str: string) => {
  return str && str.length > 0
    ? str.length > 8
      ? str.substring(0, 8) + '...'
      : str
    : 'Unknown';
};

export const trimJobDetailsHome = (str: string) => {
  return str && str.length > 0
    ? str.length > 15
      ? str.substring(0, 15) + '...'
      : str
    : 'Unknown';
};

export const formatString = (str: string, chars: number = 10): string =>
  str.length > chars ? str.substring(0, chars) + '...' : str;

export const formatArray = (
  arr: Array<string>,
  elements: number = 10,
): Array<string> => (arr.length > elements ? arr.slice(0, elements) : arr);

export const parseFloatWithDecimals = (number: number, decimals = 3) => {
  const parsed = parseFloat(number.toFixed(decimals));

  return parsed;
};
