import React from 'react';

import PortalList from '@src/components/dashboard/widgets/misc/PortalList';

import WidgetFrame from './misc/WidgetFrame';
import WidgetHeader from './misc/WidgetHeader';

const ExploreWidget: React.FC = () => {
  return (
    <WidgetFrame
      queriesReady={true}
      queriesLoading={false}
      queriesError={undefined}
      extraClasses="p-3"
    >
      <WidgetHeader
        title="Explore"
        subtitle="Navigate job opportunities accross platforms"
      />

      <div className="mt-3">
        <PortalList columns={2} />
      </div>
    </WidgetFrame>
  );
};

export default ExploreWidget;
